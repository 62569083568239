import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import PurchaseInvoiceTabView from 'module/Common/ViewPages/PurchaseInvoice/PurchaseInvoiceTabView';
import { useLocation } from 'react-router-dom';
function PurchaseInvoiceInfo({
  purchaseInvoiceId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const apiCallDelayTime = location?.state?.apiCallDelay ?? 0;

  const fetchData = async () => {
    let url = voidTransactionReport
      ? 'settings/void-transaction-details'
      : `purchase/purchase-invoice/${purchaseInvoiceId}`;
    await axios
      .get(url, {
        params: {
          trans_type: voidTransactionReport ? 'PI' : '',
          trans_no: voidTransactionReport ? purchaseInvoiceId : ''
        }
      })
      .then(res => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    const apiCallTime = setTimeout(() => {
      fetchData();
    }, apiCallDelayTime);
    return () => clearTimeout(apiCallTime);
  }, [purchaseInvoiceId, apiCallDelayTime]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for purchase invoice info" />
        </LoadingScreenCover>
      ) : (
        <PurchaseInvoiceTabView
          purchaseInvoiceData={data}
          loading={loading}
          creditFormatted={
            data && data.supplier && data.supplier.unused_credits_formatted
              ? data.supplier.unused_credits_formatted
              : ''
          }
          credit={
            data && data.supplier && data.supplier.unused_credits
              ? data.supplier.unused_credits
              : ''
          }
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      )}
    </>
  );
}

PurchaseInvoiceInfo.propTypes = {
  purchaseInvoiceId: PropTypes.string,
  setShowVoucherDetailArea: PropTypes.func,
  offCanvas: PropTypes.bool,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default PurchaseInvoiceInfo;

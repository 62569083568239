const initPermissionData = {
  apiDataBackup: undefined,
  allPermissionsData: [],
  permissionsData: [],
  permissionsTreeData: [],
  defaultSelectedModules: [],
  activeModule: undefined
};

function setPermissionWithAssignedPermissionData(data, action) {
  if (data?.permissions && data?.assigned_permissions) {
    data.permissions = data.permissions.reduce((result, permission) => {
      if (action === 'reset') {
        delete permission.hasPermissionRef;
      }
      permission.hasPermission =
        data.assigned_permissions.filter(
          item => item?.permission_id === permission?.id
        ).length > 0
          ? true
          : false;
      result.push(permission);
      return result;
    }, []);
  }
  return data;
}

function checkModuleHasAllPermisson(permissions, moduleId) {
  let allRelatedPermissions = (
    Array.isArray(permissions) ? permissions : []
  ).filter(permissionItem => permissionItem?.module_id === moduleId);

  if (allRelatedPermissions.length < 1) return false;

  let allPermissionsCount = allRelatedPermissions.length;

  let allActivePermissionsCount = allRelatedPermissions.filter(
    item => item.hasPermission === true
  ).length;

  return allPermissionsCount === allActivePermissionsCount ? true : false;
}

function setSubModule(
  data,
  permissionsWithHasPermission,
  modulePermissions,
  setSelectedModules
) {
  let newData = [];
  if (Array.isArray(data?.sub_modules) && data.sub_modules.length > 0) {
    newData = data.sub_modules.reduce((result, item) => {
      const subModules = Array.isArray(item?.sub_modules)
        ? item.sub_modules
        : [];

      const modulePermissions =
        permissionsWithHasPermission?.permissions.filter(
          permissionItem => permissionItem?.module_id === item?.id
        );
      const subModulesLength = subModules.length;

      let treeData = {
        id: item?.id,
        name: item?.module_name,
        icon: subModulesLength > 0 ? 'folder' : 'file',
        iconClass: 'text-danger'
      };

      if (subModulesLength > 0 || modulePermissions?.length > 0) {
        treeData.children = setSubModule(
          item,
          permissionsWithHasPermission,
          modulePermissions,
          setSelectedModules
        );
      }

      const moduleHasAllPermisson = checkModuleHasAllPermisson(
        permissionsWithHasPermission?.permissions ?? [],
        treeData.id
      );

      if (moduleHasAllPermisson && typeof setSelectedModules === 'function') {
        setSelectedModules(treeData.id);
      }

      result.push(treeData);
      return result;
    }, []);
  }

  if (Array.isArray(modulePermissions) && modulePermissions.length > 0) {
    modulePermissions.forEach(permissionItem => {
      newData.push({
        id: permissionItem?.id,
        name: permissionItem?.name,
        icon: 'file',
        iconClass: 'text-danger',
        hide: true
      });

      if (
        ((typeof permissionItem?.hasPermissionRef === 'undefined' &&
          permissionItem?.hasPermission) ||
          permissionItem?.hasPermissionRef) &&
        typeof setSelectedModules === 'function'
      ) {
        setSelectedModules(permissionItem?.id);
      }
    });
  }

  return newData;
}

function setTreeResult(permissionsWithHasPermission) {
  let selectedModules = [];
  const setSelectedModules = id => {
    selectedModules = [...selectedModules, id];
  };

  const treeData = permissionsWithHasPermission.modules.reduce(
    (result, item) => {
      const subModules = Array.isArray(item?.sub_modules)
        ? item.sub_modules
        : [];

      const modulePermissions =
        permissionsWithHasPermission?.permissions.filter(
          permissionItem => permissionItem?.module_id === item?.id
        );

      const subModulesLength = subModules.length;

      let treeData = {
        id: item?.id,
        name: item?.module_name,
        icon: subModulesLength > 0 ? 'folder' : 'file',
        iconClass: 'text-primary'
      };

      if (subModulesLength > 0 || modulePermissions?.length > 0) {
        treeData.children = setSubModule(
          item,
          permissionsWithHasPermission,
          modulePermissions,
          setSelectedModules
        );
      }

      checkModuleHasAllPermisson(
        permissionsWithHasPermission?.permissions ?? [],
        treeData.id
      );

      result.push(treeData);
      return result;
    },
    []
  );

  return {
    treeData: [
      {
        id: 'rootItemId',
        name: 'Modules',
        icon: 'folder',
        iconClass: 'text-primary',
        children: treeData.sort((a, b) =>
          a.name < b.name ? -1 : a.name > b.name ? 1 : 0
        )
      }
    ],
    selectedModules: selectedModules
  };
}

export default function permissionDataReducer(state, action) {
  switch (action?.type) {
    case 'SET_DATA': {
      const permissionsWithHasPermission =
        setPermissionWithAssignedPermissionData(action?.data);
      const permissionsResult = permissionsWithHasPermission?.permissions ?? [];
      let newResult = { ...state, apiDataBackup: action.data };
      newResult.allPermissionsData = permissionsResult;
      newResult.permissionsData = permissionsResult;

      if (
        Array.isArray(permissionsWithHasPermission?.modules) &&
        permissionsWithHasPermission.modules.length > 0
      ) {
        const { treeData, selectedModules } = setTreeResult(
          permissionsWithHasPermission
        );
        newResult.permissionsTreeData = treeData;
        newResult.defaultSelectedModules = selectedModules;
      }

      return newResult;
    }

    case 'RESET_DATA': {
      const permissionsWithHasPermission =
        setPermissionWithAssignedPermissionData(state?.apiDataBackup, 'reset');
      const permissionsResult = permissionsWithHasPermission?.permissions ?? [];
      let newResult = { ...state, apiDataBackup: action.data };
      newResult.allPermissionsData = permissionsResult;
      newResult.permissionsData = permissionsResult;

      if (
        Array.isArray(permissionsWithHasPermission?.modules) &&
        permissionsWithHasPermission.modules.length > 0
      ) {
        const { treeData, selectedModules } = setTreeResult(
          permissionsWithHasPermission
        );
        newResult.permissionsTreeData = treeData;
        newResult.defaultSelectedModules = selectedModules;
      }

      return newResult;
    }

    case 'SET_ACTIVE_MODULE_DATA': {
      return {
        ...state,
        activeModule: action?.module
      };
    }

    case 'CHECK_MODULE': {
      if (!Array.isArray(state?.permissionsData))
        throw Error('Invalid permission data');

      const { slectedModulesArray } = action;

      return {
        ...state,
        permissionsData: state.permissionsData.reduce((result, permission) => {
          if (
            slectedModulesArray.includes(permission.module_id) ||
            slectedModulesArray.includes(permission.id)
          ) {
            permission.hasPermissionRef = true;
          } else {
            permission.hasPermissionRef = false;
          }
          result.push(permission);
          return result;
        }, [])
      };
    }

    case 'SWITCH_PERMISSION': {
      const data = {
        ...state?.apiDataBackup,
        permissions: state?.permissionsData
      };

      let permissionsWithHasPermission;
      if (Array.isArray(data?.permissions)) {
        const { module, selectedModulesArray, checked, id } = action;
        data.permissions = data.permissions.reduce((result, permissionItem) => {
          if (
            permissionItem.id === id ||
            (module &&
              (permissionItem.module_id === id ||
                selectedModulesArray.includes(permissionItem.module_id)))
          ) {
            permissionItem.hasPermissionRef = checked;
          }
          result.push(permissionItem);
          return result;
        }, []);
        permissionsWithHasPermission = data;
      }

      let newResult = { ...state };

      if (
        Array.isArray(permissionsWithHasPermission?.modules) &&
        permissionsWithHasPermission.modules.length > 0
      ) {
        const { treeData, selectedModules } = setTreeResult(
          permissionsWithHasPermission
        );
        newResult.permissionsTreeData = treeData;
        newResult.defaultSelectedModules = selectedModules;
      }

      return newResult;
    }

    case 'SET_DEFAULT_DATA': {
      return initPermissionData;
    }

    default: {
      throw Error('Unknown action: ' + action.type);
    }
  }
}

export { initPermissionData };

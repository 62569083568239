import { React, useState } from 'react';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { BsReceipt } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import RecurringRules from '../Common/RecurringRules';
import JournalTable from '../Common/Tables/JournalTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';

function JournalVoucherTabView({
  journalVoucherData,
  loading,
  voidScreen,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport
}) {
  const { breakpoints } = useBreakpoints();
  const Translate = useAxisproTranslate();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [upload, setUpload] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const permission = {
    show_jv: getAcodaxPermissionSlug('JV', 'show'),
    update_jv: getAcodaxPermissionSlug('JV', 'update'),
    send_email_jv: getAcodaxPermissionSlug('JV', 'send-mail')
  };
  const getEmail = () => {
    switch (journalVoucherData.person_type_id) {
      case 'CUSTOMER':
        return journalVoucherData.person ? journalVoucherData.person.email : '';
      case 'SUPPLIER':
        return journalVoucherData.person
          ? journalVoucherData.person.email_address
          : '';
      default:
        return '';
    }
  };

  const sendEmail = () => {
    const email = getEmail();
    setEmailAddress(email);
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Journal Voucher')}
              </h5>
            </div>
            {journalVoucherData?.custom_voucher?.title && (
              <Badge className="ms-2 fs--1" bg="warning">
                {journalVoucherData?.custom_voucher?.title}
              </Badge>
            )}
            {journalVoucherData && journalVoucherData?.is_recurring ? (
              <Badge bg="success" className="ms-2 fs--1">
                {Translate('Recurring')} <TiTick />
              </Badge>
            ) : (
              ''
            )}
            {voidTransactionReport && (
              <Badge bg="danger" className="ms-2 fs--1">
                {Translate('Voided')}
              </Badge>
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  setRecurring={setRecurring}
                  recurring
                  disableRecurring={journalVoucherData.is_recurring === 1}
                  emailPermission={permission.send_email_jv}
                />
              )}
              {voidScreen || offCanvas ? (
                ''
              ) : permission.update_jv ? (
                <EditButton
                  to={`/finance/journal-voucher/edit/${journalVoucherData.id}`}
                />
              ) : (
                ''
              )}
              {!voidTransactionReport && permission.update_jv && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`/finance/journal-entry/print/${journalVoucherData.id}`}
                  pageTitle="Journal voucher print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap">
            <div className="w-100 mb-2 view-page-contents">
              {journalVoucherData.trans_date_formatted ? (
                <MainDetails
                  label={'Transaction Date'}
                  value={journalVoucherData.trans_date_formatted}
                />
              ) : null}
              {journalVoucherData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/finance/voucher-inquiry?entry=${journalVoucherData?.id}&layout=column`}
                  value={journalVoucherData?.reference}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={journalVoucherData.trans_type}
                      transId={journalVoucherData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <JournalTable
                    journalData={journalVoucherData}
                    voucherData
                    jv
                  />
                </SimpleBar>
              </Tab>
              {journalVoucherData.media &&
              journalVoucherData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={journalVoucherData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {journalVoucherData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`/finance/journal-entry/print/${journalVoucherData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {journalVoucherData && journalVoucherData.created_user_full_name
                ? journalVoucherData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {journalVoucherData && journalVoucherData.created_at
                ? journalVoucherData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'JV'}
        id={journalVoucherData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'finance/journal-entry/send-email/' + journalVoucherData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={emailAddress}
      />
      <RecurringRules
        show={recurring}
        type="JV"
        id={journalVoucherData.id}
        fetchData={fetchData}
        onHide={() => setRecurring(false)}
      />
    </>
  );
}

JournalVoucherTabView.propTypes = {
  journalVoucherData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool
};

export default JournalVoucherTabView;

import { Fragment, React, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import 'driver.js/dist/driver.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-quill/dist/quill.snow.css';
import './assets/scss/style.scss';

import Layout from './layouts/Layout';
import ErrorShowingPopup from 'module/Common/Error/ErrorShowingPopup';
import AppContext, { AuthWizardContext } from 'context/Context';
import AppDropdownMenuProvider from 'components/app-dropdown-button/AppDropdownProvider';
import Error403Popup from 'components/errors/Error403Popup';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const getAuthToken = () => {
  let userData = JSON.parse(localStorage.getItem('AXIS_PRO_USER'));
  return userData && userData.token ? userData.token : undefined;
};

// setting axios config
axios.interceptors.request.use(
  config => {
    if (!config.headers.settingBaseUrl) {
      const uniqueRequestId = uuidv4();
      config.withCredentials = true;
      axios.defaults.crossOrigin = true;
      axios.defaults.crossDomain = true;
      config.headers.post['Content-Type'] = 'application/json';
      config.headers.post['Accept'] = 'application/json';
      config.headers.common['Authorization'] = `Bearer ${getAuthToken()}`;
      config.headers.common['Timezone'] = timeZone;
      config.headers.common['X-Request-Id'] = uniqueRequestId;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

const App = () => {
  const [error, setError] = useState(false);
  const [permission, setPermission] = useState(false);
  const [exceptionData, setExceptionData] = useState('');
  const currentUrl = window.location.href;
  const { lockScreen } = useContext(AuthWizardContext);
  const { user } = useContext(AuthWizardContext);
  const { setConfig } = useContext(AppContext);

  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error && error.response) {
        if (error.response.data || error.response.status) {
          if (error.response.status === 401) {
            if (localStorage.getItem('AXIS_PRO_USER')) {
              lockScreen(true);
            } else {
              window.location.href = currentUrl;
            }
          } else if (error.response.status === 403) {
            setPermission(true);
          } else {
            let data = null;
            let exceptionDetails = {};
            if (error?.response?.data?.data?.exception) {
              if (
                error?.response?.data?.data?.exception &&
                error?.response?.data?.data?.status === 500
              ) {
                data = error.response.data.data.exception;
                exceptionDetails.class = data.class;
              } else if (error.response.status === 500) {
                data = error.response.data;
                exceptionDetails.exception = data.exception;
              }
              if (data) {
                setError(true);
                setExceptionData({
                  ...exceptionDetails,
                  file: data.file,
                  line: data.line,
                  message: data.message,
                  request_url: data.request_url
                    ? data.request_url
                    : data.responseURL
                    ? data.responseURL
                    : 'None',
                  request_method: data.request_method
                    ? data.request_method
                    : error.config.method
                    ? error.config.method
                    : 'None',
                  frontend_url: currentUrl
                });
              }
            }
          }
        }
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    if (user && user.language) {
      setConfig('isRTL', user.language == 'AR' ? true : false);
    } else {
      setConfig('isRTL', false);
    }
  }, [user]);

  return (
    <Fragment>
      <AppDropdownMenuProvider>
        <Layout />
      </AppDropdownMenuProvider>
      <ErrorShowingPopup
        show={error}
        exceptionData={exceptionData}
        onHide={() => setError(false)}
      />
      <Error403Popup show={permission} onHide={() => setPermission(false)} />
    </Fragment>
  );
};

export default App;

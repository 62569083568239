import { React, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';

import { FaCrown, FaUser, FaUserLock } from 'react-icons/fa';

import { AuthWizardContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Avatar from 'components/common/Avatar';
import ResetPasswordModal from './ResetPasswordModal';
import SoftBadge from 'components/common/SoftBadge';

const ProfileDropdown = () => {
  let navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const { dispatch, user } = useContext(AuthWizardContext);
  const handleLogout = () => {
    axios
      .post('auth/logout')
      .then(res => {
        if (res.data.success === true) {
          // remove user data from localStorage
          localStorage.removeItem('AXIS_PRO_USER');

          // remove user data from translation
          localStorage.removeItem('ACODAX_TRANSLATION');
          localStorage.removeItem('ACODAX_PERMISSION');
          localStorage.setItem('ACODAX_MANUEL_LOGOUT', true);
          //This code used to logout from every tab opened in same browser
          if ('BroadcastChannel' in window) {
            const logoutChannel = new BroadcastChannel('logoutChannel');
            logoutChannel.postMessage({
              type: 'logout',
              message: 'User has logged out'
            });
            logoutChannel.close();
          }

          // delete user details from context
          dispatch({ type: 'DELETE' });

          showToast(res.data.message, 'success');

          // redirect user to login screen
          navigate('/authentication/login');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        if (error && error.response && error.response.status === 401) {
          // remove user data from localStorage
          localStorage.removeItem('AXIS_PRO_USER');

          // remove user data from translation
          localStorage.removeItem('ACODAX_TRANSLATION');

          // delete user details from context
          dispatch({ type: 'DELETE' });

          // redirect user to login screen
          navigate('/authentication/login');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      });
  };

  // Code used to reload logout tabs
  useEffect(() => {
    if ('BroadcastChannel' in window) {
      const logoutChannel = new BroadcastChannel('logoutChannel');
      logoutChannel.onmessage = function (event) {
        if (event.data && event.data.type === 'logout') {
          window.location.reload();
        }
      };
      return () => {
        logoutChannel.close();
      };
    }
  }, []);

  return (
    <>
      <Dropdown navbar={true} as="li">
        <Dropdown.Toggle
          bsPrefix="toggle"
          as={Link}
          to="#!"
          className="pe-0 ps-2 nav-link user-profile-driver-element"
          title="User Profile"
        >
          {user && user.image && <Avatar src={user.image} />}
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
          <div className="bg-white rounded-2 py-1  dark__bg-1000">
            {user && user.first_name && (
              <Dropdown.Item
                as={Link}
                to={`system-admin/users/info/${user.id}`}
                className="d-flex  "
              >
                <div className=" d-flex  w-100">
                  <div className="d-flex position-relative">
                    {user.image && (
                      <img
                        src={user?.image}
                        alt="profile-image"
                        className="rounded-circle"
                        style={{ width: '40px' }}
                        title="profile image"
                      />
                    )}
                    <FaCrown
                      title={
                        user.roles &&
                        user.roles[0] &&
                        user.roles[0].name &&
                        user.roles[0].name
                      }
                      className="position-absolute start-0 top-0"
                      style={{ color: '#ff9b0d' }}
                    />
                  </div>
                  <div className="profile-details text-dark pe-3 ps-3">
                    <div title="name">
                      {user?.first_name + ' ' + user?.last_name}
                    </div>
                    <div>
                      {user.username + ' ' + '-'}{' '}
                      <SoftBadge bg="success" className="text-center">
                        {user.roles &&
                          user.roles[0] &&
                          user.roles[0].name &&
                          user.roles[0].name}
                      </SoftBadge>
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
            )}
            <Dropdown.Item
              className="mt-2 d-flex align-items-center"
              as={Link}
              to={`/system-admin/users/info/${user?.id}`}
              title={Translate('Click here to view user profile')}
            >
              <FaUser className="me-2" /> {Translate('Your Profile')}
            </Dropdown.Item>
            <Dropdown.Item
              as={Link}
              to={`?reset_password=true`}
              title={Translate('Click here to change password')}
            >
              <FaUserLock size={18} className="me-1" />{' '}
              {Translate('Reset Password')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={handleLogout}
              href="#logout"
              className="text-center logout-button-driver-element"
            >
              {Translate('Logout')}
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <ResetPasswordModal />
    </>
  );
};

export default ProfileDropdown;

import React, { useEffect, useState } from 'react';
import logo from 'assets/img/Accodax-4.png';
import IndroGif from 'assets/gif/Indro-3.gif';
import './asset/style/AuthScreen.scss';
import { Button, Card } from 'react-bootstrap';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { FaKey, FaLock } from 'react-icons/fa';
import SendOtpForm from 'module/Common/OtpModals/SendOtpForm';
import VerifyOtpForm from 'module/Common/OtpModals/VerifyOtpForm';
const AuthOutlet = () => {
  const [isOtpForm, setIsOtpForm] = useState(false);
  const [isotpVerifyForm, setIsotpVerifyForm] = useState(false);
  const [username, setUsername] = useState('');
  const [hiddenNumber, setHiddenNumber] = useState('');
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  let activeTab;
  switch (location.pathname) {
    case '/authentication':
      activeTab = 'login';
      break;

    case '/authentication/':
      activeTab = 'login';
      break;

    case '/authentication/login':
      activeTab = 'login';
      break;

    case '/authentication/account-recovery':
      activeTab = 'recovery';
      break;

    default:
      activeTab = 'reset';
      break;
  }

  const backToLogin = () => {
    setHiddenNumber('');
    setIsOtpForm(false);
    setIsotpVerifyForm(false);
    setUsername('');
  };

  useEffect(() => {
    setIsOtpForm(false);
  }, [activeTab]);

  useEffect(() => {
    // const firstVisit = localStorage.getItem('visited');
    // if (firstVisit === null) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      // localStorage.setItem('visited', 1);
    }, 4600);
    // }
  }, []);
  return (
    <>
      {!loading ? (
        <div className="auth-screen-wrapper d-flex flex-column justify-content-center align-items-center animation-indro">
          <div className="brand-logo">
            <img
              src={logo}
              alt="logo"
              className="mb-4"
              style={{ width: '23rem' }}
            />
          </div>

          <Card className={`shadow p-3 form-container rounded px-0`}>
            <Card.Header className="d-flex flex-row border-bottom justify-content-around tab-area">
              {!isOtpForm ? (
                <>
                  {activeTab === 'reset' ? (
                    <Card.Title className="text-start w-100 fw-bold reset-title">
                      Reset password
                    </Card.Title>
                  ) : (
                    <>
                      <Button
                        className={`d-flex flex-row align-items-center rounded py-1 ${
                          activeTab !== 'login' ? 'border-0' : ''
                        } `}
                        size="sm"
                        variant={
                          activeTab === 'login'
                            ? 'outline-success'
                            : 'outline-secondary'
                        }
                        style={{ boxShadow: 'none' }}
                        as={activeTab !== 'login' ? Link : null}
                        to={'/authentication/login'}
                        disabled={activeTab === 'login'}
                      >
                        <FaLock size={13} />
                        <span className="ms-1 small">Log in</span>
                      </Button>
                      <Button
                        className={`d-flex flex-row align-items-center rounded py-1 ${
                          activeTab !== 'recovery' ? 'border-0' : ''
                        } `}
                        size="sm"
                        variant={
                          activeTab === 'recovery'
                            ? 'outline-success'
                            : 'outline-secondary'
                        }
                        style={{ boxShadow: 'none' }}
                        as={activeTab !== 'recovery' ? Link : null}
                        to={'/authentication/account-recovery'}
                        disabled={activeTab === 'recovery'}
                      >
                        <FaKey size={13} />
                        <span className="ms-1 small">Recover your Account</span>
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <Card.Title className="text-center w-100 fw-bold ">
                  Login with OTP
                </Card.Title>
              )}
            </Card.Header>
            <Card.Body>
              {!isOtpForm && !isotpVerifyForm ? (
                <Outlet />
              ) : isOtpForm && !isotpVerifyForm ? (
                <SendOtpForm
                  isOtpForm={isOtpForm}
                  setIsotpVerifyForm={setIsotpVerifyForm}
                  setUsername={setUsername}
                  username={username}
                  setHiddenNumber={setHiddenNumber}
                  backToLogin={backToLogin}
                />
              ) : (
                <VerifyOtpForm
                  show={isotpVerifyForm}
                  setIsotpVerifyForm={setIsotpVerifyForm}
                  username={username}
                  hiddenNumber={hiddenNumber}
                />
              )}
            </Card.Body>
            {!isOtpForm && (
              <Card.Footer className="d-flex flex-column text-center border-top">
                <p>OR</p>
                <a href="#" onClick={() => setIsOtpForm(true)}>
                  Login with OTP
                </a>
              </Card.Footer>
            )}
          </Card>
        </div>
      ) : (
        <div
          className=" d-flex flex-column justify-content-center align-items-center"
          style={{
            backgroundImage: `url(${IndroGif})`,
            backgroundColor: 'none !important',
            width: '100%',
            minHeight: '100vh',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center'
          }}
        ></div>
      )}
    </>
  );
};

export default AuthOutlet;

import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';

export default async function setPurchaseDetailsItemsTaxGroupData({
  newFormData,
  formData,
  setFormData,
  setEntryTableShowLoading,
  Translate,
  supplierKeyName
}) {
  if (newFormData?.[supplierKeyName ?? 'supplier_id']) {
    try {
      const { data: supplierDetails } = await axios.get(
        'purchase/supplier/' + newFormData[supplierKeyName ?? 'supplier_id']
      );

      if (formData?.details?.length > 0 && supplierDetails?.data) {
        const supplierTaxConfig = supplierDetails?.data?.tax_config;

        newFormData.details = newFormData.details.reduce((newResult, item) => {
          if (item?.loading_ref) delete item.loading_ref;
          item.add_to_cart_ref = true;
          let taxGroupDetails = {};
          if (supplierTaxConfig === 'USE_ITEM_TAX') {
            taxGroupDetails = item?.item_display_name_ref?.item;
          } else {
            const supplierTaxConfigTaxGroup =
              supplierDetails?.data.tax_config_tax_group;
            taxGroupDetails.tax_group_id = supplierTaxConfigTaxGroup?.id;
            taxGroupDetails.tax_group_name = supplierTaxConfigTaxGroup?.name;
          }

          item.tax_group_id = taxGroupDetails?.tax_group_id;
          item.tax_group_id_ref = {
            label: taxGroupDetails?.tax_group_name,
            value: taxGroupDetails?.tax_group_id
          };

          newResult.push(item);
          return newResult;
        }, []);

        setFormData(newFormData);
      }

      newFormData.supplierDetails_ref = supplierDetails?.data;
      setEntryTableShowLoading(false);
    } catch (error) {
      console.error(error);
      throw new Error('Error found');
    }
  } else {
    if (formData?.details?.length === 0) setEntryTableShowLoading(false);
    if (newFormData?.supplierDetails_ref)
      delete newFormData.supplierDetails_ref;
    setFormData(newFormData);
    showToast(
      Translate('Supplier name is required, please choose a valid data'),
      'error'
    );
  }
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

function ShowMemo({ memo, label }) {
  const Translate = useAxisproTranslate();

  return (
    <Row style={{ border: '1px dashed grey' }} className="pt-2 pb-2 pe-1 ps-1">
      <Col xs={12} className="p-0">
        <h6 className="mb-2 fw-bold">{Translate(label ? label : 'Notes')} :</h6>
      </Col>
      <Col xs={12} className="ms-3">
        <p className="mb-2 text-dark white-space-pre">{memo}</p>
      </Col>
    </Row>
  );
}

ShowMemo.propTypes = {
  memo: PropTypes.any,
  label: PropTypes.any
};

export default ShowMemo;

import { React, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { BsReceipt } from 'react-icons/bs';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import TransactionTable from '../Common/Tables/TransactionTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';
import RentalContractView from './RentalContractView';
function RentalContractTabView({
  rentalContractData,
  loading,
  voidScreen,
  infoPage,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide
  // getData
}) {
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [person] = useState({
    value: rentalContractData?.customer_id
  });
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  // const [isLoading] = useState(false);
  const permission = {
    show_rc: getAcodaxPermissionSlug('RC', 'show'),
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_rc: getAcodaxPermissionSlug('RC', 'print'),
    create_rc: getAcodaxPermissionSlug('RC', 'create'),
    update_rc: getAcodaxPermissionSlug('RC', 'update'),
    send_mail_rc: getAcodaxPermissionSlug('rc', 'send_mail'),
    void_rc: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  // const handleStatusChange = status => {
  //   if (rentalContractData?.status == status) {
  //     return null;
  //   } else {
  //     setIsLoading(true);
  //     axios({
  //       method: 'post',
  //       url: `sales/contracts/update-status/${rentalContractData.id}`,
  //       data: { status: status }
  //     })
  //       .then(res => {
  //         if (res?.data?.success) {
  //           showToast(res.data?.message, 'success');
  //           fetchData();
  //           setIsLoading(false);
  //         }
  //       })
  //       .catch(error => {
  //         if (error?.response?.data?.message) {
  //           showToast(error.response.data.message, 'error');
  //           setIsLoading(false);
  //         }
  //       });
  //   }
  // };

  return (
    <>
      {/* {isLoading ? <AppLayer /> : ''} */}
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Rental Contract')}
              </h5>
            </div>
            {rentalContractData?.rental_status && (
              <Badge
                bg={
                  rentalContractData?.rental_status === 'pending'
                    ? 'warning'
                    : 'danger'
                }
                className="ms-2 p-0 px-2 py-1 fs--1 text-uppercase d-flex align-items-center justify-content-center cursor-pointer"
              >
                {rentalContractData?.rental_status}
              </Badge>
            )}
            {/* {rentalContractData?.status && (
              <Badge
                bg={
                  rentalContractData?.rental_status === 'active' ? 'success' : 'danger'
                }
                className="ms-2 p-0 px-2 fs--1 text-uppercase d-flex align-items-center justify-content-center cursor-pointer"
                onClick={() => {
                  if (dropdownRef.current) {
                    dropdownRef.current.click();
                  }
                }}
              >
                {Translate(rentalContractData?.status)}
                <div
                  className="ms-2 cursor-pointer"
                  title={Translate(
                    'Click here to change rental contract status!'
                  )}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      className="p-0"
                      variant="transparent"
                      style={{ boxShadow: 'none' }}
                      ref={dropdownRef}
                    />
                    <Dropdown.Menu>
                      {status &&
                        status.length > 0 &&
                        status.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleStatusChange(item)}
                          >
                            {Translate(item.toUpperCase())}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Badge>
            )} */}
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  emailPermission={permission?.send_mail_rc}
                  // firstConversionValidation={true}
                  // disableFirstConversion={
                  //   (rentalContractData?.balanced_invoiced_amount &&
                  //     parseFloat(rentalContractData.balanced_invoiced_amount) <=
                  //       0) ||
                  //   rentalContractData?.status === 'expired'
                  // }
                  // firstConversionTo={
                  //   '/sales/sales-invoice/add/ct/' + rentalContractData.id
                  // }
                  // afterFirstConversion={
                  //   rentalContractData?.status === 'expired'
                  //     ? Translate('Contract Expired!')
                  //     : Translate('Fully Invoiced!')
                  // }
                  // beforeFirstConversion={
                  //   rentalContractData?.status === 'expired'
                  //     ? Translate('Contract Expired!')
                  //     : Translate('Convert to Sales Invoice')
                  // }
                  // voidTransactionData={
                  //   permission?.void_rc
                  //     ? {
                  //         id: rentalContractData?.id,
                  //         trans_type: 'CT',
                  //         callBack: () => {
                  //           navigateTo('fixed-assets/rental-contracts/info');
                  //           typeof getData === 'function' && getData(1);
                  //         }
                  //       }
                  //     : undefined
                  // }
                />
              )}
              {voidScreen || infoPage || offCanvas ? (
                ''
              ) : permission?.update_rc ? (
                <EditButton
                  to={`/fixed-assets/rental-contracts/edit/${
                    rentalContractData?.id
                  }?${createSearchParams({
                    ...allQueryParams
                  })}`}
                />
              ) : (
                ''
              )}
              {!voidTransactionReport && permission?.print_rc && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`sales/rental-contracts/print/${rentalContractData.id}`}
                  pageTitle="Rental contract print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea || onHide}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap justify-content-between">
            <div>
              {rentalContractData?.customer_name ? (
                <PersonName
                  label={'Customer Name'}
                  value={rentalContractData?.customer_name}
                  view
                  permission={permission?.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              ) : null}
              {rentalContractData?.contract_date_formatted ? (
                <MainDetails
                  label={'Rental Contract Date'}
                  value={rentalContractData?.contract_date_formatted}
                />
              ) : null}
            </div>
            <div>
              {rentalContractData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/fixed-assets/rental-contracts/info/${rentalContractData?.id}`}
                  value={rentalContractData?.reference}
                />
              ) : null}
              {rentalContractData?.amount_formatted ? (
                <MainDetails
                  label={'Total Rental Contract Amount'}
                  value={rentalContractData?.amount_formatted}
                />
              ) : null}
              {rentalContractData?.total_invoiced_amount_formatted ? (
                <MainDetails
                  label={'Total Invoiced Amount'}
                  value={rentalContractData?.total_invoiced_amount_formatted}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={rentalContractData.trans_type}
                      transId={rentalContractData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    voidScreen || offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <RentalContractView rentalContractData={rentalContractData} />
                </SimpleBar>
              </Tab>
              {rentalContractData?.sales_invoices &&
                rentalContractData?.sales_invoices.length > 0 && (
                  <Tab
                    eventKey="transactions"
                    title={Translate('Transactions')}
                  >
                    <SimpleBar
                      className={
                        voidScreen || offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <TransactionTable data={rentalContractData} />
                    </SimpleBar>
                  </Tab>
                )}
              {rentalContractData.media &&
              rentalContractData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      Math.round(rentalContractData.balance) === 0 ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={rentalContractData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {rentalContractData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`sales/rental-contracts/print/${rentalContractData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={rentalContractData?.created_at}
          created_by={rentalContractData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'CT'}
        id={rentalContractData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'sales/contracts-email/' + rentalContractData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
      />
    </>
  );
}

RentalContractTabView.propTypes = {
  rentalContractData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  infoPage: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  onHide: PropTypes.bool,
  getData: PropTypes.func
};

export default RentalContractTabView;

import { React } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import useAutoCompleteApiCallHandler from 'hooks/useAutoCompleteApiCallHandler';

const SelectSalesType = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  onFocus
}) => {
  const { fetchData, setDefaultResult, loading, defaultOptions } =
    useAutoCompleteApiCallHandler({
      url: 'settings/sales-types-autocomplete',
      setParams: searchKey => ({
        name: searchKey
      }),
      setOptions: option => ({
        label: option.name,
        value: keyName ? option[keyName] : option.id
      }),
      onFocus: onFocus
    });

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={fetchData}
      isLoading={loading}
      defaultOptions={defaultOptions}
      onFocus={setDefaultResult}
      isClearable
      name={name ?? 'sales_type'}
      value={value}
      placeholder={placeholder ?? ''}
      onChange={handleFieldChange}
      classNamePrefix="app-react-select"
      className={`${error && 'is-invalid'}`}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style
        })
      }}
    />
  );
};

SelectSalesType.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onFocus: PropTypes.func
};

export default SelectSalesType;

import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { RiStockLine } from 'react-icons/ri';
import { FaFileInvoice } from 'react-icons/fa';
import { MdWavingHand } from 'react-icons/md';

import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Flex from 'components/common/Flex';
import Background from 'components/common/Background';
import corner1 from 'assets/img/icons/spot-illustrations/corner-1.png';

const WelcomeTile = ({ draggable }) => {
  const Translate = useAxisproTranslate();
  const [userName, setUserName] = useState(null);
  const data = [
    {
      title: Translate('Sales Invoice'),
      text: Translate('Create new sales invoice!'),
      color: 'primary',
      Icon: FaFileInvoice,
      url: '/sales/sales-invoice/add',
      slug: getAcodaxPermissionSlug('SI', 'create')
    },
    {
      title: Translate('Inventory Adjustment'),
      text: Translate('Adjust Inventory here!'),
      color: 'warning',
      Icon: RiStockLine,
      url: '/inventory/inventory-adjustment/add',
      slug: getAcodaxPermissionSlug('IA', 'store')
    }
  ];

  useEffect(() => {
    const storedData = localStorage.getItem('AXIS_PRO_USER');
    if (storedData) {
      setUserName(JSON.parse(storedData));
    }
  }, []);

  return (
    <Card
      className={`overflow-hidden position-relative  ${
        draggable && 'dashboard_shake'
      } p-3 pb-2`}
      style={{ height: '100%' }}
    >
      <Background image={corner1} className="p-card bg-card" />
      <Card.Body className="position-relative pe-0 ps-1">
        <h5 className="text-warning fs-1">
          {Translate('Hello')} <MdWavingHand /> {userName && userName.name}
        </h5>
        <h6 className="text-dark">
          {Translate('Here are some quick links for you to start')}
        </h6>
        <Row className="g-2  align-items-end mt-3">
          {data.map(({ Icon, color, title, text, url, slug }) => {
            return slug ? (
              <Col sm={6} md={6} key={url}>
                <Flex className="position-relative">
                  <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                    <Icon className={`text-${color}`} />
                  </div>
                  <div className="flex-1">
                    <Link to={url} className="stretched-link">
                      <h6 className="text-dark mb-0">{title}</h6>
                    </Link>
                    <p className="mb-0 fs--2 text-500 ">{text}</p>
                  </div>
                </Flex>
              </Col>
            ) : (
              ''
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

WelcomeTile.propTypes = {
  draggable: PropTypes.bool
};

export default WelcomeTile;

import { React, useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Figure, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { AiOutlineUpload } from 'react-icons/ai';
import { FaSave, FaUserSecret } from 'react-icons/fa';

import Loading from '../../../../../assets/gif/upload.gif';

import { AuthWizardContext, StoreContext } from 'context/Context';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import useAcodaxNumberFormat from 'hooks/useAcodaxNumberFormat';

function BasicDetails({ data, type }) {
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  const Translate = useAxisproTranslate();
  const acodaxNumberFormat = useAcodaxNumberFormat();
  const Navigate = useNavigate();
  const fileRef = useRef('');
  const [formData, setFormData] = useState({
    id: data.id,
    image: data.image
  });
  const [logoImg, setLogoImg] = useState('');
  const [isUploadImage, setIsUploadImage] = useState(false);
  const [save, setSave] = useState(false);
  const baseCurrency = user?.branch?.base_currency ?? '';

  const handleFieldChange = e => {
    let value;
    if (e.target.type === 'file' && e.target.files.length > 0) {
      value = e.target.files[0];
      setLogoImg(URL.createObjectURL(e.target.files[0]));
      setIsUploadImage(true);
    }
    setFormData({ ...formData, [e.target.name]: value });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setSave(true);
    axios({
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      },
      url:
        data && data.customer_id
          ? `sales/customer-upload-profile-image`
          : type === 'salesman'
          ? `sales/salesman-upload-profile-image`
          : `purchase/supplier-upload-profile-image`,
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          setIsUploadImage(false);
          setSave(false);
          showToast(response.data.message, 'success');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
        setSave(false);
      });
  };

  return (
    <div className="pt-3 pb-3 pe-1 ps-0">
      <div className="d-flex align-items-center flex-column justify-content-center mb-3">
        <div className="customer-image">
          {data && data.image ? (
            <Figure
              onClick={() => fileRef.current.click()}
              className="figure m-1"
            >
              {logoImg ? (
                save ? (
                  <div className="profile-image d-flex align-items-center justify-content-center">
                    <img
                      src={Loading}
                      alt="customer-image"
                      className="profile-image-gif"
                    />
                  </div>
                ) : (
                  <img
                    src={logoImg}
                    alt="customer-image"
                    className="profile-image"
                  />
                )
              ) : (
                <img
                  src={formData.image}
                  alt="customer-image"
                  className="profile-image"
                />
              )}
              <Form.Control
                type="file"
                name="image"
                ref={fileRef}
                onChange={handleFieldChange}
                hidden
              />
            </Figure>
          ) : (
            <FaUserSecret size={60} className="text-dark" />
          )}
        </div>
        <div className="mb-3">
          {isUploadImage ? (
            <Button
              onClick={handleSubmit}
              variant="success"
              size="sm"
              className="customer-image-save-button"
            >
              {save ? Translate('Saving') : Translate('Save')}
              <FaSave />
            </Button>
          ) : (
            <div
              className="image-upload-section"
              onClick={() => fileRef.current.click()}
            >
              <p className="m-0 text-primary">
                {Translate('click here to choose image')}
                <AiOutlineUpload size={18} />
                <Form.Control
                  type="file"
                  name="image"
                  ref={fileRef}
                  onChange={handleFieldChange}
                  hidden
                />
              </p>
            </div>
          )}
        </div>
      </div>
      <Row className="mb-2 basic-details-wrapper">
        <Col xs={6} className="pe-0 basic-details-label">
          <h6 className="mb-2 text-warning">{Translate(`Opening Balance`)}</h6>
        </Col>
        <Col xs={6} className="basic-details">
          <h6 className="mb-2 text-warning">
            {`${baseCurrency} ` +
              ' ' +
              acodaxNumberFormat(Math.abs(data?.opening_balance) ?? 0)}
            <span className="text-dark text-small m-0 fs--2 ps-2">
              {data?.opening_balance < 0
                ? '(Credit)'
                : data?.opening_balance > 0
                ? '(Debit)'
                : ''}
            </span>
          </h6>
        </Col>
      </Row>
      {data && data.salesman_name && !type ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Salesman')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6
              onClick={() => {
                Navigate(`/sales/salesman/info/${data.salesman_id}`);
              }}
              className="mb-2 text-primary cursor-pointer"
            >
              {data.salesman_name}
            </h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {(data && data.phone) ||
      (data && data.contact_number) ||
      (data && data.salesman_phone) ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Phone Number')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">
              {data.phone ||
                data.contact_number ||
                (data && data.salesman_phone)}
            </h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.emirates_id ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Emirates Id')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.emirates_id}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.customer_type ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Customer Type')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.customer_type}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data?.customer_group ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Customer Group')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">
              {data?.customer_group?.group_code +
                ' - ' +
                data?.customer_group?.name}
            </h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.sales_type_name ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Sales Type')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.sales_type_name}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.secondary_contact_number ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Second Number')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.secondary_contact_number}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {(data && data.email) ||
      (data && data.email_address) ||
      (data && data.salesman_email) ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Email Address')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">
              {data.email ||
                data.email_address ||
                (data && data.salesman_email)}
            </h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {/* {data && data.salesman_name && !type ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Salesman')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.salesman_name}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )} */}
      {data && data?.trn_no ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">
              {Translate(`${store?.globalConfiguration?.tax_label} Number`)}
            </h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data?.trn_no}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data?.trade_license_no ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Trade License no')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data?.trade_license_no}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.credit_status_name ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Credit status')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.credit_status_name}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.tax_name ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Tax')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.tax_name}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data?.tax_config ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Tax Configuration')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            {data?.tax_config === 'USE_ITEM_TAX' ? (
              <h6 className="mb-2">USE ITEM TAX</h6>
            ) : (
              <h6 className="mb-2">{data?.tax_config_tax_group?.name ?? ''}</h6>
            )}
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.currency_code ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Currency Code')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.currency_code}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.credit_limit ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Credit Limit')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.credit_limit}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.address ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Address')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.address}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.provision1 ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Provision 1')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.provision1}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.break_point ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Break Point')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.break_point}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
      {data && data.provision2 ? (
        <Row className="mb-2 basic-details-wrapper">
          <Col xs={6} className="pe-0 basic-details-label">
            <h6 className="mb-2">{Translate('Provision 2')}</h6>
          </Col>
          <Col xs={6} className="basic-details">
            <h6 className="mb-2">{data.provision2}</h6>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </div>
  );
}

BasicDetails.propTypes = {
  data: PropTypes.any,
  type: PropTypes.string
};

export default BasicDetails;

import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap';
import { FaChevronLeft, FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';
import ReportinLineItemLoading from 'components/report-components/ReportinLineItemLoading';
import LoadingItem from 'components/loading-screen/LoadingItem';
import { apiCall } from 'helpers/apiCalls';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import AppPagination from 'components/common/app-pagination/AppPagination';
import { BiCategory } from 'react-icons/bi';
import Search from 'components/search/Search';
import { useLocation } from 'react-router-dom';
import BackButton from 'components/common/BackButton';
import generatePaginationData from 'helpers/generatePaginationData';
import DownloadCenterBacktoTopButton from 'components/download-center/DownloadCenterBacktoTopButton';
import { AuthWizardContext } from 'context/Context';

const ItemAdvanceSearch = ({ setItem, name, keyName, advanceSearchType }) => {
  const navigate = useNavigate();
  const { user } = useContext(AuthWizardContext);
  const [show, setShow] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [categoriesArray, setCategoriesArray] = useState({});
  const [searchKey, setSearchKey] = useState(null);
  const [items, setItems] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [itemPage, setItemPage] = useState(0);
  const [loadMoreItems, setLoadMoreItems] = useState(false);
  const [showBacktoTop, setShowBacktoTop] = useState(false);

  const [queryParams] = useSearchParams();
  const location = useLocation();
  const categoryId = queryParams.get('category');
  const subcategoryId = queryParams.get('subcategory');
  const category_page = queryParams.get('category_page');
  const categorLimit = 5;
  const element = useRef(null);

  const handleBacktoTop = () => {
    if (element && element.current) {
      element.current.scrollTop = 0;
    }
  };

  const handleWindowScroll = e => {
    if (e.target.scrollTop > 300 && !showBacktoTop) {
      setShowBacktoTop(true);
    } else if (e.target.scrollTop < 301 && showBacktoTop) {
      setShowBacktoTop(false);
    }
  };

  const handleScreen = () => {
    if (show) {
      setSearchKey(null);
      navigate(location.pathname);
    }
    setShow(!show);
  };

  const handleSelectItem = item => {
    let event = {
      item: item,
      label: item.item_name,
      value: item[keyName ?? 'id']
    };

    let action = {
      name: name
    };

    setItem(event, action);
    setSearchKey(null);
    navigate(location.pathname);
    setShow(false);
  };

  const getItems = async page => {
    setLoadingItem(true);
    setLoadMoreItems(false);
    setItemPage(page);

    try {
      const Itemsdata = await apiCall({
        url: 'inventory/items',
        params: {
          query: searchKey,
          category_id: categoryId,
          subcategory_id: subcategoryId,
          page: page ?? 1
        }
      });

      if (Itemsdata && typeof Itemsdata === 'object') {
        Itemsdata.data &&
          setItems(prev =>
            page > 1 ? [...prev, ...Itemsdata.data] : Itemsdata.data
          );
        setLoadingItem(false);
        setLoadMoreItems(
          Itemsdata.meta &&
            Itemsdata.meta.last_page &&
            page < Itemsdata.meta.last_page
            ? true
            : false
        );
      }
    } catch (error) {
      setLoadingItem(false);
    }
  };

  const getCategories = async () => {
    setLoadingCategory(true);
    const Categorydata = await apiCall({
      url: 'settings/category/list-category-and-subcategory',
      params: { page: category_page }
    });

    setCategoryData(Categorydata);

    setLoadingCategory(false);
  };

  useEffect(() => {
    show && getCategories();
  }, [show]);

  useEffect(() => {
    setCategoriesArray(
      categoryData && categoryData.categories
        ? generatePaginationData(
            categoryData.categories,
            categorLimit,
            category_page ? parseInt(category_page) : 1
          )
        : {}
    );
  }, [category_page, categoryData]);

  useEffect(() => {
    if (
      (show && !loadingItem && itemPage < 1) ||
      (show && !loadingItem && loadMoreItems)
    ) {
      getItems(itemPage + 1);
    }
  }, [show, loadMoreItems]);

  useEffect(() => {
    if (show) {
      setItems([]);
      getItems(1);
    }
  }, [searchKey, categoryId, subcategoryId]);

  useEffect(() => {
    let id = subcategoryId ? subcategoryId : categoryId ? categoryId : null;
    if (
      id &&
      categoryData &&
      categoryData.sub_categories &&
      Array.isArray(categoryData.sub_categories)
    ) {
      setCategoriesArray(old => ({
        ...old,
        data: categoryData.sub_categories.filter(
          item =>
            item[!subcategoryId ? 'category_id' : 'sub_category_id'] === id
        )
      }));
    } else if (
      categoryData.categories &&
      Array.isArray(categoryData.categories)
    ) {
      setCategoriesArray(
        generatePaginationData(
          categoryData.categories,
          categorLimit,
          category_page ? parseInt(category_page) : 1
        )
      );
    }
  }, [categoryId, subcategoryId]);

  return (
    <>
      <OverlayTrigger overlay={<Tooltip>Advance search</Tooltip>}>
        <Button
          variant="primary"
          size="sm"
          className="px-2 align-self-stretch d-lex justify-content-center align-items-center"
          onClick={handleScreen}
        >
          <FaSearch size={12} />
        </Button>
      </OverlayTrigger>

      <Modal
        show={show}
        onHide={handleScreen}
        backdrop="static"
        size="xl"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title>ITEM LIST</Modal.Title>
        </Modal.Header>
        {showBacktoTop ? (
          <DownloadCenterBacktoTopButton onClick={handleBacktoTop} />
        ) : null}
        <Modal.Body ref={element} onScroll={handleWindowScroll}>
          {categoryId && (
            <BackButton size="sm" className="mb-3 fs--1 px-2">
              <FaChevronLeft />
            </BackButton>
          )}
          <Row md={3} lg={5}>
            {loadingCategory
              ? [1, 2, 3, 4, 5].map(index => (
                  <Col key={index} className="mb-3">
                    <LoadingItem />
                  </Col>
                ))
              : categoriesArray && categoriesArray.data
              ? categoriesArray.data.map((category, index) => (
                  <Col key={index} className="mb-3">
                    <Link
                      to={`${location.pathname}?${
                        category_page ? `category_page=${category_page}&` : ''
                      }${
                        categoryId ? `subcategory=${category.id}&` : ''
                      }category=${categoryId ?? category.id}`}
                    >
                      <div className="border p-3 d-flex flex-column align-items-center">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            width: 80,
                            height: 60,
                            ...(category.images &&
                            Array.isArray(category.images) &&
                            category.images.length > 0
                              ? {
                                  backgroundImage: `url(${category.images[0]})`,
                                  backgroundPosition: 'center center',
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat'
                                }
                              : null)
                          }}
                        >
                          {!category.images ||
                          !Array.isArray(category.images) ||
                          category.images.length < 1 ? (
                            <BiCategory size={20} />
                          ) : null}
                        </div>
                        <p className="h6 mb-0 text-center pt-2">
                          {category.name}
                        </p>
                      </div>
                    </Link>
                  </Col>
                ))
              : null}
          </Row>

          <div className="my-3">
            {!loadingCategory &&
              !categoryId &&
              categoriesArray &&
              categoriesArray.meta && (
                <AppPagination
                  data={categoriesArray}
                  url={`${location.pathname}?category_page=`}
                />
              )}
          </div>

          <div className="d-flex justify-content-end py-3">
            <div style={{ width: '100%', maxWidth: 300 }}>
              <Search
                placeholder="Search item"
                className="fs--1 w-100"
                value={searchKey}
                onChange={e => setSearchKey(e.target.value)}
              />
            </div>
          </div>

          <Table striped bordered responsive>
            <thead
              className="text-dark text-uppercase"
              style={{ backgroundColor: 'rgb(237,243,248)' }}
            >
              <tr>
                <th className="table-header-size">Item Code</th>
                <th style={{ minWidth: '250px' }} className="table-header-size">
                  Item Name
                </th>
                <th className="table-header-size">Category</th>
                <th className="table-header-size">Price</th>
              </tr>
            </thead>
            <tbody>
              {items.length > 0 ? (
                items.map((item, index) => (
                  <tr key={index}>
                    <th className="fs--1 py-1">
                      <Button
                        variant="transparant"
                        className="fs--1 btn-link py-0"
                        onClick={() => handleSelectItem(item)}
                      >
                        {item.stock_id}
                      </Button>
                    </th>
                    <td className="fs--1 py-1">{item.item_name}</td>
                    <td className="fs--1 py-1">{item.category_name}</td>
                    <td className="fs--1 py-1">
                      {item[
                        advanceSearchType === 'sales'
                          ? 'sale_rate_formatted'
                          : 'purchase_rate_formatted'
                      ] ?? `${user?.branch?.base_currency} 0.00`}
                    </td>
                  </tr>
                ))
              ) : !loadingItem && items.length < 1 ? (
                <tr>
                  <td colSpan={5}>
                    <Badge bg="danger">No results found</Badge>
                  </td>
                </tr>
              ) : null}

              {loadingItem ? (
                <ReportinLineItemLoading colSpan={5} />
              ) : !loadingItem && !loadMoreItems ? (
                <tr>
                  <td colSpan={5}>
                    <div className="d-flex flex-column align-items-center">
                      <span>End reached! &#128578;</span>
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </>
  );
};

ItemAdvanceSearch.propTypes = {
  setItem: PropTypes.func,
  name: PropTypes.string,
  keyName: PropTypes.string,
  advanceSearchType: PropTypes.string.isRequired
};

export default ItemAdvanceSearch;

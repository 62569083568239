import { React, useContext, useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { MdAddCircle } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';

import generateFormDataHelper from 'helpers/generateFormDataHelper';
import SelectItem from 'components/form/SelectItem';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import TableLoading from 'module/Report/Common/TableLoading';
import { showToast } from 'module/Common/Toast/toast';
import { BsExclamationCircleFill } from 'react-icons/bs';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectBatch from 'components/form/SelectBatch';
import useAxisproPermission from 'hooks/useAxisproPermission';
import classNames from 'classnames';
import ItemCodeAutoComplete from 'components/form/ItemCodeAutoComplete';
import SelectTaxGroup from 'components/form/SelectTaxGroup';
import PurchaseHistoryModal from 'components/purchase-history-modal/PurchaseHistoryModal';
import { DeleteContext } from 'context/Context';

function PurchaseTableForm({
  editItemData,
  onTableItemChange,
  conversion,
  transaction_date,
  taxIncluded,
  defaultKeys,
  isLoading,
  enableBatchSection,
  batchIsEditable,
  hide,
  transType,
  enableTaxGroup,
  supplierDetails,
  showPurchaseHistory
}) {
  const axisProPermission = useAxisproPermission();
  const Translate = useAxisproTranslate();
  const { dispatch: configConfirm } = useContext(DeleteContext);
  const submitButtonRef = useRef(null);
  const itemCodeElementRef = useRef(null);
  const [tableData, setTableData] = useState(
    editItemData ? editItemData : defaultKeys
  );

  const itemIsKit = ['1', 'true'].tableData?.is_kit?.toString()?.toLowerCase();

  const showPurchaseHistoryModal =
    showPurchaseHistory && supplierDetails?.id && tableData?.stock_id;

  const handleFieldChange = (e, action) => {
    let newData = generateFormDataHelper(e, action, tableData);

    if (!action && e.target.name === 'discount_unit_ref') {
      newData.discount_amount = 0;
      newData.discount_percent = 0;
    }

    if (action?.name === 'item_display_name') {
      if (enableTaxGroup) {
        let taxGroupDetails = {};
        if (supplierDetails?.tax_config === 'USE_ITEM_TAX') {
          taxGroupDetails = newData?.item_display_name_ref?.item;
        } else {
          const supplierTaxConfigTaxGroup =
            supplierDetails?.tax_config_tax_group;
          taxGroupDetails.tax_group_id = supplierTaxConfigTaxGroup?.id;
          taxGroupDetails.tax_group_name = supplierTaxConfigTaxGroup?.name;
        }

        newData.tax_group_id = taxGroupDetails?.tax_group_id;
        newData.tax_group_id_ref = {
          label: taxGroupDetails?.tax_group_name,
          value: taxGroupDetails?.tax_group_id
        };
      }

      if (enableBatchSection) {
        newData.has_batch_inventory = e?.item?.has_batch_inventory;
      }
      newData.is_kit = e?.item?.type === 'kit' ? 1 : 0;
      newData.stock_id = e ? e.item.stock_id : '';
      newData.unit_price = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.unit_price
          : e.item.purchase_rate
        : '';
      newData.purchase_rate_ref = e?.item?.purchase_rate;
      newData.purchase_rate_formatted_ref = e?.item?.purchase_rate_formatted;
      newData.unit_ref = newData.is_kit ? 'KIT' : e?.item?.unit_name ?? '';
      newData.quantity = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.quantity
          : 1
        : '';
      newData.discount_amount = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.discount_amount
          : 0
        : 0;
      newData.discount_percent = e
        ? editItemData && editItemData.stock_id === e.item.stock_id
          ? editItemData.discount_percent
          : 0
        : 0;

      newData.item_description =
        newData?.item_display_name_ref?.item?.item_description || '';
    }

    if (
      e?.target?.name === 'unit_price' ||
      action?.name === 'item_display_name'
    ) {
      newData.skip_unit_price_warning_ref = false;
    }

    setTableData(newData);
  };

  function handleSubmitOnKeyPress(event, key) {
    let activeKey = event?.key?.toLowerCase();
    if (activeKey === 'enter') event.preventDefault();
    if (activeKey === key) {
      submitButtonRef?.current?.click();
    }
  }

  const handleSubmit = e => {
    e?.preventDefault();
    if (hide) return;
    let error =
      tableData.quantity <= 0
        ? Translate('Invalid quantity, value must be greater than 0')
        : conversion &&
          parseFloat(tableData.quantity_ref) < parseFloat(tableData.quantity)
        ? `${Translate('Invalid quantity, value must be less than')} ${
            tableData.quantity_ref
          }`
        : parseFloat(tableData.discount_amount) < 0
        ? Translate(
            'Invalid discount amount, the amount must be between 0 and unit price'
          )
        : tableData.discount_unit_ref === '$' &&
          parseFloat(tableData.discount_amount) >
            parseFloat(tableData.unit_price)
        ? Translate(
            'Invalid discount amount, the amount must be less than or equal to the unit price'
          )
        : (tableData.discount_unit_ref === '%' &&
            parseFloat(tableData.discount_percent) > 100) ||
          parseFloat(tableData.discount_percent) < 0
        ? Translate(
            'Invalid discount percentage, the percentage must be between 0 and 100'
          )
        : tableData?.has_batch_inventory && !tableData?.batch_number
        ? Translate('Invalid batch, The batch number field is required.')
        : null;

    //  edit case validation
    // if (!conversion && tableData?.quantity > 0) {
    //   let quantity_value = parseFloat(tableData.quantity);
    //   error =
    //     editItemData?.quantity_issued > 0 &&
    //     quantity_value < parseFloat(editItemData.quantity_issued)
    //       ? Translate(
    //           'Invalid quantity, value cannot be reduced below the already issued quantity of '
    //         ) + editItemData.quantity_issued
    //       : editItemData?.qty_sent > 0 &&
    //         quantity_value < parseFloat(editItemData.qty_sent)
    //       ? Translate(
    //           'Invalid quantity, value cannot be reduced below the already sent quantity of '
    //         ) + editItemData.qty_sent
    //       : editItemData?.qty_invoiced > 0 &&
    //         quantity_value < parseFloat(editItemData.qty_invoiced)
    //       ? Translate(
    //           'Invalid quantity, value cannot be reduced below the already invoiced quantity of '
    //         ) + editItemData.qty_invoiced
    //       : null;
    // }

    // check if the unit price is greater than the item master purchase rate
    const itemPurchaseRate = parseFloat(tableData?.purchase_rate_ref);
    const itemUnitRate = parseFloat(tableData?.unit_price);
    if (
      transType === 'PI' &&
      !tableData?.skip_unit_price_warning_ref &&
      itemPurchaseRate > 0 &&
      itemUnitRate > itemPurchaseRate
    ) {
      configConfirm({
        type: 'CONFIG',
        payload: {
          show: true,
          title: 'Warning!',
          message: `Provided unit price is higher than purchase rate. The actual
              purchase rate is ${tableData?.purchase_rate_formatted_ref}, are
              you sure you want to continue?`,
          submitButtonVariant: 'warning',
          buttonText: Translate('Continue'),
          cancelButtonVariant: 'secondary',
          onSubmit: event => {
            event?.preventDefault();
            handleAddOrUpdate(error, {
              ...tableData,
              skip_unit_price_warning_ref: true
            });
            configConfirm({
              type: 'RESET'
            });
          }
        }
      });
      return;
    }

    handleAddOrUpdate(error, tableData);
  };

  const handleAddOrUpdate = (error, data) => {
    if (error) {
      showToast(error, 'error');
    } else if (!editItemData) {
      onTableItemChange(
        {
          ...data,
          add_to_cart_ref: true,
          edit: true,
          trans_date: transaction_date,
          tax_included: taxIncluded ? 1 : 0
        },
        'ADD',
        true
      );
      setTableData(defaultKeys);
    } else {
      onTableItemChange(
        {
          ...data,
          add_to_cart_ref: true,
          edit: true,
          trans_date: transaction_date,
          tax_included: taxIncluded ? 1 : 0,
          removeThisKeys: ['errors']
        },
        'UPDATE',
        true
      );
    }
  };

  useEffect(() => {
    if (itemCodeElementRef?.current && !hide) {
      setTimeout(() => {
        itemCodeElementRef.current?.focus();
      }, 10);
    }
  }, [hide, itemCodeElementRef]);

  useEffect(() => {
    setTableData(editItemData ? editItemData : defaultKeys);
  }, [supplierDetails?.id]);

  return (
    <>
      {isLoading ? (
        <TableLoading
          row={1}
          column={
            enableBatchSection && enableTaxGroup
              ? 12
              : enableBatchSection || enableTaxGroup
              ? 11
              : 10
          }
        />
      ) : (
        <tr
          className={classNames({ 'd-none': hide })}
          style={{ borderBottom: editItemData ? '' : '1.5px dashed #ccc' }}
        >
          <td className="fs--1 p-1 text-center">
            {editItemData && editItemData.errors ? (
              <BsExclamationCircleFill size={13} className="text-danger" />
            ) : (
              '#'
            )}
          </td>
          <td className="p-1 fs--1">
            {conversion ? (
              tableData.stock_id
            ) : (
              <ItemCodeAutoComplete
                type="text"
                name="stock_id"
                keyName="item_name"
                SelectItemKeyName="item_display_name"
                value={tableData.stock_id}
                onChange={handleFieldChange}
                onKeyDown={event => handleSubmitOnKeyPress(event, 'enter')}
                filter_keys={{
                  exclude_kits: true
                }}
              />
            )}
          </td>
          <td className="p-1 fs--1">
            {conversion ? (
              <>
                {tableData.item_display_name ? (
                  <span>{tableData.item_display_name}</span>
                ) : null}
                {tableData.item_description ? (
                  <p className="small text-muted m-0">
                    {tableData.item_description}
                  </p>
                ) : null}
              </>
            ) : (
              <>
                <SelectItem
                  value={tableData.item_display_name_ref}
                  handleFieldChange={handleFieldChange}
                  name="item_display_name"
                  keyName="item_name"
                  advanceSearch
                  advanceSearchType="purchase"
                  placeholder={Translate('Search...')}
                  style={{ borderRadius: 'none' }}
                  withAddButton={axisProPermission('create-item')}
                  filter_keys={{
                    exclude_kits: true
                  }}
                  includeDetails
                />

                {showPurchaseHistoryModal ? (
                  <PurchaseHistoryModal
                    supplierId={supplierDetails?.id}
                    stockId={tableData?.stock_id}
                  />
                ) : null}

                <Form.Control
                  as="textarea"
                  placeholder={Translate('Enter Description...')}
                  rows={2}
                  name="item_description"
                  onChange={handleFieldChange}
                  onKeyDown={event => handleSubmitOnKeyPress(event, 'enter')}
                  value={tableData.item_description}
                  className={showPurchaseHistoryModal ? null : 'mt-1'}
                />
              </>
            )}
          </td>

          {enableBatchSection ? (
            <td className="p-1 fs--1">
              {tableData?.has_batch_inventory ||
              tableData?.batch_number_display?.length > 0 ? (
                !batchIsEditable && conversion ? (
                  tableData.batch_number_display
                ) : (
                  <SelectBatch
                    value={tableData.batch_number_ref}
                    stock_id={tableData?.stock_id}
                    handleFieldChange={handleFieldChange}
                    name="batch_number"
                    keyName="batch_number"
                    placeholder={Translate('Search...')}
                    isDisabled={!tableData?.stock_id}
                    withAddButton
                  />
                )
              ) : (
                '-'
              )}
            </td>
          ) : null}

          <td className="p-1 fs--1 text-uppercase">
            {tableData?.unit_ref
              ? tableData.unit_ref
              : tableData?.unit_name
              ? tableData.unit_name
              : '-'}
          </td>

          {enableTaxGroup ? (
            <td>
              <SelectTaxGroup
                withTaxDetails
                name="tax_group_id"
                placeholder={Translate('Search...')}
                value={tableData?.tax_group_id_ref}
                onChange={handleFieldChange}
                isClearable={false}
              />
            </td>
          ) : null}

          <td className="p-1 fs--1">
            <Form.Control
              type="number"
              name="quantity"
              onChange={handleFieldChange}
              onKeyDown={event => handleSubmitOnKeyPress(event, 'enter')}
              min={1}
              max={conversion ? tableData.quantity_ref : undefined}
              value={tableData.quantity}
            />
          </td>
          <td className="p-1 fs--1">
            {(conversion && transType === 'DN') || itemIsKit ? (
              tableData.unit_price + '/-'
            ) : (
              <Form.Control
                type="number"
                step="0.1"
                min={0}
                name="unit_price"
                onChange={handleFieldChange}
                onKeyDown={event => handleSubmitOnKeyPress(event, 'enter')}
                value={tableData.unit_price}
              />
            )}
          </td>
          <td className="p-1 fw-bold fs--1 text-uppercase"></td>
          <td className="p-1 fs--1">
            {(conversion && transType === 'DN') || itemIsKit ? (
              tableData.discount_amount + '/-'
            ) : (
              <div className="d-flex flex-row ">
                <Form.Control
                  className="w-100"
                  type="number"
                  step="0.1"
                  min={0}
                  max={
                    tableData.discount_unit_ref === '%'
                      ? 100
                      : tableData.unit_price > 0
                      ? tableData.unit_price
                      : 0
                  }
                  name={
                    tableData.discount_unit_ref === '%'
                      ? 'discount_percent'
                      : 'discount_amount'
                  }
                  onChange={handleFieldChange}
                  onKeyDown={event => handleSubmitOnKeyPress(event, 'enter')}
                  value={
                    tableData[
                      tableData.discount_unit_ref === '%'
                        ? 'discount_percent'
                        : 'discount_amount'
                    ]
                  }
                />
                <Form.Select
                  className="bg-primary text-white border-primary"
                  aria-label="Discount unit"
                  style={{ width: '80px' }}
                  name="discount_unit_ref"
                  onChange={handleFieldChange}
                  onKeyDown={event => handleSubmitOnKeyPress(event, 'tab')}
                  value={tableData.discount_unit_ref}
                >
                  <option value="$">$</option>
                  <option value="%">%</option>
                </Form.Select>
              </div>
            )}
          </td>
          <td className="p-1 fw-bold fs--1 text-uppercase"></td>
          <td>
            <div className="d-flex flex-row justify-content-center w-100">
              <Button
                ref={submitButtonRef}
                variant="transparent"
                type="button"
                onClick={handleSubmit}
                className="p-0 text-success"
                style={{ boxShadow: 'none' }}
                disabled={!tableData.item_display_name}
              >
                {editItemData ? (
                  <IoMdCheckmarkCircle size={20} />
                ) : (
                  <MdAddCircle size={20} />
                )}
              </Button>
              {editItemData && (
                <Button
                  size="sm"
                  variant="transparent"
                  className="p-0 ms-1 text-danger"
                  onClick={() =>
                    onTableItemChange(
                      { ...editItemData, removeThisKeys: ['edit'] },
                      'UPDATE'
                    )
                  }
                >
                  <AiFillCloseCircle size={19} />
                </Button>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
}

PurchaseTableForm.propTypes = {
  editItemData: PropTypes.object,
  onTableItemChange: PropTypes.func,
  conversion: PropTypes.bool,
  transaction_date: PropTypes.string,
  taxIncluded: PropTypes.bool,
  defaultKeys: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  enableBatchSection: PropTypes.bool,
  batchIsEditable: PropTypes.bool,
  hide: PropTypes.bool,
  transType: PropTypes.string,
  enableTaxGroup: PropTypes.bool,
  supplierDetails: PropTypes.object,
  showPurchaseHistory: PropTypes.bool
};

export default PurchaseTableForm;

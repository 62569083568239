import { React, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { ButtonGroup, Card, Container, Tab, Tabs } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { FaUserCog } from 'react-icons/fa';

import { useBreakpoints } from 'hooks/useBreakpoints';
import { EditButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import CustomerView from './CustomerView';
import ActivityLog from '../Common/ActivityLog';
import PersonBasedTransactions from '../Common/PersonBasedComponents/PersonBasedTransactions';
function CustomerTabView({ customerData, loading, fetchData, Offcanvas }) {
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState('overview');
  const [upload, setUpload] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    activity_log: false,
    transaction: false
  });
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const permission = {
    update_cs: getAcodaxPermissionSlug('CS', 'update'),
    history_lg: getAcodaxPermissionSlug('LG', 'show-all')
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        {!Offcanvas && (
          <Card.Header
            className={
              breakpoints.up('sm')
                ? 'd-flex m-0 align-items-center border-bottom'
                : 'border-bottom'
            }
          >
            <>
              <div className="d-flex m-0 align-items-center">
                <FaUserCog size={28} className="me-2 text-dark" />
                <h5
                  className="mb-0 d-flex text-uppercase view-page-header"
                  style={{ fontSize: '25px' }}
                >
                  {customerData && customerData.name ? customerData.name : ''}
                </h5>
              </div>
              <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
                <MoreButton setUpload={setUpload} type="CUSTOMER" />
                {permission?.update_cs ? (
                  <EditButton
                    style={{ boxShadow: 'none' }}
                    to={`/sales/customer/edit/${
                      customerData.id
                    }?${createSearchParams({
                      ...allQueryParams
                    })}`}
                  />
                ) : (
                  ''
                )}
              </ButtonGroup>
            </>
          </Card.Header>
        )}
        <Card.Body
          className={loading && 'd-flex '}
          style={{ height: Offcanvas ? '90vh' : '' }}
        >
          <div className="d-flex flex-wrap">
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Customer Name')}:&nbsp;
                </h6>
                <h6 className="mb-2 text-uppercase">
                  {customerData && customerData.name
                    ? customerData.name
                    : 'null'}
                </h6>
              </div>
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Unused Credits')}:&nbsp;
                </h6>
                <h6
                  className={
                    customerData.unused_credits === 0
                      ? 'mb-2 text-primary fs-1'
                      : 'mb-2 text-success fs-1'
                  }
                >
                  {customerData && customerData.unused_credits_formatted
                    ? customerData.unused_credits_formatted
                    : 'null'}
                </h6>
              </div>
            </div>
            <div className="w-100 mb-2 view-page-contents">
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Customer ID')}:&nbsp;
                </h6>
                <h6 className="mb-2">
                  {customerData && customerData.customer_id
                    ? customerData.customer_id
                    : 'update'}
                </h6>
              </div>
              <div className="d-flex gap-3">
                <h6 className="mb-2 fw-bold">
                  {Translate('Outstanding Receivables')}
                  :&nbsp;
                </h6>
                <h6
                  className={
                    customerData.unused_credits === 0
                      ? 'mb-2 text-warning fs-1'
                      : 'mb-2 text-danger fs-1'
                  }
                >
                  {customerData && customerData.total_outstanding_formatted
                    ? customerData.total_outstanding_formatted
                    : 'null'}
                </h6>
              </div>
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-overview-page-style">
                  <CustomerView customerData={customerData} />
                </SimpleBar>
              </Tab>

              <Tab eventKey="transaction" title={Translate('Transactions')}>
                <SimpleBar className="simplebar-overview-page-style">
                  <PersonBasedTransactions
                    person_id={customerData?.id}
                    person_name="customer_id"
                    url={'sales/customer-balance-statements'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.transaction}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              {permission?.history_lg ? (
                <Tab eventKey="activity" title={Translate('Activity Log')}>
                  <SimpleBar className="simplebar-overview-page-style">
                    <ActivityLog
                      id={customerData.id}
                      type={'CUSTOMER'}
                      activeTabKey={key}
                      tabApiCallCompleted={tabApiCallCompleted?.activity_log}
                      setTabApiCallCompleted={setTabApiCallCompleted}
                    />
                  </SimpleBar>
                </Tab>
              ) : null}
              {customerData.media && customerData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={customerData}
                      fetchData={fetchData}
                      setKey={setKey}
                      deletePermsission={permission?.update}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {customerData && customerData.created_user_full_name
                ? customerData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {customerData && customerData.created_at
                ? customerData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'CUSTOMER'}
        id={customerData.id}
        setKey={setKey}
      />
    </>
  );
}

CustomerTabView.propTypes = {
  customerData: PropTypes.any,
  loading: PropTypes.bool,
  fetchData: PropTypes.func,
  Offcanvas: PropTypes.bool
};

export default CustomerTabView;

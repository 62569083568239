import { React, useState } from 'react';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { BsReceipt } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import { useBreakpoints } from 'hooks/useBreakpoints';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import PaymentVoucherView from './PaymentVoucherView';
import UnUsedCreditsPopup from '../Common/UnUSedCredits/UnUsedCreditsPopup';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import PaymentScreenPopup from '../Common/PaymentScreenPopup';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import ReFundPopup from '../Common/ReFundPopup';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import RecurringRules from '../Common/RecurringRules';
import AllocationsTable from '../Common/Tables/AllocationsTable';
import PDFView from 'components/PDFViewerButton/PDFView';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';

function PaymentVoucherTabView({
  paymentVoucherData,
  loading,
  fetchData,
  voidScreen,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport
}) {
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [unUsedCredits, setUnUsedCredits] = useState(false);
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const permission = {
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    show_sp: getAcodaxPermissionSlug('SP', 'show'),
    show_pv: getAcodaxPermissionSlug('PV', 'show'),
    print_pv: getAcodaxPermissionSlug('PV', 'show'),
    send_mail_pv: getAcodaxPermissionSlug('PV', 'send-mail'),
    update_pv: getAcodaxPermissionSlug('PV', 'update')
  };
  const [person] = useState({
    personType: paymentVoucherData.person_type_id,
    personId: paymentVoucherData.person_id,
    name: paymentVoucherData.person_name,
    value: paymentVoucherData.person_id,
    personName: paymentVoucherData.person_name,
    refundAmount: paymentVoucherData.balance,
    reference: paymentVoucherData.reference,
    refundAmountFormatted: paymentVoucherData.balance_formatted,
    transType: paymentVoucherData.trans_type,
    transNo: paymentVoucherData.id
  });
  const [payment, setPayment] = useState(false);
  const [reFund, setReFund] = useState(false);
  const [recurring, setRecurring] = useState(false);
  const [upload, setUpload] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    allocations: false
  });
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  const getEmail = () => {
    switch (paymentVoucherData.person_type_id) {
      case 'CUSTOMER':
        return paymentVoucherData.person ? paymentVoucherData.person.email : '';
      case 'SUPPLIER':
        return paymentVoucherData.person
          ? paymentVoucherData.person.email_address
          : '';
      default:
        return '';
    }
  };

  const sendEmail = () => {
    const email = getEmail();
    setEmailAddress(email);
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
  };

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <div className="d-flex m-0 align-items-center">
            <BsReceipt size={28} className="me-2 text-dark" />
            <h5 className="mb-0 d-flex text-uppercase view-page-header">
              {Translate('Payment Voucher')}
            </h5>
          </div>
          {paymentVoucherData &&
            paymentVoucherData.custom_voucher &&
            paymentVoucherData.custom_voucher.title && (
              <Badge className="ms-2 fs--1" bg="warning">
                {paymentVoucherData.custom_voucher.title}
              </Badge>
            )}
          {paymentVoucherData && paymentVoucherData.is_recurring ? (
            <Badge bg="success" className="ms-2 fs--1">
              {Translate('Recurring')} <TiTick />
            </Badge>
          ) : (
            ''
          )}
          {voidTransactionReport && (
            <Badge bg="danger" className="ms-2 fs--1">
              {Translate('Voided')} <TiTick />
            </Badge>
          )}
          <ButtonGroup className="ms-auto d-flex gap-2 align-items-center">
            {voidScreen || offCanvas ? (
              ''
            ) : (
              <MoreButton
                sendEmail={sendEmail}
                setUpload={setUpload}
                setReFund={setReFund}
                setPayment={setPayment}
                setRecurring={setRecurring}
                recurring
                disableRecurring={paymentVoucherData.is_recurring === 1}
                refundValiadtions={
                  paymentVoucherData &&
                  paymentVoucherData.person_type_id &&
                  paymentVoucherData.person_type_id === 'SUPPLIER'
                }
                disableRefundButton={
                  Math.round(paymentVoucherData.balance) === 0
                }
                paymentValidations={
                  paymentVoucherData &&
                  paymentVoucherData.person_id &&
                  paymentVoucherData.person_type_id === 'CUSTOMER'
                }
                disablePaymentButton={
                  Math.abs(paymentVoucherData.alloc) ===
                  Math.abs(paymentVoucherData.amount)
                }
                type={'PV'}
                emailPermission={permission.print_pv}
              />
            )}
            {voidScreen ||
            (paymentVoucherData && paymentVoucherData.custom_voucher) ||
            offCanvas ? (
              ''
            ) : permission.update_pv ? (
              <EditButton
                to={`/finance/payment-voucher/edit/${paymentVoucherData.id}`}
              />
            ) : (
              ''
            )}
            {!voidTransactionReport && permission.print_pv && (
              <ViewPagePrintButton
                variant="danger"
                className="text-white"
                style={{ boxShadow: 'none' }}
                url={`/finance/payment-voucher/print/${paymentVoucherData.id}`}
                pageTitle="Payment voucher print view"
              />
            )}
            {offCanvas ? (
              <CloseButton
                setShowVoucherDetailArea={setShowVoucherDetailArea}
              />
            ) : (
              ''
            )}
          </ButtonGroup>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div>
              {paymentVoucherData && paymentVoucherData.person_name ? (
                <PersonName
                  label={
                    paymentVoucherData &&
                    paymentVoucherData.person_type_id === 'CUSTOMER'
                      ? Translate('Customer Name')
                      : paymentVoucherData &&
                        paymentVoucherData.person_type_id === 'SUPPLIER'
                      ? Translate('Supplier Name')
                      : Translate('Name')
                  }
                  value={paymentVoucherData.person_name}
                  view={true}
                  permission={
                    paymentVoucherData.person_type_id === 'CUSTOMER'
                      ? permission.show_cs
                      : paymentVoucherData.person_type_id === 'SUPPLIER'
                      ? permission.show_sp
                      : false
                  }
                  setCustomerDetailsArea={
                    paymentVoucherData.person_type_id === 'CUSTOMER'
                      ? setCustomerDetailsArea
                      : paymentVoucherData.person_type_id === 'SUPPLIER'
                      ? setSupplierDetailsArea
                      : null
                  }
                />
              ) : null}
              {paymentVoucherData.trans_date_formatted ? (
                <MainDetails
                  label={'Transaction Date'}
                  value={paymentVoucherData.trans_date_formatted}
                />
              ) : null}
            </div>
            <div className=" view-page-contents  d-flex flex-column">
              {paymentVoucherData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/finance/voucher-inquiry?entry=${paymentVoucherData?.id}&layout=column`}
                  value={paymentVoucherData?.reference}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title="Voiding Form">
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={paymentVoucherData.trans_type}
                      transId={paymentVoucherData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar className="simplebar-view-page-style">
                  <PaymentVoucherView paymentVoucherData={paymentVoucherData} />
                </SimpleBar>
              </Tab>
              <Tab eventKey="allocations" title={Translate('Allocations')}>
                <SimpleBar className="simplebar-view-page-style">
                  <AllocationsTable
                    id={paymentVoucherData?.id}
                    t_type={'PV'}
                    activeTabKey={key}
                    tabApiCallCompleted={tabApiCallCompleted?.allocations}
                    setTabApiCallCompleted={setTabApiCallCompleted}
                  />
                </SimpleBar>
              </Tab>
              {paymentVoucherData.media &&
              paymentVoucherData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <AttachementsView
                      data={paymentVoucherData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {paymentVoucherData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`/finance/payment-voucher/print/${paymentVoucherData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <Card.Footer className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created By')}:&nbsp;</h6>
            <h6 className="m-0">
              {paymentVoucherData && paymentVoucherData.created_user_full_name
                ? paymentVoucherData.created_user_full_name
                : 'null'}
            </h6>
          </div>
          <div className="d-flex align-items-center gap-3">
            <h6 className="m-0 fw-bold">{Translate('Created At')}:&nbsp;</h6>
            <h6 className="m-0">
              {paymentVoucherData && paymentVoucherData.created_at
                ? paymentVoucherData.created_at
                : 'null'}
            </h6>
          </div>
        </Card.Footer>
      </Card>
      <ReFundPopup
        show={reFund}
        popupData={person}
        fetchData={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        onHide={() => setReFund(false)}
      />
      <UnUsedCreditsPopup
        show={unUsedCredits}
        onHide={() => setUnUsedCredits(false)}
        person={person}
        transType={paymentVoucherData.trans_type}
        transId={paymentVoucherData.id}
        reference={paymentVoucherData.reference}
        dueAmount={paymentVoucherData.amount - paymentVoucherData.alloc}
        reLoad={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
      />
      <PaymentScreenPopup
        show={payment}
        person={person}
        invoiceData={paymentVoucherData}
        onHide={() => setPayment(false)}
        fetchData={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        dueAmount={paymentVoucherData.amount - paymentVoucherData.alloc}
      />
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={handleSupplierDetailsArea}
        value={person}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'PV'}
        id={paymentVoucherData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'finance/payment-voucher/send-email/' + paymentVoucherData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={emailAddress}
      />
      <RecurringRules
        show={recurring}
        type="PV"
        id={paymentVoucherData.id}
        fetchData={fetchData}
        onHide={() => setRecurring(false)}
      />
    </>
  );
}

PaymentVoucherTabView.propTypes = {
  paymentVoucherData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool
};

export default PaymentVoucherTabView;

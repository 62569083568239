import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import RentalContractTabView from 'module/Common/ViewPages/RentalContract/RentalContractTabView';

function RentalContractInfo({
  rentalId,
  rendering,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `sales/rental-contracts/${rentalId}`;
        setTimeout(
          await axios
            .get(url, {
              params: {
                trans_type: voidTransactionReport ? 'CT' : '',
                trans_no: voidTransactionReport ? firstId : ''
              }
            })
            .then(res => {
              if (res.data.success) {
                setData(res.data.data);
                setLoading(false);
              }
            })
            .catch(error => {
              console.log(error);
            }),
          1000
        );
      }
    } else {
      if (rentalId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `sales/rental-contracts/${rentalId}`;
        await axios
          .get(url, {
            params: {
              trans_type: voidTransactionReport ? 'CT' : '',
              trans_no: voidTransactionReport ? rentalId : ''
            }
          })
          .then(res => {
            if (res.data.success) {
              setData(res.data.data);
              setLoading(false);
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchData(loading);
    }, 400);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [rentalId, firstId]);

  return (
    <>
      {loading && data ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for details" />
        </LoadingScreenCover>
      ) : (
        <RentalContractTabView
          rentalContractData={data}
          loading={loading}
          fetchData={fetchData}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          offCanvas={offCanvas}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      )}
    </>
  );
}

RentalContractInfo.propTypes = {
  rentalId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default RentalContractInfo;

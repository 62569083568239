import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import SelectCostCenter from 'components/form/SelectCostCenter';
import {
  purchaseDebitNoteFormKeys,
  purchaseInvoiceTableFormKeys
} from 'helpers/formKeys';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Alert, Card, Col, Form, Row } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import { AiFillFileAdd } from 'react-icons/ai';
import {
  Navigate,
  useNavigate,
  useOutletContext,
  useSearchParams
} from 'react-router-dom';
import Total from 'module/Common/Total';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import axios from 'axios';
import SelectSupplier from 'components/form/SelectSupplier';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import removeRefData from 'helpers/removeRefData';
import SelectPaymentTerms from 'components/form/SelectPaymentTerms';
import { apiCall } from 'helpers/apiCalls';
import { FiChevronsRight } from 'react-icons/fi';
import FileController from 'components/file-controller/FileController';
import PhoneNumberInput from 'components/form/PhoneNumberInput';
import ErrorAlert from 'module/Common/Error/ErrorAlert';
import FormErrorPopover from 'components/form-error-popover/FormErrorPopover';
import setNewFormData from 'helpers/setNewFormData';
import PurchaseEntryTable from 'module/Purchase/common/components/PurchaseEntryTable';
import { useDraft } from 'module/Purchase/common/hooks/purchaseHooks';
import PurchaseFormSaveButton from 'module/Purchase/common/components/PurchaseFormSaveButton';
import PurchaseFormCancelButton from 'module/Purchase/common/components/PurchaseFormCancelButton';
import { showToast } from 'module/Common/Toast/toast';
import SetLineItemsWithItemsIncludedInTheKit from 'module/Purchase/common/helpers/SetLineItemsWithItemsIncludedInTheKit';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import SelectWarehouse from 'components/form/SelectWarehouse';
import { AuthWizardContext } from 'context/Context';
import ReferencePreview from 'module/Purchase/common/components/ReferencePreview/ReferencePreview';
import setPurchaseDetailsItemsTaxGroupData from 'module/Purchase/common/helpers/setPurchaseDetailsItemsTaxGroupData';
import CustomFieldsSection from 'components/form/custom-fields-section/CustomFieldsSection';
import setCustomFields from 'helpers/setCustomFields';
import handlePurchaseModuleFormDynamicValues from 'module/Purchase/common/helpers/handlePurchaseModuleFormDynamicValues';

const DebitNoteForm = () => {
  const { user } = useContext(AuthWizardContext);
  const userBranchData = user?.branch;
  const enableTaxGroup =
    user?.branch?.purchase_settings?.enable_line_item_tax_group === '1'
      ? true
      : false;

  if (userBranchData?.inventory_settings?.default_warehouse_id) {
    purchaseDebitNoteFormKeys.warehouse_id =
      userBranchData.inventory_settings.default_warehouse_id;
    purchaseDebitNoteFormKeys.warehouse_id_ref = {
      label: userBranchData.inventory_settings?.default_warehouse_name,
      value: userBranchData.inventory_settings.default_warehouse_id
    };
  }

  const navigate = useNavigate();
  const Translate = useAxisproTranslate();
  const [queryParams] = useSearchParams();
  const [permissions] = useOutletContext();
  const [formError, setFormError] = useState({});
  const [customFieldItems, setCustomFieldItems] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(null);
  const [onSave, setOnSave] = useState(false);
  const [entryTableShowLoading, setEntryTableShowLoading] = useState(false);
  const invoiceId = queryParams.get('invoice_id');
  const { formData, setFormData, netAmounts } = useDraft({
    trans_type: 'DN',
    defaultFormData: purchaseDebitNoteFormKeys,
    withoutItemsArray: ['person_type']
  });

  const handleFieldChange = async (e, action) => {
    let newFormData = generateFormDataHelper(e, action, formData);
    let keyName = !action ? e.target.name : action?.name;

    await handlePurchaseModuleFormDynamicValues(
      e,
      action,
      newFormData,
      user,
      [
        'applyTransdateAndTaxIncludedChanges',
        'setSuppliersDefaultPaymentTermAsDefaultPaymentTermValue',
        'setDueDateRelatedToPaymentTermDays'
      ],
      'DebitNote'
    );

    setFormData(newFormData);
    if (enableTaxGroup && keyName === 'person_id') {
      setEntryTableShowLoading(true);
      setPurchaseDetailsItemsTaxGroupData({
        newFormData: newFormData,
        formData: formData,
        setFormData: setFormData,
        setEntryTableShowLoading: setEntryTableShowLoading,
        Translate: Translate,
        supplierKeyName: 'person_id'
      });
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = {
      ...removeRefData(formData, 'object', {
        removeValue: [null, 'null']
      }),
      details: removeRefData(
        SetLineItemsWithItemsIncludedInTheKit(formData.details),
        'array'
      )
    };

    let newFormData = setNewFormData(formDataWithoutRefkeys);

    axios({
      method: 'post',
      url: 'finance/debit-notes',
      data: newFormData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');

          navigate(
            `/purchase/debit-note?entry=${response.data.data.id}&layout=column`,
            {
              replace: true
            }
          );
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      })
      .catch(error => {
        if (error.response.data && error.response.data.message) {
          showToast(error.response.data.message, 'error');

          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            Translate(
              'Something went wrong, please refresh the page and try again.'
            ),
            'error'
          );
        }
        setOnSave(false);
      });
  };

  useLayoutEffect(() => {
    const featchDetails = async () => {
      setLoading(true);

      try {
        //set custom fileds
        await setCustomFields({
          formSlug: 'dn-form',
          setCustomFieldItems: setCustomFieldItems
        });

        // set conversion data
        if (invoiceId) {
          setLoadingText('Looking for purchase invoice details');
          await apiCall({
            url: `purchase/purchase-invoice/${invoiceId}`
          }).then(data => {
            setFormData(old => ({
              ...old,
              invoice_id: invoiceId,
              reference_ref: data.reference,
              person_id: data.supplier_id,
              person_name_ref: data.supplier_name,
              payment_term_id: data.payment_term_id,
              payment_term_name_ref: data.payment_term,
              cost_center_id: data.cost_center_id,
              cost_center_name_ref: data.cost_center_name,
              ...(data?.warehouse_id?.length > 0
                ? {
                    warehouse_id: data.warehouse_id,
                    warehouse_name_ref: data.warehouse_name
                  }
                : null),
              trans_date: data.trans_date ?? '',
              due_date: data.due_date ?? '',
              memo: data.memo ?? '',
              memo_name_ref: data.memo ?? '',
              mobile: data.phone ?? '',
              mobile_ref: data.phone ?? '',
              email: data.email ?? '',
              email_ref: data.email ?? '',
              tax_included: data.tax_included ?? 0,
              details: Array.isArray(data.details)
                ? data.details.map((item, index) => {
                    let itemDetails = {
                      ...item,
                      batch_number_ref: item?.batch_number_display
                        ? {
                            label: item.batch_number_display,
                            value: item.batch_number
                          }
                        : null,
                      trans_date: data?.trans_date,
                      id_ref: index + 1,
                      invoice_detail_id: item?.id
                    };

                    if (item?.batch_number?.length > 0) {
                      itemDetails.has_batch_inventory = 1;
                    }

                    itemDetails.quantity = parseFloat(item.qty_sent);
                    itemDetails.quantity_ref = parseFloat(item.qty_sent);
                    itemDetails.qty_debited = parseFloat(item.qty_debited);

                    if (
                      itemDetails.qty_debited > 0 &&
                      itemDetails.quantity > 0
                    ) {
                      itemDetails.quantity -= itemDetails.qty_debited;
                      itemDetails.quantity_ref = itemDetails.quantity;
                    }

                    if (item?.tax_group_id) {
                      itemDetails.tax_group_id_ref = {
                        label: item?.tax_group_name,
                        value: item?.tax_group_id
                      };
                    }

                    itemDetails.add_to_cart_ref = true;
                    itemDetails.edit = true;
                    return itemDetails;
                  })
                : old.details
            }));
          });
        }

        setLoadingText(null);
        setLoading(false);
      } catch (error) {
        console.error(error);
        showToast('Something went wrong, please refresh and try again');
      }
    };
    featchDetails();
  }, [invoiceId]);

  return !permissions?.create_dn ? (
    <Navigate to="/dashboard" />
  ) : (
    <Card style={{ height: '92vh' }}>
      <Card.Header className="d-flex flex-row justify-content-between align-items-center">
        <h5
          className="fs-1 mb-0 d-flex align-items-center text-uppercase"
          style={{ fontSize: '18px' }}
        >
          <AiFillFileAdd size={25} className="text-success me-1" />
          {invoiceId ? (
            <>
              {Translate('Purchase Invoice')}
              <FiChevronsRight color="red" className="mx-2" />{' '}
              {Translate('Debit Note')}
            </>
          ) : (
            Translate('Add Debit Note')
          )}
        </h5>
        {invoiceId && formData.reference_ref ? (
          <ReferencePreview
            reference={formData.reference_ref}
            url={`purchase/purchase-invoice/${invoiceId}`}
            type="PI"
          />
        ) : null}
      </Card.Header>
      <Form onSubmit={handleSubmit}>
        <>
          {!loading ? (
            <>
              <SimpleBar
                style={{
                  height: '79vh',
                  overflowX: 'hidden',
                  overflowY: 'auto'
                }}
              >
                <Card.Body className="pt-3">
                  {Object.keys(formError).length > 0 && (
                    <ErrorAlert
                      formError={formError}
                      setFormError={setFormError}
                    />
                  )}
                  <Row>
                    <Col md={4}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="person_id"
                      >
                        <Form.Label
                          column
                          xl={4}
                          md={4}
                          sm={12}
                          className="require-data"
                        >
                          {Translate('Supplier Name')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            {invoiceId ? (
                              <Form.Control
                                readOnly
                                value={formData.person_name_ref}
                              />
                            ) : (
                              <SelectSupplier
                                value={formData.person_id_ref}
                                name="person_id"
                                handleFieldChange={handleFieldChange}
                                placeholder={Translate('Search...')}
                                style={{ borderRadius: 'none' }}
                                includeDetailsKey
                                withAddButton
                              />
                            )}
                          </div>

                          <FormErrorPopover
                            id="person_id"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.person_id}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="payment_term_id"
                      >
                        <Form.Label
                          column
                          xl={4}
                          md={4}
                          sm={12}
                          className="require-data"
                        >
                          {Translate('Payment Term')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            {invoiceId && formData.payment_term_name_ref ? (
                              <Form.Control
                                readOnly
                                value={formData.payment_term_name_ref}
                              />
                            ) : (
                              <SelectPaymentTerms
                                value={formData.payment_term_id_ref}
                                name="payment_term_id"
                                handleFieldChange={handleFieldChange}
                                placeholder={Translate('Search...')}
                                style={{ borderRadius: 'none' }}
                                includeThisKeys={['days']}
                                withAddButton
                              />
                            )}
                          </div>

                          <FormErrorPopover
                            id="payment_term_id"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.payment_term_id}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="cost_center_id"
                      >
                        <Form.Label column xl={4} md={4} sm={12}>
                          {Translate('Cost Center')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            {invoiceId && formData.cost_center_name_ref ? (
                              <Form.Control
                                readOnly
                                value={formData.cost_center_name_ref}
                              />
                            ) : (
                              <SelectCostCenter
                                value={formData.cost_center_id_ref}
                                name="cost_center_id"
                                handleFieldChange={handleFieldChange}
                                placeholder={Translate('Search...')}
                                style={{ borderRadius: 'none' }}
                                withAddButton
                              />
                            )}
                          </div>

                          <FormErrorPopover
                            id="cost_center_id"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.cost_center_id}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="warehouse_id"
                      >
                        <Form.Label
                          column
                          xl={4}
                          md={4}
                          sm={12}
                          className="require-data"
                        >
                          {Translate('Warehouse Name')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            {invoiceId && formData.warehouse_name_ref ? (
                              <Form.Control
                                readOnly
                                value={formData.warehouse_name_ref}
                              />
                            ) : (
                              <SelectWarehouse
                                value={formData.warehouse_id_ref}
                                name="warehouse_id"
                                handleFieldChange={handleFieldChange}
                                placeholder={Translate('Search...')}
                                style={{ borderRadius: 'none' }}
                                withAddButton
                              />
                            )}
                          </div>

                          <FormErrorPopover
                            id="warehouse_id"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.warehouse_id}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="trans_date"
                      >
                        <Form.Label
                          column
                          xl={4}
                          md={4}
                          sm={12}
                          className="require-data"
                        >
                          {Translate('Date')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            <AppDatePicker
                              name="trans_date"
                              value={formData.trans_date}
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              onChange={handleFieldChange}
                              showDefaultDate
                            />
                          </div>

                          <FormErrorPopover
                            id="trans_date"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.trans_date}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="due_date"
                      >
                        <Form.Label
                          column
                          xl={4}
                          md={4}
                          sm={12}
                          className="require-data"
                        >
                          {Translate('Due Date')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            <AppDatePicker
                              name="due_date"
                              value={formData.due_date}
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              onChange={handleFieldChange}
                            />
                          </div>

                          <FormErrorPopover
                            id="due_date"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.due_date}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group as={Row} className="mb-3 " controlId="mobile">
                        <Form.Label
                          column
                          xl={4}
                          md={4}
                          sm={12}
                          className="require-data"
                        >
                          {Translate('Contact Number')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center "
                        >
                          <div className="flex-fill">
                            <PhoneNumberInput
                              name="mobile"
                              onChange={handleFieldChange}
                              value={formData.mobile}
                              readOnly={
                                invoiceId && formData?.mobile_ref?.length > 0
                              }
                            />
                          </div>

                          <FormErrorPopover
                            id="mobile"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.mobile}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group as={Row} className="mb-3" controlId="email">
                        <Form.Label
                          column
                          xl={4}
                          md={4}
                          sm={12}
                          className="require-data"
                        >
                          {Translate('Contact Email')}
                        </Form.Label>
                        <Col
                          xl={8}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            <Form.Control
                              type="email"
                              name="email"
                              onChange={handleFieldChange}
                              value={formData.email}
                              readOnly={
                                invoiceId && formData?.email_ref?.length > 0
                              }
                            />
                          </div>

                          <FormErrorPopover
                            id="email"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.email}
                            addSpace
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col md={4}>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="tax_included"
                      >
                        <Form.Label column xl={4} md={4} sm={12}>
                          {Translate('Tax Included')}
                        </Form.Label>
                        <Col
                          lg={5}
                          md={8}
                          sm={12}
                          className="d-flex flex-row align-items-center"
                        >
                          <div className="flex-fill">
                            <Form.Check
                              type="switch"
                              checked={formData.tax_included}
                              name="tax_included"
                              onChange={handleFieldChange}
                            />
                          </div>
                          <FormErrorPopover
                            id="tax_included"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.tax_included}
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <CustomFieldsSection
                      customFieldItems={customFieldItems}
                      layout="grid"
                      formData={formData}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                    />
                  </Row>

                  {!formData?.details?.length ? (
                    <Alert
                      className="text-center mt-4 mb-2 rounded-0 p-2 fs--1"
                      variant="warning"
                    >
                      {Translate('Entries are required!')}
                    </Alert>
                  ) : null}

                  <PurchaseEntryTable
                    transaction_date={formData.trans_date}
                    entries={formData.details}
                    setFormData={setFormData}
                    conversion={invoiceId ? true : false}
                    taxIncluded={formData.tax_included ? true : false}
                    defaultKeys={purchaseInvoiceTableFormKeys}
                    totalAmount={netAmounts.ItemTotal}
                    transType="DN"
                    errors={formError}
                    disableEntriesRequiredMessage
                    enableBatchSection={
                      !!+user?.branch?.inventory_settings?.enable_batch
                    }
                    batchIsEditable={!!invoiceId}
                    enableTaxGroup={enableTaxGroup}
                    supplierDetails={formData?.supplierDetails_ref}
                    showLoading={entryTableShowLoading}
                  />

                  <Row>
                    <Col md={{ span: 6, order: 2 }}>
                      <Total
                        grossTotal={netAmounts.GrossTotal}
                        subTotal={netAmounts.ItemTotal}
                        netDiscount={netAmounts.discount}
                        tax={netAmounts.tax}
                      />
                    </Col>
                    <Col md={{ span: 6, order: 1 }}>
                      <FileController
                        limit={10}
                        limitFeature={true}
                        limitFileSizeInMB={5}
                        limitFileType={[
                          'jpg',
                          'png',
                          'jpeg',
                          'gif',
                          'pdf',
                          'excel',
                          'xlsx',
                          'docx',
                          'doc'
                        ]}
                        supportHandleFieldChange
                        onChange={handleFieldChange}
                        className="my-3"
                        note={Translate(
                          'You can upload a maximum of 10 files, 5MB each'
                        )}
                      />
                    </Col>
                  </Row>

                  <Form.Group className="mb-3" controlId="memo">
                    <Form.Label>{Translate('Notes')}</Form.Label>
                    <div className="d-flex flex-row align-items-center">
                      <div className="flex-fill">
                        <Form.Control
                          type="text"
                          as="textarea"
                          name="memo"
                          rows={5}
                          onChange={handleFieldChange}
                          value={formData.memo}
                          readOnly={invoiceId && formData.memo_name_ref}
                        />
                      </div>
                      <FormErrorPopover
                        id="memo"
                        totalErrorCount={Object.keys(formError).length}
                        errorMessage={formError.memo}
                      />
                    </div>
                  </Form.Group>
                </Card.Body>
              </SimpleBar>
              <Card.Footer className="footer d-flex flex-row gap-2 justify-content-start">
                <PurchaseFormSaveButton onSave={onSave} />
                <PurchaseFormCancelButton onSave={onSave} variant="danger" />
              </Card.Footer>
            </>
          ) : (
            <Card.Body
              style={{ height: '75vh' }}
              className="d-flex align-items-center justify-content-center"
            >
              <LoadingScreen message={loadingText} />
            </Card.Body>
          )}
        </>
      </Form>
    </Card>
  );
};

export default DebitNoteForm;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Spinner, Table } from 'react-bootstrap';
import axios from 'axios';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { FaCalendarAlt } from 'react-icons/fa';
import EllipsisOverlayText from 'components/ellipsis-overlay-text/EllipsisOverlayText';
import ClickReference from 'module/Common/ClickableData/ClickReference';
import VoucherDetail from 'components/voucher-details/VoucherDetail';

export default function AdditionalCostTab({
  activeTabKey,
  tabApiCallCompleted,
  setTabApiCallCompleted,
  moduleID
}) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [voucherId, setVoucherId] = useState();
  const [showVoucherDetailArea, setShowVoucherDetailArea] = useState(false);
  function fetchData() {
    setIsLoading(true);
    //pagination needed in backend
    axios
      .get(`purchase/purchase-invoice-additional-costs/${moduleID}`)
      .then(response => {
        setIsLoading(false);
        console.log(response.data.data);
        setData(response.data.data);
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage(
          error?.response?.data?.message ??
            'Something went wrong, please refresh the page and try again.'
        );
      })
      .finally(() => {
        setIsLoading(false);
        setTabApiCallCompleted(prev => ({
          ...prev,
          additionalCosts: true
        }));
      });
  }

  useEffect(() => {
    if (activeTabKey !== 'additional_costs' || tabApiCallCompleted) return;
    fetchData();
  }, [activeTabKey, tabApiCallCompleted]);
  const clickOnVoucher = voucher_id => {
    setVoucherId(voucher_id);
    setShowVoucherDetailArea(true);
  };
  return (
    <>
      <div className="pt-4 d-flex flex-column flex-fill align-items-between justify-content-between">
        {errorMessage ? (
          <Alert variant="danger">{Translate(errorMessage)}</Alert>
        ) : isLoading ? (
          <Alert className="d-flex flex-row align-items-center gap-2">
            <span>{Translate('Looking for additional costs')}</span>
            <Spinner
              style={{
                width: 15,
                height: 15
              }}
            />
          </Alert>
        ) : (
          <>
            <Table
              responsive
              striped
              bordered
              hover
              className="simple-table-animation"
            >
              <thead>
                <tr className="bg-dark text-light fs--2 text-uppercase">
                  <td>{Translate('Date')}</td>
                  <td>{Translate('Reference')}</td>
                  <td>{Translate('Stock Id')}</td>
                  <td>{Translate('Item Name')}</td>
                  <td>{Translate('Account')}</td>
                  <td>{Translate('Note')}</td>
                  <td className="text-center">{Translate('Amount')}</td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(data) && data.length > 0 ? (
                  data.map((item, index) => (
                    <tr key={index} className="fs--1">
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <FaCalendarAlt size={12} className="text-muted" />
                          <span>{item?.voucher_date}</span>
                        </div>
                      </td>
                      <td className="">
                        <ClickReference
                          permission={true}
                          id={item.voucher_id}
                          reference={item.voucher_reference}
                          clickOnFunction={clickOnVoucher}
                        />
                      </td>
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <span>{item?.stock}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <span>{item?.item_display_name}</span>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex flex-row align-items-center gap-2">
                          <span>
                            {item?.account_code + '-' + item?.account_name}
                          </span>
                        </div>
                      </td>

                      <td>
                        <EllipsisOverlayText
                          text={item.description}
                          limit={30}
                          emptyCaseResult="-"
                        />
                      </td>
                      <td className="text-center">
                        {item?.amount_formated ?? '-'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="p-0" colSpan={7}>
                      <Alert className="m-0 border-0 text-center rounded-0">
                        {Translate('No Results found!')}
                      </Alert>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </>
        )}
        <VoucherDetail
          show={showVoucherDetailArea}
          onHide={() => setShowVoucherDetailArea(false)}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          type={''}
          voucherId={voucherId}
          offCanvas={true}
        />
      </div>
    </>
  );
}

AdditionalCostTab.propTypes = {
  activeTabKey: PropTypes.string,
  tabApiCallCompleted: PropTypes.bool,
  setTabApiCallCompleted: PropTypes.func,
  moduleID: PropTypes.string,
  WorkOrderDetailsArray: PropTypes.array
};

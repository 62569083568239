import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';

const AdvanceTable = ({
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  view,
  to,
  allQueryParams,
  pageNumber,
  threeColumnLayout,
  entry_params,
  vouchers_table
}) => {
  const navigate = useNavigate();

  const handleNavigation = route => {
    navigate(route);
  };

  return (
    <SimpleBarReact>
      <Table
        {...getTableProps(tableProps)}
        hover
        className="advance-table-styles"
      >
        <thead className={headerClassName}>
          <tr>
            {headers.map((column, index) => (
              <th
                key={index}
                {...column.getHeaderProps(
                  column.getSortByToggleProps(column.headerProps)
                )}
              >
                {column.render('Header')}
                {column.canSort ? (
                  column.isSorted ? (
                    column.isSortedDesc ? (
                      <span className="sort desc" />
                    ) : (
                      <span className="sort asc" />
                    )
                  ) : (
                    <span className="sort" />
                  )
                ) : (
                  ''
                )}
              </th>
            ))}
          </tr>
        </thead>
        {page?.length > 0 ? (
          <tbody>
            {page.map((row, i) => {
              prepareRow(row);
              if (vouchers_table) {
                return getAcodaxPermissionSlug(
                  row.original.trans_type,
                  'show'
                ) ? (
                  <tr
                    key={i}
                    className={rowClassName}
                    {...row.getRowProps()}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                          onClick={() =>
                            cell?.column?.Header === ''
                              ? ''
                              : handleNavigation(
                                  threeColumnLayout
                                    ? `${to}${row.original.id}&layout=column${
                                        pageNumber ? `&page=${pageNumber}` : ''
                                      }`
                                    : `${to}${
                                        !entry_params ? row.original.id : ''
                                      }?${createSearchParams({
                                        ...allQueryParams,
                                        ...(entry_params
                                          ? {
                                              entry: row.original.id,
                                              layout: 'column',
                                              ...allQueryParams
                                            }
                                          : {})
                                      })}`
                                )
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                ) : (
                  <tr
                    key={i}
                    className={rowClassName}
                    {...row.getRowProps()}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              } else {
                return view ? (
                  <tr
                    key={i}
                    className={rowClassName}
                    {...row.getRowProps()}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                          onClick={() =>
                            cell?.column?.Header === ''
                              ? ''
                              : handleNavigation(
                                  threeColumnLayout
                                    ? `${to}${row.original.id}&layout=column${
                                        pageNumber ? `&page=${pageNumber}` : ''
                                      }`
                                    : `${to}${
                                        !entry_params ? row.original.id : ''
                                      }?${createSearchParams({
                                        ...allQueryParams,
                                        ...(entry_params
                                          ? {
                                              entry: row.original.id,
                                              layout: 'column'
                                            }
                                          : {})
                                      })}`
                                )
                          }
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                ) : (
                  <tr
                    key={i}
                    className={rowClassName}
                    {...row.getRowProps()}
                    style={{ cursor: 'pointer' }}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          key={index}
                          {...cell.getCellProps(cell.column.cellProps)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              }
            })}
          </tbody>
        ) : (
          <tbody>
            <td
              style={{ backgroundColor: '#e307272e' }}
              className="text-center  p-2 fs--1 "
              rowSpan={12}
              colSpan={12}
            >
              No Data Available Let's Add It 🙂{' '}
            </td>
          </tbody>
        )}
      </Table>
    </SimpleBarReact>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  allQueryParams: PropTypes.any,
  view: PropTypes.bool,
  to: PropTypes.any,
  pageNumber: PropTypes.any,
  threeColumnLayout: PropTypes.bool,
  entry_params: PropTypes.bool,
  vouchers_table: PropTypes.bool
};

export default AdvanceTable;

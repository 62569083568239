import { useContext } from 'react';
import { AuthWizardContext } from 'context/Context';

function useAxisPropModulePermission() {
  const { user } = useContext(AuthWizardContext);
  const useModulePermission = slug => {
    if (
      user &&
      user.assigned_permissions &&
      user.assigned_permissions.some(module => module.module_slug == slug)
    )
      return true;
    else return false;
  };
  return useModulePermission;
}

export default useAxisPropModulePermission;

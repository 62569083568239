import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {
  Button,
  Card,
  Col,
  Row,
  Table,
  ProgressBar,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FcNoIdea } from 'react-icons/fc';

import useAxisProPermission from 'hooks/useAxisproPermission';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import ItemDetails from 'components/item-details/ItemDetails';
import Loader from '../Common/Loader';
import { bestSellingProducts } from '../Common/LoaderData';

const BestSellingProduct = ({ product, isLast }) => {
  const { image, item_name, id, type, percentage } = product;
  const [value, setValue] = useState({ value: 0 });
  const [showItemDetailsArea, setShowItemDetailsArea] = useState(false);
  const axisProPermission = useAxisProPermission();

  const handleItemDetailsArea = () => {
    setShowItemDetailsArea(!showItemDetailsArea);
    setValue({ value: 0 });
  };

  const clickOnItem = id => {
    setValue({ value: id });
    {
      axisProPermission('show-item') ? setShowItemDetailsArea(true) : '';
    }
  };

  return (
    <>
      <tr className={classNames({ 'border-bottom border-200': !isLast })}>
        <td>
          <Flex alignItems="center" className="position-relative">
            <img
              className="rounded-1 border border-200"
              src={image}
              width="60"
              alt={item_name}
            />
            <div className="ms-3">
              <h6 className="mb-1 fw-semi-bold">
                <OverlayTrigger
                  placement={'bottom'}
                  overlay={
                    <Tooltip>
                      <strong>{item_name}</strong>
                    </Tooltip>
                  }
                >
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => clickOnItem(id)}
                  >
                    {item_name.length > 12
                      ? item_name.substring(0, 12) + '...'
                      : item_name}
                  </a>
                </OverlayTrigger>
              </h6>
              <p className="fw-semi-bold mb-0 text-500">{type}</p>
            </div>
          </Flex>
        </td>
        <td></td>
        <td className="align-middle pe-card">
          <Flex alignItems="center">
            <ProgressBar
              now={percentage}
              style={{ width: '80px', height: 5 }}
            />
            <div className="fw-semi-bold ms-3">{percentage + '%'}</div>
          </Flex>
        </td>
      </tr>

      <ItemDetails
        show={showItemDetailsArea}
        onHide={handleItemDetailsArea}
        value={value}
        showItemDetailsArea={showItemDetailsArea}
      />
    </>
  );
};

BestSellingProduct.propTypes = {
  totalPrice: PropTypes.number,
  isLast: PropTypes.bool,
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    image: PropTypes.string,
    item_name: PropTypes.string,
    type: PropTypes.string,
    percentage: PropTypes.any
  }).isRequired
};

const BestSellingProducts = ({ sales, draggable }) => {
  const Translate = useAxisproTranslate();
  const axisProPermission = useAxisProPermission();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);

  const noOfProducts = data.length;

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get('sales/get-top-selling-items')
      .then(res => {
        if (res.data.success === true) {
          if (res.data.data.length > 0) {
            const sum = res.data.data.reduce(
              (acc, obj) => acc + obj.qty_sold,
              0
            );
            const arrayWithPercentages = res.data.data.map(obj => {
              const percentage = ((obj.qty_sold / sum) * 100).toFixed(1);
              return { ...obj, percentage };
            });
            setData(arrayWithPercentages);
          }

          setIsLoading(false);
        }
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
      {!isLoading ? (
        <>
          {data && data.length > 0 ? (
            <>
              <Card.Body
                className="p-0"
                style={{
                  height: sales ? '3rem' : '',
                  overflow: 'hidden',
                  overflowY: 'scroll'
                }}
              >
                <Table borderless responsive className="mb-0 fs--1">
                  <thead className="bg-light">
                    <tr className="text-900">
                      <th>{Translate('Best Selling Products')}</th>
                      <th></th>
                      <th
                        className="pe-card text-end"
                        style={{ width: '8rem' }}
                      >
                        {Translate('Quantity sold')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((product, index) => (
                      <BestSellingProduct
                        product={product}
                        isLast={index === noOfProducts - 1}
                        key={index}
                      />
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              {!sales && axisProPermission('show-item') && (
                <Card.Footer className="bg-light py-2">
                  <Row className="flex-between-center">
                    <Col xs="auto"></Col>
                    <Col xs="auto">
                      <Button
                        variant="falcon-default"
                        size="sm"
                        as={Link}
                        to="/inventory/items"
                      >
                        {Translate('View All')}
                      </Button>
                    </Col>
                  </Row>
                </Card.Footer>
              )}
            </>
          ) : (
            <Card.Body className="d-flex flex-column justify-content-center align-items-center">
              <FcNoIdea size={30} />
              {Translate('No Data Available')}
            </Card.Body>
          )}
        </>
      ) : (
        <Loader loader={bestSellingProducts} className={'ps-4'} />
      )}
    </Card>
  );
};

BestSellingProducts.propTypes = {
  sales: PropTypes.bool,
  className: PropTypes.string,
  draggable: PropTypes.bool
};

export default BestSellingProducts;

import { React } from 'react';
import {
  Card,
  Modal,
  Button,
  Form,
  Alert,
  Collapse,
  Spinner
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useState } from 'react';
import { FaUserLock } from 'react-icons/fa';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import PasswordPolicyValidationInput from 'module/Common/PasswordPolicy/PasswordPolicyValidationInput';

const ResetPasswordModal = () => {
  const Translate = useAxisproTranslate();
  const [isSaving, setSaving] = useState(false);
  const [queryParams] = useSearchParams();
  const [buttonDisable, setButtonDisable] = useState(false);
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const show = queryParams.get('reset_password');
  const [formError, setFormError] = useState({});
  const [formData, setFormData] = useState({
    old_password: '',
    new_password: '',
    c_password: ''
  });

  const handleChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    setFormError({});
    setSaving(true);
    if (formData.new_password === formData.c_password) {
      setShowAlert(false);
      axios({
        method: 'post',
        url: '/change-password',
        data: formData
      })
        .then(response => {
          setSaving(false);
          if (response.data.success === true) {
            showToast(response.data.message, 'success');
            navigate(-1);
            setFormData({});
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
        })
        .catch(error => {
          setSaving(false);
          if (error.response.data && error.response.data.message) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.errors
                ? error.response.data.data.errors
                : null;
            validation_error && setFormError({ ...validation_error });
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              'error'
            );
          }
        });
    } else {
      setSaving(false);
      setShowAlert(true);
    }
  };
  return (
    <Modal
      show={show}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
      size=""
      onHide={() => {
        navigate(-1);
      }}
    >
      <Modal.Header className="m-0 p-0 pe-4" closeButton>
        <FaUserLock size={18} className="me-1 ms-3" />{' '}
        <FalconCardHeader title="Change Password" />
      </Modal.Header>
      <Card className="m-0 p-0">
        <Card.Body className="bg-light">
          {showAlert && (
            <Alert variant="warning">
              {Translate(`New Password And Confirm Password Doesn't Match`)}
            </Alert>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="oldPassword">
              <Form.Label>{Translate('Old Password')}</Form.Label>
              <Form.Control
                type="password"
                value={formData.old_password}
                name="old_password"
                onChange={handleChange}
                isInvalid={!!formError.old_password}
              />
              <Form.Control.Feedback type="invalid">
                {formError.old_password}{' '}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="newPassword">
              <Form.Label>{Translate('New Password')}</Form.Label>
              <PasswordPolicyValidationInput
                name="new_password"
                handleFieldChange={handleChange}
                formData={formData}
                formError={formError}
                handleError={value => setButtonDisable(value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="confirmPassword">
              <Form.Label>{Translate('Confirm Password')}</Form.Label>
              <Form.Control
                type="password"
                value={formData.c_password}
                name="c_password"
                onChange={handleChange}
                isInvalid={!!formError.c_password}
              />
              {formData?.c_password ? (
                <Collapse
                  in={
                    !!(
                      formError?.new_password ||
                      (formData?.c_password &&
                        formData?.new_password &&
                        formData?.new_password !== formData?.c_password)
                    )
                  }
                >
                  <p className="text-danger mb-0 fs--2">
                    {Translate('Password and Confirm Password must be same!')}
                  </p>
                </Collapse>
              ) : (
                <Form.Control.Feedback type="invalid">
                  {formError.c_password}{' '}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Button
              className="w-100"
              type="submit"
              disabled={
                buttonDisable ||
                formData?.new_password !== formData?.c_password ||
                isSaving
              }
            >
              {isSaving ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                Translate('Update Password')
              )}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </Modal>
  );
};
ResetPasswordModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func
};
export default ResetPasswordModal;

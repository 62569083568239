import { Fragment, React, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Container, Form, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { BiPurchaseTag } from 'react-icons/bi';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import { FaEdit } from 'react-icons/fa';

import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SerachSelectSalesFields from '../Common/SerachSelectSalesFields';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';
function PurchaseSettings({ Offcanvas, settings_type }) {
  const Translate = useAxisproTranslate();
  const [loading, setLoading] = useState(true);
  const [purchaseData, setPurchaseData] = useState({});
  const [extraData, setExtraData] = useState({});
  const [formData, setFormData] = useState({});
  const [editField, setEditField] = useState({});
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [prePurchaseData, setPrePurchaseData] = useState({});
  const [editItems, setEditItems] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const handleShow = () => setConfirmShow(!confirmShow);

  const fetchData = () => {
    axios
      .get('settings/system/system-settings')
      .then(res => {
        if (res.data.success === true) {
          const purchaseSettings = res?.data?.data?.purchase_settings;
          setPurchaseData({
            delivery_print_template:
              purchaseSettings?.delivery_print_template ?? 'print_1',
            delivery_screen_detail_hidden_fields:
              purchaseSettings?.delivery_screen_detail_hidden_fields ?? '',
            delivery_screen_main_hidden_fields:
              purchaseSettings?.delivery_screen_main_hidden_fields ?? '',
            invoice_print_template:
              purchaseSettings?.invoice_print_template ?? 'print_1',
            invoice_screen_detail_hidden_fields:
              purchaseSettings?.invoice_screen_detail_hidden_fields ?? '',
            invoice_screen_main_hidden_fields:
              purchaseSettings?.invoice_screen_main_hidden_fields ?? '',
            is_enable_auto_pd_from_pi:
              purchaseSettings?.is_enable_auto_pd_from_pi ?? '',
            order_print_template:
              purchaseSettings?.order_print_template ?? 'print_1',
            order_screen_detail_hidden_fields:
              purchaseSettings?.order_screen_detail_hidden_fields ?? '',
            order_screen_main_hidden_fields:
              purchaseSettings?.order_screen_main_hidden_fields ?? '',
            enable_line_item_tax_group:
              purchaseSettings?.enable_line_item_tax_group ?? '',
            is_item_description_in_pr_required:
              purchaseSettings?.is_item_description_in_pr_required ?? '',
            enable_pi_additional_cost:
              purchaseSettings?.enable_pi_additional_cost ?? '',
            is_item_description_in_po_required:
              purchaseSettings?.is_item_description_in_po_required ?? '',
            is_item_description_in_pd_required:
              purchaseSettings?.is_item_description_in_pd_required ?? '',
            is_item_description_in_pi_required:
              purchaseSettings?.is_item_description_in_pi_required ?? '',
            is_item_description_in_dn_required:
              purchaseSettings?.is_item_description_in_dn_required ?? '',
            payment_term: purchaseSettings?.payment_term_id ?? '',
            payment_term_ref: {
              label: purchaseSettings?.payment_term_name ?? '',
              value: purchaseSettings?.payment_term_id ?? ''
            },
            payment_term_name: purchaseSettings?.payment_term_name ?? '-',
            default_terms_and_conditions_id:
              purchaseSettings?.default_terms_and_conditions_id ?? '-',
            default_terms_and_conditions_name:
              purchaseSettings?.default_terms_and_conditions_name ?? '-',
            default_terms_and_conditions_ref: {
              label: purchaseSettings?.default_terms_and_conditions_name ?? '-',
              value: purchaseSettings.default_terms_and_conditions_id ?? '-'
            }
          });
          setEditItems(false);
          setPrePurchaseData({
            delivery_print_template:
              purchaseSettings?.delivery_print_template ?? 'print_1',
            delivery_screen_detail_hidden_fields:
              purchaseSettings?.delivery_screen_detail_hidden_fields ?? '',
            delivery_screen_main_hidden_fields:
              purchaseSettings?.delivery_screen_main_hidden_fields ?? '',
            invoice_print_template:
              purchaseSettings?.invoice_print_template ?? 'print_1',
            invoice_screen_detail_hidden_fields:
              purchaseSettings?.invoice_screen_detail_hidden_fields ?? '',
            invoice_screen_main_hidden_fields:
              purchaseSettings?.invoice_screen_main_hidden_fields ?? '',
            is_enable_auto_pd_from_pi:
              purchaseSettings?.is_enable_auto_pd_from_pi ?? '',
            order_print_template:
              purchaseSettings?.order_print_template ?? 'print_1',
            order_screen_detail_hidden_fields:
              purchaseSettings?.order_screen_detail_hidden_fields ?? '',
            order_screen_main_hidden_fields:
              purchaseSettings?.order_screen_main_hidden_fields ?? '',
            enable_line_item_tax_group:
              purchaseSettings?.enable_line_item_tax_group ?? '',
            is_item_description_in_pr_required:
              purchaseSettings?.is_item_description_in_pr_required ?? '',
            is_item_description_in_po_required:
              purchaseSettings?.is_item_description_in_po_required ?? '',
            enable_pi_additional_cost:
              purchaseSettings?.enable_pi_additional_cost ?? '',
            is_item_description_in_pd_required:
              purchaseSettings?.is_item_description_in_pd_required ?? '',
            is_item_description_in_pi_required:
              purchaseSettings?.is_item_description_in_pi_required ?? '',
            is_item_description_in_dn_required:
              purchaseSettings?.is_item_description_in_dn_required ?? '',
            payment_term: purchaseSettings?.payment_term_id ?? '',
            payment_term_ref: {
              label: purchaseSettings?.payment_term_name ?? '',
              value: purchaseSettings?.payment_term_id ?? ''
            },
            payment_term_name: purchaseSettings?.payment_term_name ?? '-',
            default_terms_and_conditions_id:
              purchaseSettings?.default_terms_and_conditions_id ?? '-',
            default_terms_and_conditions_name:
              purchaseSettings?.default_terms_and_conditions_name ?? '-',
            default_terms_and_conditions_ref: {
              label: purchaseSettings?.default_terms_and_conditions_name ?? '-',
              value: purchaseSettings.default_terms_and_conditions_id ?? '-'
            }
          });
          setExtraData(res.data.data.extra_options.purchase);
          setLoading(false);
        }
      })
      .catch(error => console.log(error));
  };

  const createHiddenInputValue = (fieldName, keyName) => {
    if (
      [
        'is_enable_auto_pd_from_pi',
        'enable_line_item_tax_group',
        'is_item_description_in_pr_required',
        'is_item_description_in_po_required',
        'is_item_description_in_pd_required',
        'is_item_description_in_pi_required',
        'is_item_description_in_dn_required',
        'enable_pi_additional_cost'
      ].includes(fieldName)
    ) {
      if (purchaseData[fieldName] === '1') {
        return true;
      } else {
        return false;
      }
    } else {
      let newData = purchaseData[fieldName]
        ? JSON.parse(purchaseData[fieldName])
        : '';
      if (newData.includes(keyName)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleFieldChange = (e, action) => {
    let newPurchaseData;
    let newFormData;
    if (action) {
      newPurchaseData = {
        ...purchaseData,
        [action.name]: e ? e.value : null,
        [action.name + '_ref']: e ? e : null
      };
      newFormData = {
        ...formData,
        [action.name + '_id']: e ? e.value : null,
        [action.name + '_name']: e ? e.label : null
      };
      setEditField({ [action.name]: true });
    } else {
      newPurchaseData = {
        ...purchaseData,
        [e.target.name]: e.target.value
      };
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value
      };
      setEditField({ [e.target.name]: true });
    }
    setPurchaseData(newPurchaseData);
    setFormData(newFormData);
  };

  const PurchaseSettings = [
    {
      heading: 'Supplier Settings',
      checkBox: false,
      subHeading: [
        {
          name: 'Payment Term',
          edit: editField.payment_term ? true : false,
          key_name: 'payment_term',
          display_name: purchaseData?.payment_term_name ?? '-',
          element: (
            <SerachSelectSalesFields
              url="settings/payment-terms-autocomplete"
              value={purchaseData.payment_term_ref}
              label={'terms'}
              name="payment_term"
              handleFieldChange={handleFieldChange}
            />
          )
        }
      ]
    },
    {
      heading: 'General Settings',
      checkBox: false,
      subHeading: [
        {
          name: 'Default Terms And Condition',
          edit: editField.default_terms_and_conditions ? true : false,
          key_name: 'default_terms_and_conditions_id',
          display_name: purchaseData?.default_terms_and_conditions_name ?? '-',
          element: (
            <SerachSelectSalesFields
              url="settings/terms-and-conditions-autocomplete"
              label="name"
              value={purchaseData.default_terms_and_conditions_ref}
              name="default_terms_and_conditions"
              handleFieldChange={handleFieldChange}
            />
          )
        }
      ]
    },
    {
      heading: 'Purchase Request Settings',
      checkBox: true,
      subHeading: [
        {
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_pr_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_pr_required')
                  }
                  name="is_item_description_in_pr_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_pr_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Purchase Order Settings',
      checkBox: true,
      subHeading: [
        {
          name: 'Order screen main details hidden fields',
          field:
            extraData?.order_screen_main_hidden_fields &&
            Object.keys(extraData?.order_screen_main_hidden_fields).map(
              items => ({
                name: extraData.order_screen_main_hidden_fields[items],
                key_name: items,
                display_name: createHiddenInputValue(
                  'order_screen_main_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(e, 'order_screen_main_hidden_fields')
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'order_screen_main_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: 'Order screen Line item hidden fields  ',
          field:
            extraData?.order_screen_detail_hidden_fields &&
            Object.keys(extraData?.order_screen_detail_hidden_fields).map(
              items => ({
                name: extraData.order_screen_detail_hidden_fields[items],
                key_name: items,
                display_name: createHiddenInputValue(
                  'order_screen_detail_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(e, 'order_screen_detail_hidden_fields')
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'order_screen_detail_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_po_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_po_required')
                  }
                  name="is_item_description_in_po_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_po_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Purchase Delivery Settings',
      checkBox: true,
      subHeading: [
        {
          name: 'Delivery screen main details hidden fields',
          field:
            extraData?.delivery_screen_main_hidden_fields &&
            Object.keys(extraData?.delivery_screen_main_hidden_fields).map(
              items => ({
                name: extraData.delivery_screen_main_hidden_fields[items],
                key_name: items,
                display_name: createHiddenInputValue(
                  'delivery_screen_main_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(e, 'delivery_screen_main_hidden_fields')
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'delivery_screen_main_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: 'Delivery screen Line item hidden fields  ',
          field:
            extraData?.delivery_screen_detail_hidden_fields &&
            Object.keys(extraData?.delivery_screen_detail_hidden_fields).map(
              items => ({
                name: extraData.delivery_screen_detail_hidden_fields[items],
                key_name: items,
                display_name: createHiddenInputValue(
                  'delivery_screen_detail_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(
                        e,
                        'delivery_screen_detail_hidden_fields'
                      )
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'delivery_screen_detail_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_pd_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_pd_required')
                  }
                  name="is_item_description_in_pd_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_pd_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Purchase Invoice Settings',
      checkBox: true,
      subHeading: [
        {
          name: 'Invoice screen main details hidden fields',
          field:
            extraData?.invoice_screen_main_hidden_fields &&
            Object.keys(extraData?.invoice_screen_main_hidden_fields).map(
              items => ({
                name: extraData.invoice_screen_main_hidden_fields[items],
                key_name: items,
                display_name: createHiddenInputValue(
                  'invoice_screen_main_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(e, 'invoice_screen_main_hidden_fields')
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'invoice_screen_main_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: 'Invoice screen Line item hidden fields  ',
          field:
            extraData?.invoice_screen_detail_hidden_fields &&
            Object.keys(extraData?.invoice_screen_detail_hidden_fields).map(
              items => ({
                name: extraData.invoice_screen_detail_hidden_fields[items],
                key_name: items,
                display_name: createHiddenInputValue(
                  'invoice_screen_detail_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(
                        e,
                        'invoice_screen_detail_hidden_fields'
                      )
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'invoice_screen_detail_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: '',
          field: [
            {
              name: 'Allow auto purchase delivery',
              key_name: 'is_enable_auto_pd_from_pi',
              display_name: 'Allow auto purchase delivery ',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_enable_auto_pd_from_pi')
                  }
                  name="is_enable_auto_pd_from_pi"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_enable_auto_pd_from_pi',
                    ''
                  )}
                />
              )
            }
          ]
        },
        {
          name: '',
          field: [
            {
              name: 'Enable Line Item Tax Group',
              key_name: 'enable_line_item_tax_group',
              display_name: 'Enable Line Item Tax Group ',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'enable_line_item_tax_group')
                  }
                  name="enable_line_item_tax_group"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'enable_line_item_tax_group',
                    ''
                  )}
                />
              )
            }
          ]
        },
        {
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_pi_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_pi_required')
                  }
                  name="is_item_description_in_pi_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_pi_required',
                    ''
                  )}
                />
              )
            }
          ]
        },
        {
          field: [
            {
              name: 'Enable Additional Cost',
              key_name: 'is_item_description_in_pi_required',
              display_name: 'Enable Additional Cost',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'enable_pi_additional_cost')
                  }
                  name="enable_pi_additional_cost"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'enable_pi_additional_cost',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Debit Note Settings',
      checkBox: true,
      subHeading: [
        {
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_dn_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_dn_required')
                  }
                  name="is_item_description_in_dn_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_dn_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    }
  ];

  const inputSwitchChange = (e, keyName) => {
    let submitData = {};
    if (
      [
        'is_enable_auto_pd_from_pi',
        'enable_line_item_tax_group',
        'is_item_description_in_pr_required',
        'is_item_description_in_po_required',
        'is_item_description_in_pd_required',
        'is_item_description_in_pi_required',
        'is_item_description_in_dn_required',
        'enable_pi_additional_cost'
      ].includes(keyName)
    ) {
      if (purchaseData[keyName] === '1') {
        submitData[keyName] = '0';
      } else {
        submitData[keyName] = '1';
      }
      setPurchaseData(pre => ({
        ...pre,
        [keyName]: submitData[keyName]
      }));
    } else {
      let checkBoxData = purchaseData[keyName]
        ? JSON.parse(purchaseData[keyName])
        : [];
      checkBoxData = checkBoxData.includes(e.target.name)
        ? checkBoxData.filter(item => item !== e.target.name)
        : [...checkBoxData, e.target.name];
      setPurchaseData(pre => ({
        ...pre,
        [keyName]: JSON.stringify(checkBoxData)
      }));
      submitData = {
        [keyName]: JSON.stringify(checkBoxData)
      };
    }
    axios({
      method: 'post',
      url: `settings/purchase`,
      data: submitData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        console.log(error);
        showToast(
          `Something went wrong, please refresh the page and try again.`,
          'error'
        );
      });
  };

  useEffect(() => {
    if (purchaseData) {
      let updatedData = [...PurchaseSettings];
      //offcanvas view time a specific settings filtering function
      if (Offcanvas) {
        let SingleSettings = PurchaseSettings.filter(
          items => items.heading === settings_type
        );
        setPurchaseItems(SingleSettings);
      } else {
        setPurchaseItems(updatedData);
      }
    }
  }, [purchaseData, extraData]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    setConfirmShow(false);
    setFormData({});
    setEditField({});
    setPurchaseData(prev => ({
      ...prev,
      ...formData
    }));
    setPrePurchaseData(prev => ({
      ...prev,
      ...formData
    }));

    setEditItems(false);
    axios({
      method: 'post',
      url: `settings/purchase`,
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
        } else {
          setEditItems(true);
          fetchData();
          setEditField(formData);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        if (error) {
          setEditItems(true);
          fetchData();
          setEditField(formData);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      });
  };

  const EditSettings = (object, secondName) => {
    setEditItems(true);
    setEditField({ [secondName]: true });
    const updatedData = purchaseItems.map(items => {
      if (items.heading === object.heading) {
        return {
          ...items,
          subHeading: items.subHeading.map(second => {
            if (second.name === secondName) {
              return {
                ...second,
                edit: true
              };
            }
            return second;
          })
        };
      }
      return items;
    });
    setPurchaseItems(updatedData);
  };

  const EditSettingsClose = (object, secondName) => {
    setFormData({});
    setEditField({});
    setPurchaseData(prePurchaseData);
    setEditItems(false);
    const updatedData = purchaseItems.map(items => {
      if (items.heading === object.heading) {
        return {
          ...items,
          subHeading: items.subHeading.map(second => {
            if (second.name === secondName) {
              return {
                ...second,
                edit: false
              };
            }
            return second;
          })
        };
      }
      return items;
    });
    setPurchaseItems(updatedData);
  };

  return (
    <Col
      className="p-0"
      xl={Offcanvas ? 12 : 10}
      md={Offcanvas ? 12 : 9}
      sm={Offcanvas ? 12 : 6}
    >
      <Card className="flex-fill">
        {!Offcanvas && (
          <Card.Header className="d-flex align-items-center border-bottom pt-3 pb-1">
            <BiPurchaseTag className="text-dark me-2" size={20} />
            <h5 className="m-0 form-heading  text-uppercase pt-1 pb-2">
              {Translate('Purchase Settings')}
            </h5>
          </Card.Header>
        )}
        <Card.Body className="p-0">
          {loading ? (
            <Container
              className="d-flex flex-fill align-items-center justify-content-center text-center"
              style={{ height: Offcanvas ? '94vh' : '86.2vh' }}
            >
              <LoadingScreen message="Setting up purchase settings" />
            </Container>
          ) : (
            <Container
              fluid
              className="p-0 flex-wrap"
              style={{
                height: Offcanvas ? '94vh' : '86.2vh',
                overflow: 'scroll'
              }}
            >
              <Table
                hover
                responsive="sm"
                className={`system-settings-table  ${
                  Offcanvas ? ' ms-3' : 'border-bottom'
                }`}
              >
                {purchaseItems.length > 0 &&
                  purchaseItems.map((items, index) => {
                    return (
                      <tbody
                        className={`${!Offcanvas && 'border-bottom'}`}
                        key={index}
                      >
                        {!Offcanvas && (
                          <tr className="position-relative border-bottom-zero no-box-shadow">
                            <td
                              className="border-bottom-zero fw-bold text-dark text-center "
                              style={{ width: '200px' }}
                              rowSpan={
                                items.checkBox
                                  ? items.heading?.length + 2
                                  : items.heading?.length
                              }
                            >
                              <div className="position-absolute fw-bold  top-0 start-0 ms-3 mt-1 ">
                                {Translate(items.heading)}
                              </div>
                            </td>
                          </tr>
                        )}
                        {items.subHeading.map((subHeading, key) => {
                          if (!items.checkBox) {
                            return (
                              <tr className="w-100" key={key}>
                                <td
                                  className="border-bottom-zero text-dark"
                                  style={{ width: '300px' }}
                                >
                                  <div className="">
                                    {Translate(subHeading?.name)}
                                  </div>
                                </td>
                                <td
                                  className="border-bottom-zero text-dark"
                                  style={{ width: '5px' }}
                                >
                                  :
                                </td>
                                <td
                                  className="border-bottom-zero text-dark "
                                  style={{ width: '300px' }}
                                >
                                  <div>
                                    {subHeading?.edit
                                      ? subHeading?.element
                                      : subHeading?.display_name}
                                    {!subHeading?.edit && !editItems && (
                                      <Button
                                        size="sm"
                                        variant="transparent"
                                        className="border-0 shadow-none  settings-edit ms-5"
                                      >
                                        <div>
                                          <FaEdit
                                            size={13}
                                            onClick={() => {
                                              EditSettings(
                                                items,
                                                subHeading.name
                                              );
                                            }}
                                          />
                                        </div>
                                      </Button>
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="border-bottom-zero d-flex align-items-center justify-content-start "
                                  style={{
                                    height: subHeading?.edit ? '47px' : '42px'
                                  }}
                                >
                                  {subHeading?.edit && (
                                    <>
                                      <Button
                                        variant="transparent"
                                        size="sm"
                                        className=" p-0 m-0 me-1 border-0 shadow-none"
                                        title={Translate('Save')}
                                        onClick={handleShow}
                                      >
                                        <IoMdCheckmarkCircle
                                          size={20}
                                          className="text-success"
                                        />
                                      </Button>
                                      <Button
                                        variant="transparent"
                                        size="sm"
                                        className="p-0 m-0 border-0 shadow-none"
                                        title={Translate('Close')}
                                        onClick={() => {
                                          EditSettingsClose(
                                            items,
                                            subHeading.name
                                          );
                                        }}
                                      >
                                        <IoMdCloseCircle
                                          size={20}
                                          className="text-danger"
                                        />
                                      </Button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <>
                                <tr className=" fw-medium no-box-shadow">
                                  <div className="pt-2 p-0">
                                    {Translate(subHeading.name)}
                                  </div>
                                </tr>
                                {subHeading?.field?.map(
                                  (newItems, newIndex) => {
                                    return (
                                      <tr className="w-100" key={newIndex}>
                                        <td
                                          className="border-bottom-zero text-dark"
                                          style={{ width: '300px' }}
                                        >
                                          <div>{Translate(newItems?.name)}</div>
                                        </td>
                                        <td
                                          className="border-bottom-zero text-dark"
                                          style={{ width: '5px' }}
                                        ></td>
                                        <td
                                          className="border-bottom-zero text-dark "
                                          style={{ width: '300px' }}
                                        >
                                          {newItems?.edit
                                            ? newItems?.element
                                            : newItems?.element}
                                        </td>
                                        <td
                                          className="border-bottom-zero d-flex align-items-end justify-content-end "
                                          style={{
                                            height: newItems?.edit
                                              ? '47px'
                                              : '37px'
                                          }}
                                        ></td>
                                      </tr>
                                    );
                                  }
                                )}
                              </>
                            );
                          }
                        })}
                      </tbody>
                    );
                  })}
              </Table>
            </Container>
          )}
          <ConfirmWarning
            show={confirmShow}
            onHide={handleShow}
            handleSubmit={handleSubmit}
            content="Are you sure you want to change the purchase settings?"
          />
        </Card.Body>
      </Card>
    </Col>
  );
}
PurchaseSettings.propTypes = {
  Offcanvas: PropTypes.bool,
  settings_type: PropTypes.string
};
export default PurchaseSettings;

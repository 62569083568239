import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import handlePrint from 'helpers/printHelper';

function ViewPagePrintButton({ url, pageTitle, title, ...rest }) {
  return (
    <Button
      {...rest}
      onClick={() => handlePrint(url, pageTitle ?? '')}
      size="sm"
    >
      <FontAwesomeIcon icon="file-pdf" size="sm" /> {title ?? ''}
    </Button>
  );
}

ViewPagePrintButton.propTypes = {
  url: PropTypes.string,
  pageTitle: PropTypes.string,
  title: PropTypes.string,
  iconSize: PropTypes.number
};

export default ViewPagePrintButton;

import Avatar from 'components/common/Avatar';
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function ActivityLogItem({ activity }) {
  return (
    <div className="app-activity-log-item d-flex flex-row align-items-center">
      <div className="app-activity-log-item-icon-area align-self-stretch d-flex align-items-center justify-content-center pe-3">
        <span className="app-activity-log-item-line-span"></span>
        <Avatar
          {...(activity?.causer_image && activity.causer_image?.length > 0
            ? {
                src: activity?.causer_image
              }
            : {
                name:
                  activity?.causer_name && activity.causer_name?.length > 0
                    ? activity.causer_name
                    : 'unknown user'
              })}
          rounded="circle"
          size="m"
        />
      </div>
      <div className="app-activity-log-item-content-area-cover d-flex flex-wrap align-items-center gap-3 flex-fill bg-white rounded p-2 shadow-sm my-3">
        <div className="app-activity-log-item-content-area me-auto">
          <p className="text-dark fw-bold mb-0 text-uppercase">
            {activity?.event ?? 'unknown event'}
          </p>
          <small className="text-muted">{activity?.description}</small>
          <br />
          <>
            {activity?.causer_name && activity.causer_name?.length > 0 ? (
              activity?.causer_id ? (
                <Link
                  to={'/system-admin/users/info/' + activity?.causer_id}
                  className="text-primary"
                >
                  <small>{activity?.causer_name}</small>
                </Link>
              ) : (
                <small className="text-primary">{activity?.causer_name}</small>
              )
            ) : (
              <small className="text-danger">unknown user</small>
            )}
          </>
        </div>
        <div className="app-activity-log-item-time-area">
          <small className="">{activity?.created_at_formatted}</small>
        </div>
      </div>
    </div>
  );
}

ActivityLogItem.propTypes = {
  activity: PropTypes.object
};

import { React, useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';

import LoadingScreenCover from 'components/common/loading-screen/LoadingScreenCover';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import SalesInvoiceTabView from 'module/Common/ViewPages/SalesInvoice/SalesInvoiceTabView';
import { Fragment } from 'react';
function SalesInvoiceInfo({
  salesInvoiceId,
  rendering,
  firstId,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async isLoading => {
    if (!rendering) {
      if (firstId !== undefined) {
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `sales/sales-invoice/${firstId}`;
        setTimeout(
          async () =>
            await axios
              .get(url, {
                params: {
                  trans_type: voidTransactionReport ? 'SI' : '',
                  trans_no: voidTransactionReport ? firstId : ''
                }
              })
              .then(res => {
                setLoading(false);
                if (res.data.success) {
                  setData(res.data.data);
                }
              })
              .catch(error => {
                console.log(error);
              }),
          1000
        );
      }
    } else {
      if (salesInvoiceId !== undefined) {
        if (isLoading) {
          setLoading(true);
        }
        let url = voidTransactionReport
          ? 'settings/void-transaction-details'
          : `sales/sales-invoice/${salesInvoiceId}`;
        await axios
          .get(url, {
            params: {
              trans_type: voidTransactionReport ? 'SI' : '',
              trans_no: voidTransactionReport ? salesInvoiceId : ''
            }
          })
          .then(res => {
            setLoading(false);
            if (res.data.success) {
              setData(res.data.data);
            }
          })
          .catch(error => {
            setLoading(false);
            console.log(error);
          });
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchData(loading);
    }, 400);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [salesInvoiceId, firstId]);

  return (
    <Fragment>
      {loading ? (
        <LoadingScreenCover>
          <LoadingScreen message="Looking for sales invoice info" />
        </LoadingScreenCover>
      ) : (
        <SalesInvoiceTabView
          salesInvoiceData={data}
          loading={loading}
          creditFormatted={
            data && data.customer && data.customer.unused_credits_formatted
              ? data.customer.unused_credits_formatted
              : ''
          }
          credit={
            data && data.customer && data.customer.unused_credits
              ? data.customer.unused_credits
              : ''
          }
          fetchData={fetchData}
          offCanvas={offCanvas}
          setShowVoucherDetailArea={setShowVoucherDetailArea}
          voidTransactionReport={voidTransactionReport}
          getData={getData}
        />
      )}
    </Fragment>
  );
}

SalesInvoiceInfo.propTypes = {
  salesInvoiceId: PropTypes.string,
  firstId: PropTypes.string,
  rendering: PropTypes.bool,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.any,
  getData: PropTypes.func
};

export default SalesInvoiceInfo;

export default function setCategoryFormDefaultData(userData) {
  return {
    sales_account_code: userData?.branch?.finance_settings?.sales_account ?? '',
    sales_account_code_ref: userData?.branch?.finance_settings?.sales_account
      ? {
          label: userData?.branch?.finance_settings?.sales_account_name,
          value: userData?.branch?.finance_settings?.sales_account
        }
      : '',
    inventory_account_code:
      userData?.branch?.finance_settings?.inventory_account ?? '',
    inventory_account_code_ref: userData?.branch?.finance_settings
      ?.inventory_account
      ? {
          label: userData?.branch?.finance_settings?.inventory_account_name,
          value: userData?.branch?.finance_settings?.inventory_account
        }
      : '',
    purchase_account_code:
      userData?.branch?.finance_settings?.cogs_account ?? '',
    purchase_account_code_ref: userData?.branch?.finance_settings?.cogs_account
      ? {
          label: userData?.branch?.finance_settings?.cogs_account_name,
          value: userData?.branch?.finance_settings?.cogs_account
        }
      : '',
    tax_group_id: userData?.branch?.finance_settings?.tax_group_id ?? '',
    tax_group_id_ref: userData?.branch?.finance_settings?.tax_group_id
      ? {
          label: userData?.branch?.finance_settings?.tax_group_name ?? '',
          value: userData?.branch?.finance_settings?.tax_group_id
        }
      : '',
    wip_account_code: userData?.branch?.finance_settings?.wip_account ?? '',
    wip_account_code_ref: userData?.branch?.finance_settings?.wip_account
      ? {
          label: userData?.branch?.finance_settings?.wip_account_name ?? '',
          value: userData?.branch?.finance_settings?.wip_account
        }
      : '',
    wip_crediting_account_code:
      userData?.branch?.finance_settings?.wip_crediting_account ?? '',
    wip_crediting_account_code_ref: userData?.branch?.finance_settings
      ?.wip_crediting_account
      ? {
          label:
            userData?.branch?.finance_settings?.wip_crediting_account_name ??
            '',
          value: userData?.branch?.finance_settings?.wip_crediting_account
        }
      : ''
  };
}

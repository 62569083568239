import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Card, Col, Container, Form, Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { MdSell } from 'react-icons/md';
import { FaEdit } from 'react-icons/fa';
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';

import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import ConfirmWarning from 'module/Common/Warnings/ConfirmWarning';
import SerachSelectSalesFields from '../Common/SerachSelectSalesFields';
import CustomFieldsNew from '../Common/CustomFieldsNew';

function SalesSettings({ Offcanvas, settings_type }) {
  const Translate = useAxisproTranslate();
  const [loading, setLoading] = useState(true);
  const [salesData, setSalesData] = useState({});
  const [extraData, setExtraData] = useState({});
  const [formData, setFormData] = useState({});
  const [editItems, setEditItems] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);
  const [salesItems, setSalesItems] = useState([]);
  const [preSalesData, setPreSalesData] = useState({});
  const [editField, setEditField] = useState({});
  const handleShow = () => setConfirmShow(!confirmShow);

  const handleFieldChange = (e, action) => {
    let newSalesData;
    let newFormData;
    if (action) {
      newSalesData = {
        ...salesData,
        [action.name]: e ? e.value : null,
        [action.name + '_ref']: e ? e : null
      };
      newFormData = {
        ...formData,
        [action.name + '_id']: e ? e.value : null,
        [action.name + '_name']: e ? e.label : null
      };
      setEditField({ [action.name]: true });
    } else {
      newSalesData = {
        ...salesData,
        [e.target.name]: e.target.value
      };
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value
      };
      setEditField({ [e.target.name]: true });
    }
    setSalesData(newSalesData);
    setFormData(newFormData);
  };

  const fetchData = () => {
    axios
      .get('settings/system/system-settings')
      .then(res => {
        if (res.data.success === true) {
          if (res.data.data.data !== '') {
            let salesSettings = res?.data?.data?.sales_settings;
            setSalesData({
              delivery_screen_detail_hidden_fields:
                salesSettings?.delivery_screen_detail_hidden_fields ?? '',
              delivery_screen_main_hidden_fields:
                salesSettings?.delivery_screen_main_hidden_fields ?? '',
              invoice_screen_detail_hidden_fields:
                salesSettings?.invoice_screen_detail_hidden_fields ?? '',
              invoice_screen_main_hidden_fields:
                salesSettings?.invoice_screen_main_hidden_fields,
              is_enable_auto_sd_from_si:
                salesSettings?.is_enable_auto_sd_from_si ?? '0',
              order_screen_detail_hidden_fields:
                salesSettings?.order_screen_detail_hidden_fields ?? '',
              order_screen_main_hidden_fields:
                salesSettings?.order_screen_main_hidden_fields ?? '',
              default_credit_limit: salesSettings?.default_credit_limit ?? '',
              tax: salesSettings?.tax_id ?? '',
              payment_term_name: salesSettings?.payment_term_name ?? '',
              default_terms_and_conditions_id:
                salesSettings?.default_terms_and_conditions_id ?? '',
              default_terms_and_conditions_name:
                salesSettings?.default_terms_and_conditions_name ?? '',
              default_terms_and_conditions_ref: {
                label: salesSettings?.default_terms_and_conditions_name ?? '',
                value: salesSettings?.default_terms_and_conditions_id ?? ''
              },
              sales_type_name: salesSettings?.sales_type_name ?? '',
              sales_type: salesSettings?.sales_type_id ?? '',
              sales_type_ref: {
                value: salesSettings?.sales_type_id ?? '',
                label: salesSettings?.sales_type_name ?? ''
              },
              payment_term: salesSettings?.payment_term_id ?? '',
              payment_term_ref: {
                label: salesSettings?.payment_term_name ?? '',
                value: salesSettings?.payment_term_id ?? ''
              },
              credit_status_name: salesSettings?.credit_status_name ?? '',
              credit_status: salesSettings?.credit_status_id ?? '',
              credit_status_ref: {
                label: salesSettings?.credit_status_name ?? '',
                value: salesSettings?.credit_status_id ?? ''
              },
              tax_name: salesSettings?.tax_name ?? '',
              tax_ref: {
                label: salesSettings?.tax_name ?? '',
                value: salesSettings?.tax_id ?? ''
              },
              is_item_description_in_si_required:
                salesSettings?.is_item_description_in_si_required ?? '0',
              is_item_description_in_sd_required:
                salesSettings?.is_item_description_in_sd_required ?? '0',
              is_item_description_in_so_required:
                salesSettings?.is_item_description_in_so_required ?? '0',
              is_item_description_in_sq_required:
                salesSettings?.is_item_description_in_sq_required ?? '0',
              is_item_description_in_cn_required:
                salesSettings?.is_item_description_in_cn_required ?? '0'
            });
            setEditItems(false);
            setPreSalesData({
              delivery_screen_detail_hidden_fields:
                salesSettings?.delivery_screen_detail_hidden_fields ?? '',
              delivery_screen_main_hidden_fields:
                salesSettings?.delivery_screen_main_hidden_fields ?? '',
              invoice_screen_detail_hidden_fields:
                salesSettings?.invoice_screen_detail_hidden_fields ?? '',
              invoice_screen_main_hidden_fields:
                salesSettings?.invoice_screen_main_hidden_fields,
              is_enable_auto_sd_from_si:
                salesSettings?.is_enable_auto_sd_from_si ?? '0',
              order_screen_detail_hidden_fields:
                salesSettings?.order_screen_detail_hidden_fields ?? '',
              order_screen_main_hidden_fields:
                salesSettings?.order_screen_main_hidden_fields ?? '',
              default_credit_limit: salesSettings?.default_credit_limit ?? '',
              tax: salesSettings?.tax_id ?? '',
              payment_term_name: salesSettings?.payment_term_name ?? '',
              sales_type_name: salesSettings?.sales_type_name ?? '',
              sales_type: salesSettings?.sales_type_id ?? '',
              sales_type_ref: {
                value: salesSettings?.sales_type_id ?? '',
                label: salesSettings?.sales_type_name ?? ''
              },
              default_terms_and_conditions_id:
                salesSettings?.default_terms_and_conditions_id ?? '',
              default_terms_and_conditions_name:
                salesSettings?.default_terms_and_conditions_name ?? '',
              default_terms_and_conditions_ref: {
                label: salesSettings?.default_terms_and_conditions_name ?? '',
                value: salesSettings?.default_terms_and_conditions_id ?? ''
              },
              payment_term: salesSettings?.payment_term_id ?? '',
              payment_term_ref: {
                label: salesSettings?.payment_term_name ?? '',
                value: salesSettings?.payment_term_id ?? ''
              },
              credit_status_name: salesSettings?.credit_status_name ?? '',
              credit_status: salesSettings?.credit_status_id ?? '',
              credit_status_ref: {
                label: salesSettings?.credit_status_name ?? '',
                value: salesSettings?.credit_status_id ?? ''
              },
              tax_name: salesSettings?.tax_name ?? '',
              tax_ref: {
                label: salesSettings?.tax_name ?? '',
                value: salesSettings?.tax_id ?? ''
              },
              is_item_description_in_si_required:
                salesSettings?.is_item_description_in_si_required ?? '0',
              is_item_description_in_sd_required:
                salesSettings?.is_item_description_in_sd_required ?? '0',
              is_item_description_in_so_required:
                salesSettings?.is_item_description_in_so_required ?? '0',
              is_item_description_in_sq_required:
                salesSettings?.is_item_description_in_sq_required ?? '0',
              is_item_description_in_cn_required:
                salesSettings?.is_item_description_in_cn_required ?? '0'
            });
            setExtraData(res.data.data.extra_options.sales);

            setLoading(false);
          }
        }
      })
      .catch(error => console.log(error));
  };

  const createHiddenInputValue = (fieldName, keyName) => {
    if (
      [
        'is_enable_auto_sd_from_si',
        'is_item_description_in_si_required',
        'is_item_description_in_sd_required',
        'is_item_description_in_so_required',
        'is_item_description_in_sq_required',
        'is_item_description_in_cn_required'
      ].includes(fieldName)
    ) {
      if (salesData[fieldName] === '1') {
        return true;
      } else {
        return false;
      }
    } else {
      let newData = salesData[fieldName]
        ? JSON?.parse(salesData[fieldName])
        : '';
      if (newData.includes(keyName)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    setConfirmShow(false);
    setFormData({});
    setEditField({});
    setSalesData(prev => ({
      ...prev,
      ...formData
    }));
    setPreSalesData(prev => ({
      ...prev,
      ...formData
    }));

    setEditItems(false);
    axios({
      method: 'post',
      url: `settings/sales`,
      data: formData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
        } else {
          setEditItems(true);
          fetchData();
          setEditField(formData);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        if (error) {
          setEditItems(true);
          fetchData();
          setEditField(formData);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      });
  };
  const inputSwitchChange = (e, keyName) => {
    let submitData = {};
    if (
      [
        'is_enable_auto_sd_from_si',
        'is_item_description_in_si_required',
        'is_item_description_in_sd_required',
        'is_item_description_in_so_required',
        'is_item_description_in_sq_required',
        'is_item_description_in_cn_required'
      ].includes(keyName)
    ) {
      if (salesData[keyName] === '1') {
        submitData[keyName] = '0';
      } else {
        submitData[keyName] = '1';
      }
      setSalesData(pre => ({
        ...pre,
        [keyName]: submitData[keyName]
      }));
    } else {
      let checkBoxData = salesData[keyName]
        ? JSON.parse(salesData[keyName])
        : [];
      checkBoxData = checkBoxData.includes(e.target.name)
        ? checkBoxData.filter(item => item !== e.target.name)
        : [...checkBoxData, e.target.name];
      setSalesData(pre => ({
        ...pre,
        [keyName]: JSON.stringify(checkBoxData)
      }));
      submitData = {
        [keyName]: JSON.stringify(checkBoxData)
      };
    }

    axios({
      method: 'post',
      url: `settings/sales`,
      data: submitData
    })
      .then(response => {
        if (response.data.success === true) {
          showToast(response.data.message, 'success');
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            'error'
          );
        }
      })
      .catch(error => {
        console.log(error);
        showToast(
          `Something went wrong, please refresh the page and try again.`,
          'error'
        );
      });
  };
  const salesSettings = [
    {
      heading: 'Customer Settings',
      checkBox: false,
      subHeading: [
        {
          name: 'Sales Type',
          edit: editField.sales_type ? true : false,
          key_name: 'sales_type',
          display_name: salesData.sales_type_name ?? '-',
          element: (
            <SerachSelectSalesFields
              url="settings/sales-types-autocomplete"
              label="name"
              value={salesData.sales_type_ref}
              name="sales_type"
              handleFieldChange={handleFieldChange}
            />
          )
        },
        {
          name: 'Payment Term',
          edit: editField.payment_term ? true : false,
          key_name: 'payment_term',
          display_name: salesData?.payment_term_name ?? '-',
          element: (
            <SerachSelectSalesFields
              url="settings/payment-terms-autocomplete"
              value={salesData.payment_term_ref}
              label={'terms'}
              name="payment_term"
              handleFieldChange={handleFieldChange}
            />
          )
        },
        {
          name: 'Credit Status',
          edit: editField.credit_status ? true : false,
          key_name: 'credit_status',
          display_name: salesData?.credit_status_name ?? '-',
          element: (
            <SerachSelectSalesFields
              url="sales/credit-status"
              label="description"
              value={salesData.credit_status_ref}
              name="credit_status"
              handleFieldChange={handleFieldChange}
            />
          )
        },
        {
          name: 'Tax Type',
          edit: editField.tax ? true : false,
          key_name: 'tax',
          display_name: salesData?.tax_name ?? '-',
          element: (
            <SerachSelectSalesFields
              url="settings/tax-autocomplete"
              value={salesData.tax_ref}
              name="tax"
              label="name"
              handleFieldChange={handleFieldChange}
            />
          )
        },
        {
          name: 'Credit Limit',
          edit: editField.default_credit_limit ? true : false,
          key_name: 'default_credit_limit',
          display_name: salesData?.default_credit_limit ?? '-',
          element: (
            <CustomFieldsNew
              input
              name={'default_credit_limit'}
              handleFieldChange={handleFieldChange}
              value={salesData.default_credit_limit}
              displayData={salesData.default_credit_limit}
              inputType={'number'}
            />
          )
        }
      ]
    },
    {
      heading: 'General Settings',
      checkBox: false,
      subHeading: [
        {
          name: 'Default Terms And Condition',
          edit: editField.default_terms_and_conditions ? true : false,
          key_name: 'default_terms_and_conditions',
          display_name: salesData.default_terms_and_conditions_name ?? '-',
          element: (
            <SerachSelectSalesFields
              url="settings/terms-and-conditions-autocomplete"
              label="name"
              value={salesData.default_terms_and_conditions_ref}
              name="default_terms_and_conditions"
              handleFieldChange={handleFieldChange}
            />
          )
        }
      ]
    },
    {
      heading: 'Sales Quotation Settings',
      checkBox: true,
      subHeading: [
        {
          name: '',
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_sq_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_sq_required')
                  }
                  name="is_item_description_in_sq_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_sq_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Sales Order Settings',
      checkBox: true,
      subHeading: [
        {
          name: 'Order Screen Main Hidden Fields',
          field:
            extraData?.order_screen_main_hidden_fields &&
            Object.keys(extraData?.order_screen_main_hidden_fields).map(
              items => ({
                name: extraData.order_screen_main_hidden_fields[items],
                edit: formData[items] ? true : false,
                key_name: items,
                display_name: createHiddenInputValue(
                  'order_screen_main_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(e, 'order_screen_main_hidden_fields')
                    }
                    className="shadow-none "
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'order_screen_main_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: 'Order Screen Details Hidden Fields',
          field:
            extraData?.order_screen_detail_hidden_fields &&
            Object.keys(extraData?.order_screen_detail_hidden_fields).map(
              items => ({
                name: extraData.order_screen_detail_hidden_fields[items],
                edit: formData[items] ? true : false,
                key_name: items,
                display_name: createHiddenInputValue(
                  'order_screen_detail_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(e, 'order_screen_detail_hidden_fields')
                    }
                    className="shadow-none "
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'order_screen_detail_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: '',
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_so_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_so_required')
                  }
                  name="is_item_description_in_so_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_so_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Sales Delivery Settings',
      checkBox: true,
      subHeading: [
        {
          name: 'Delivery Screen Main Hidden Fields',
          field:
            extraData?.delivery_screen_main_hidden_fields &&
            Object.keys(extraData?.delivery_screen_main_hidden_fields).map(
              items => ({
                name: extraData.delivery_screen_main_hidden_fields[items],
                edit: formData[items] ? true : false,
                key_name: items,
                display_name: createHiddenInputValue(
                  'delivery_screen_main_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    className="shadow-none "
                    onChange={e =>
                      inputSwitchChange(e, 'delivery_screen_main_hidden_fields')
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'delivery_screen_main_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: 'Delivery Screen Details Hidden Fields',
          field:
            extraData?.delivery_screen_detail_hidden_fields &&
            Object.keys(extraData?.delivery_screen_detail_hidden_fields).map(
              items => ({
                name: extraData.delivery_screen_detail_hidden_fields[items],
                edit: formData[items] ? true : false,
                key_name: items,
                display_name: createHiddenInputValue(
                  'delivery_screen_detail_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    className="shadow-none "
                    onChange={e =>
                      inputSwitchChange(
                        e,
                        'delivery_screen_detail_hidden_fields'
                      )
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'delivery_screen_detail_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: '',
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_sd_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_sd_required')
                  }
                  name="is_item_description_in_sd_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_sd_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Sales Invoice Settings',
      checkBox: true,
      subHeading: [
        {
          name: 'Invoice Screen Main Hidden Fields',
          field:
            extraData?.invoice_screen_main_hidden_fields &&
            Object.keys(extraData?.invoice_screen_main_hidden_fields).map(
              items => ({
                name: extraData.invoice_screen_main_hidden_fields[items],
                edit: formData[items] ? true : false,
                key_name: items,
                display_name: createHiddenInputValue(
                  'invoice_screen_main_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    onChange={e =>
                      inputSwitchChange(e, 'invoice_screen_main_hidden_fields')
                    }
                    name={items}
                    className="custom-control-input shadow-none "
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'invoice_screen_main_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: 'Invoice Screen Details Hidden Fields',
          field:
            extraData?.invoice_screen_detail_hidden_fields &&
            Object.keys(extraData?.invoice_screen_detail_hidden_fields).map(
              items => ({
                name: extraData.invoice_screen_detail_hidden_fields[items],
                edit: formData[items] ? true : false,
                key_name: items,
                display_name: createHiddenInputValue(
                  'invoice_screen_detail_hidden_fields',
                  items
                ),
                element: (
                  <Form.Switch
                    className="shadow-none "
                    onChange={e =>
                      inputSwitchChange(
                        e,
                        'invoice_screen_detail_hidden_fields'
                      )
                    }
                    name={items}
                    type="checkbox"
                    checked={createHiddenInputValue(
                      'invoice_screen_detail_hidden_fields',
                      items
                    )}
                  />
                )
              })
            )
        },
        {
          name: '',
          field: [
            {
              name: 'Allow auto sales delivery',
              key_name: 'is_enable_auto_sd_from_si',
              display_name: 'Allow auto sales delivery ',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_enable_auto_sd_from_si')
                  }
                  name="is_enable_auto_sd_from_si"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_enable_auto_sd_from_si',
                    ''
                  )}
                />
              )
            }
          ]
        },
        {
          name: '',
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_si_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_si_required')
                  }
                  name="is_item_description_in_si_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_si_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    },
    {
      heading: 'Credit Note Settings',
      checkBox: true,
      subHeading: [
        {
          name: '',
          field: [
            {
              name: 'Is Item Description Required',
              key_name: 'is_item_description_in_cn_required',
              display_name: 'Is Item Description Required',
              element: (
                <Form.Switch
                  onChange={e =>
                    inputSwitchChange(e, 'is_item_description_in_cn_required')
                  }
                  name="is_item_description_in_cn_required"
                  type="checkbox"
                  checked={createHiddenInputValue(
                    'is_item_description_in_cn_required',
                    ''
                  )}
                />
              )
            }
          ]
        }
      ]
    }
  ];

  const EditSettings = (object, secondName) => {
    setEditItems(true);
    setEditField({ [secondName]: true });
    const updatedData = salesItems.map(items => {
      if (items.heading === object.heading) {
        return {
          ...items,
          subHeading: items.subHeading.map(second => {
            if (second.name === secondName) {
              return {
                ...second,
                edit: true
              };
            }
            return second;
          })
        };
      }
      return items;
    });
    setSalesItems(updatedData);
  };

  const EditSettingsClose = (object, secondName) => {
    setFormData({});
    setEditField({});
    setSalesData(preSalesData);
    setEditItems(false);
    const updatedData = salesItems.map(items => {
      if (items.heading === object.heading) {
        return {
          ...items,
          subHeading: items.subHeading.map(second => {
            if (second.name === secondName) {
              return {
                ...second,
                edit: false
              };
            }
            return second;
          })
        };
      }
      return items;
    });

    setSalesItems(updatedData);
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (salesData) {
      let updatedData = [...salesSettings];
      //offcanvas view time a specific settings filtering function
      if (Offcanvas) {
        let SingleSettings = salesSettings.filter(
          items => items.heading === settings_type
        );
        setSalesItems(SingleSettings);
      } else {
        setSalesItems(updatedData);
      }
    }
  }, [salesData, extraData]);
  return (
    <Col
      className="p-0"
      xl={Offcanvas ? 12 : 10}
      md={Offcanvas ? 12 : 9}
      sm={Offcanvas ? 12 : 6}
    >
      <Card className="flex-fill">
        {!Offcanvas && (
          <Card.Header className="d-flex align-items-center border-bottom pt-3 pb-1">
            <MdSell className="text-dark me-2" size={20} />
            <h5 className="m-0 form-heading  text-uppercase pt-1 pb-2">
              {Translate('Sales Settings')}
            </h5>
          </Card.Header>
        )}
        <Card.Body className="p-0">
          {loading ? (
            <Container
              className="d-flex flex-fill align-items-center justify-content-center text-center"
              style={{ height: Offcanvas ? '94vh' : '86.2vh' }}
            >
              <LoadingScreen message="Setting up sales settings" />
            </Container>
          ) : (
            <Container
              fluid
              className="p-0 flex-wrap"
              style={{
                height: Offcanvas ? '94vh' : '86.2vh',
                overflow: 'scroll'
              }}
            >
              <Table
                hover
                responsive="sm"
                className={`system-settings-table  ${
                  Offcanvas ? ' ms-3' : 'border-bottom'
                }`}
              >
                {salesItems.length > 0 &&
                  salesItems.map((items, index) => {
                    return (
                      <tbody
                        className={`${!Offcanvas && 'border-bottom'}`}
                        key={index}
                      >
                        {!Offcanvas && (
                          <tr className="position-relative border-bottom-zero no-box-shadow">
                            <td
                              className="border-bottom-zero fw-bold text-dark text-center  "
                              style={{ width: '200px' }}
                              rowSpan={
                                items.checkBox
                                  ? items.heading?.length + 2
                                  : items.heading?.length
                              }
                            >
                              <div className="position-absolute fw-bold  top-0 start-0 pt-2 ms-3">
                                {Translate(items.heading)}
                              </div>
                            </td>
                          </tr>
                        )}
                        {items.subHeading.map((subHeading, key) => {
                          if (!items.checkBox) {
                            return (
                              <tr className="w-100" key={key}>
                                <td
                                  className="border-bottom-zero text-dark"
                                  style={{ width: '300px' }}
                                >
                                  <div className="">
                                    {Translate(subHeading?.name)}
                                  </div>
                                </td>
                                <td
                                  className="border-bottom-zero text-dark"
                                  style={{ width: '5px' }}
                                >
                                  :
                                </td>
                                <td
                                  className="border-bottom-zero text-dark "
                                  style={{ width: '300px' }}
                                >
                                  <div>
                                    {subHeading?.edit
                                      ? subHeading?.element
                                      : subHeading?.display_name}
                                    {!subHeading?.edit && !editItems && (
                                      <Button
                                        size="sm"
                                        variant="transparent"
                                        className="border-0 shadow-none  settings-edit ms-5"
                                      >
                                        <div>
                                          <FaEdit
                                            size={13}
                                            onClick={() => {
                                              EditSettings(
                                                items,
                                                subHeading.name
                                              );
                                            }}
                                          />
                                        </div>
                                      </Button>
                                    )}
                                  </div>
                                </td>
                                <td
                                  className="border-bottom-zero d-flex align-items-center justify-content-start "
                                  style={{
                                    height: subHeading?.edit ? '47px' : '42px'
                                  }}
                                >
                                  {subHeading?.edit && (
                                    <>
                                      <Button
                                        variant="transparent"
                                        size="sm"
                                        className=" p-0 m-0 me-1 border-0 shadow-none"
                                        title={Translate('Save')}
                                        onClick={handleShow}
                                      >
                                        <IoMdCheckmarkCircle
                                          size={20}
                                          className="text-success"
                                        />
                                      </Button>
                                      <Button
                                        variant="transparent"
                                        size="sm"
                                        className="p-0 m-0 border-0 shadow-none"
                                        title={Translate('Close')}
                                        onClick={() => {
                                          EditSettingsClose(
                                            items,
                                            subHeading.name
                                          );
                                        }}
                                      >
                                        <IoMdCloseCircle
                                          size={20}
                                          className="text-danger"
                                        />
                                      </Button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          } else {
                            return (
                              <>
                                <tr className=" fw-medium no-box-shadow">
                                  <div className="pt-2 p-0">
                                    {Translate(subHeading.name)}
                                  </div>
                                </tr>
                                {subHeading?.field?.map(
                                  (newItems, newIndex) => {
                                    return (
                                      <tr className="w-100" key={newIndex}>
                                        <td
                                          className="border-bottom-zero text-dark"
                                          style={{ width: '300px' }}
                                        >
                                          <div>{Translate(newItems?.name)}</div>
                                        </td>
                                        <td
                                          className="border-bottom-zero text-dark"
                                          style={{ width: '5px' }}
                                        ></td>
                                        <td
                                          className="border-bottom-zero text-dark "
                                          style={{ width: '300px' }}
                                        >
                                          {newItems?.edit
                                            ? newItems?.element
                                            : newItems?.element}
                                        </td>
                                        <td
                                          className="border-bottom-zero d-flex align-items-end justify-content-end "
                                          style={{
                                            height: newItems?.edit
                                              ? '47px'
                                              : '37px'
                                          }}
                                        ></td>
                                      </tr>
                                    );
                                  }
                                )}
                              </>
                            );
                          }
                        })}
                      </tbody>
                    );
                  })}
              </Table>
            </Container>
          )}

          <ConfirmWarning
            show={confirmShow}
            onHide={handleShow}
            handleSubmit={handleSubmit}
            content="Are you sure you want to change the sales settings?"
          />
        </Card.Body>
      </Card>
    </Col>
  );
}

SalesSettings.propTypes = {
  Offcanvas: PropTypes.bool,
  settings_type: PropTypes.string
};

export default SalesSettings;

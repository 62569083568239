import { React, useLayoutEffect, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Container,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';
import { AiOutlineFileText } from 'react-icons/ai';
import { useBreakpoints } from 'hooks/useBreakpoints';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { Link, useNavigate } from 'react-router-dom';
import PDFView from 'components/PDFViewerButton/PDFView';
import { CloseButton, MoreButton } from 'module/Common/Buttons/ViewPageButtons';
import ViewPagePrintButton from 'module/Common/ViewPages/Common/ViewPagePrintButton';
import VoidTransactionTab from 'module/Common/ViewPages/Common/VoidTransactionTab';
import JournalTable from 'module/Common/ViewPages/Common/Tables/JournalTable';
import DebitNoteView from './DebitNoteView';
import AttachementsView from '../Common/AttachementsView';
import SupplierDetails from 'components/supplier-details/SupplierDetails';
import SendEmail from '../Common/SendEmail';
import FileUpload from '../Common/FileUpload';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import AllocationsTable from '../Common/Tables/AllocationsTable';
import ReFundPopup from '../Common/ReFundPopup';
import TabViewGeneralDetailsSectionCover from 'components/tab-view-general-details-section-cover/TabViewGeneralDetailsSectionCover';
import { FaExternalLinkAlt } from 'react-icons/fa';
import ShowReference from 'components/show-reference/ShowReference';

function DebitNoteTabView({
  debitNoteInfoData,
  loading,
  infoPage,
  onHide,
  fetchData,
  voidScreen,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  getData
}) {
  const Translate = useAxisproTranslate();
  const navigateTo = useNavigate();
  const permission = {
    sendEmail: getAcodaxPermissionSlug('DN', 'send-mail'),
    printDN: getAcodaxPermissionSlug('DN', 'print'),
    create: getAcodaxPermissionSlug('DN', 'create'),
    show_sp: getAcodaxPermissionSlug('supplier', 'show'),
    voidTransaction: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const [showSupplierDetailsArea, setSupplierDetailsArea] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [person, setPerson] = useState(() => ({
    personType: 'SUPPLIER',
    personId: '',
    name: '',
    value: ''
  }));
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [reFund, setReFund] = useState(false);
  const [tabApiCallCompleted, setTabApiCallCompleted] = useState({
    journal: false,
    allocations: false
  });

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleSupplierDetailsArea = () => {
    setSupplierDetailsArea(!showSupplierDetailsArea);
  };
  const handleCancel = () => {
    setShowEmailModal(false);
  };

  useLayoutEffect(() => {
    setPerson(() => ({
      personType: debitNoteInfoData.person_type,
      personId: debitNoteInfoData?.person_id,
      name: debitNoteInfoData.person_name,
      value: debitNoteInfoData?.person_id
    }));
  }, [debitNoteInfoData]);

  return (
    <>
      <Card className="flex-fill view-page-content-wrapper">
        {debitNoteInfoData.id ? (
          <>
            <Card.Header
              className={
                breakpoints.up('sm')
                  ? 'd-flex m-0 align-items-center border-bottom'
                  : 'border-bottom'
              }
            >
              <>
                <div className="d-flex m-0 align-items-center">
                  <AiOutlineFileText size={28} className="me-2 text-dark" />
                  <h5
                    className="mb-0 d-flex text-uppercase view-page-header"
                    style={{ fontSize: '25px' }}
                  >
                    {Translate('Debit Note')}
                  </h5>
                </div>
                {voidTransactionReport && (
                  <Badge bg="danger" className="ms-2 fs--1">
                    {Translate('Voided')}
                  </Badge>
                )}
                <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center dropdown-menu-style">
                  {voidScreen || offCanvas ? (
                    ''
                  ) : (
                    <MoreButton
                      sendEmail={sendEmail}
                      setUpload={setUpload}
                      emailPermission={permission?.sendEmail}
                      refundValiadtions
                      setReFund={setReFund}
                      disableRefundButton={
                        !!(
                          isNaN(parseFloat(debitNoteInfoData?.balance)) ||
                          parseFloat(debitNoteInfoData?.balance) <= 0
                        )
                      }
                      voidTransactionData={
                        permission?.voidTransaction
                          ? {
                              id: debitNoteInfoData?.id,
                              trans_type: 'DN',
                              callBack: () => {
                                navigateTo(
                                  '/purchase/debit-note?layout=column'
                                );
                                typeof getData === 'function' && getData(1);
                              }
                            }
                          : undefined
                      }
                    />
                  )}
                  {!voidTransactionReport && permission?.printDN && (
                    <ViewPagePrintButton
                      variant="danger"
                      className="text-white"
                      style={{ boxShadow: 'none' }}
                      url={`/finance/debit-note/print/${debitNoteInfoData.id}`}
                      pageTitle="debit note print view"
                    />
                  )}
                  {infoPage || offCanvas ? (
                    <CloseButton
                      setShowVoucherDetailArea={setShowVoucherDetailArea}
                      onHide={onHide}
                    />
                  ) : (
                    ''
                  )}
                </ButtonGroup>
              </>
            </Card.Header>
            <Card.Body className={loading && 'd-flex'}>
              <TabViewGeneralDetailsSectionCover mainTitle="General Details">
                <TabViewGeneralDetailsSectionCover.Section position="start">
                  <TabViewGeneralDetailsSectionCover.Item
                    title="Supplier Name"
                    value={debitNoteInfoData?.person_name}
                    EndElement={
                      <Button
                        variant="transparent"
                        className="p-0 mb-2 text-primary outline-none"
                        onClick={() => setSupplierDetailsArea(true)}
                        size="sm"
                      >
                        <FaExternalLinkAlt size={10} />
                      </Button>
                    }
                  />

                  <TabViewGeneralDetailsSectionCover.Item
                    title="Transaction Date"
                    value={debitNoteInfoData?.trans_date_formatted}
                  />

                  <TabViewGeneralDetailsSectionCover.Item
                    title="Due Date"
                    value={debitNoteInfoData?.due_date_formatted}
                  />
                </TabViewGeneralDetailsSectionCover.Section>

                <TabViewGeneralDetailsSectionCover.Section position="end">
                  <TabViewGeneralDetailsSectionCover.Item
                    title="Reference"
                    contentSectionClassName="mb-2"
                  >
                    <ShowReference
                      data={debitNoteInfoData}
                      to={`/purchase/debit-note?entry=${debitNoteInfoData?.id}&layout=column`}
                      offCanvas={offCanvas}
                      readOnly={voidTransactionReport}
                    />
                  </TabViewGeneralDetailsSectionCover.Item>

                  <TabViewGeneralDetailsSectionCover.Item
                    title="Tax Included"
                    value={
                      [true, 1, '1'].includes(debitNoteInfoData?.tax_included)
                        ? 'Included'
                        : 'Excluded'
                    }
                  />

                  <TabViewGeneralDetailsSectionCover.Item
                    title="Payment Term"
                    value={debitNoteInfoData?.payment_term_name}
                  />
                </TabViewGeneralDetailsSectionCover.Section>

                <TabViewGeneralDetailsSectionCover.CustomColumnResultSection
                  customColumnItems={debitNoteInfoData?.custom_field_values}
                />
              </TabViewGeneralDetailsSectionCover>

              <Container className="p-0 view-page-tab-style-wrapper">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={k => setKey(k)}
                  color="red"
                >
                  {voidScreen ? (
                    <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                      <SimpleBar className="simplebar-view-page-style">
                        <VoidTransactionTab
                          transType={debitNoteInfoData.trans_type}
                          transId={debitNoteInfoData.id}
                        />
                      </SimpleBar>
                    </Tab>
                  ) : (
                    ''
                  )}
                  <Tab eventKey="overview" title={Translate('Overview')}>
                    <SimpleBar
                      className={
                        Math.round(debitNoteInfoData.amount) ===
                          Math.round(debitNoteInfoData.alloc) ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <DebitNoteView debitNoteInfoData={debitNoteInfoData} />
                    </SimpleBar>
                  </Tab>
                  <Tab eventKey="journal" title={Translate('Journal')}>
                    <SimpleBar
                      className={
                        Math.round(debitNoteInfoData.balance) === 0 ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <JournalTable
                        id={debitNoteInfoData?.id}
                        transType={'DN'}
                        activeTabKey={key}
                        tabApiCallCompleted={tabApiCallCompleted?.journal}
                        setTabApiCallCompleted={setTabApiCallCompleted}
                      />
                    </SimpleBar>
                  </Tab>
                  <Tab eventKey="allocations" title={Translate('Allocations')}>
                    <SimpleBar
                      className={
                        Math.round(debitNoteInfoData.amount) ===
                          Math.round(debitNoteInfoData.alloc) ||
                        voidScreen ||
                        offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <AllocationsTable
                        id={debitNoteInfoData?.id}
                        t_type={'DN'}
                        activeTabKey={key}
                        tabApiCallCompleted={tabApiCallCompleted?.allocations}
                        setTabApiCallCompleted={setTabApiCallCompleted}
                      />
                    </SimpleBar>
                  </Tab>
                  {debitNoteInfoData.media &&
                  debitNoteInfoData.media.length > 0 ? (
                    <Tab
                      eventKey="attachments"
                      title={Translate('Attachments')}
                    >
                      <SimpleBar
                        className={
                          Math.round(debitNoteInfoData.amount) ===
                            Math.round(debitNoteInfoData.alloc) ||
                          voidScreen ||
                          offCanvas
                            ? 'simplebar-view-page-style'
                            : 'simplebar-view-page-style-with-container'
                        }
                      >
                        <AttachementsView
                          data={debitNoteInfoData}
                          fetchData={fetchData}
                          setKey={setKey}
                        />
                      </SimpleBar>
                    </Tab>
                  ) : (
                    ''
                  )}
                  {debitNoteInfoData?.id ? (
                    <Tab eventKey="pdfView" title={Translate('PDF View')}>
                      <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                        {key === 'pdfView' && (
                          <PDFView
                            viewType="tab"
                            url={`finance/debit-note/print/${debitNoteInfoData.id}`}
                          />
                        )}
                      </SimpleBar>
                    </Tab>
                  ) : null}
                </Tabs>
              </Container>
            </Card.Body>
            <Card.Footer className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center gap-3">
                <h6 className="m-0 fw-bold">
                  {Translate('Created By')}:&nbsp;
                </h6>
                <h6 className="m-0">
                  {debitNoteInfoData && debitNoteInfoData.created_user_full_name
                    ? debitNoteInfoData.created_user_full_name
                    : 'null'}
                </h6>
              </div>
              <div className="d-flex align-items-center gap-3">
                <h6 className="m-0 fw-bold">
                  {Translate('Created At')}:&nbsp;
                </h6>
                <h6 className="m-0">
                  {debitNoteInfoData && debitNoteInfoData.created_at
                    ? debitNoteInfoData.created_at
                    : 'null'}
                </h6>
              </div>
            </Card.Footer>
          </>
        ) : (
          <div className="d-flex h-100 align-items-center justify-content-center">
            {permission.create && (
              <Button
                className="m-1"
                size="sm"
                as={Link}
                to="/purchase/debit-note/add"
              >
                {Translate('Add Debit Note')}
              </Button>
            )}
          </div>
        )}
      </Card>
      <SupplierDetails
        show={showSupplierDetailsArea}
        onHide={handleSupplierDetailsArea}
        value={person}
        showSupplierDetailsArea={showSupplierDetailsArea}
      />
      {debitNoteInfoData.id && (
        <FileUpload
          show={upload}
          onHide={() => setUpload(false)}
          fetchData={fetchData}
          type={'DN'}
          id={debitNoteInfoData.id}
          setKey={setKey}
        />
      )}
      <ReFundPopup
        show={reFund}
        popupData={{
          personId: debitNoteInfoData.person_id,
          personType: 'SUPPLIER',
          personName: debitNoteInfoData.person_name,
          refundAmount: debitNoteInfoData.balance,
          reference: debitNoteInfoData.reference,
          refundAmountFormatted: debitNoteInfoData.balance_formatted,
          transType: 'DN',
          transNo: debitNoteInfoData.voucher_id,
          value: debitNoteInfoData.person_id
        }}
        fetchData={() => {
          fetchData();
          setTabApiCallCompleted(prev => ({
            ...prev,
            allocations: false
          }));
        }}
        onHide={() => setReFund(false)}
      />
      <SendEmail
        url={'finance/debit-note-email/' + debitNoteInfoData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
        data={debitNoteInfoData.email}
      />
    </>
  );
}

DebitNoteTabView.propTypes = {
  debitNoteInfoData: PropTypes.any,
  loading: PropTypes.bool,
  infoPage: PropTypes.bool,
  onHide: PropTypes.func,
  fetchData: PropTypes.func,
  voidScreen: PropTypes.string,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  getData: PropTypes.func
};

export default DebitNoteTabView;

import { React, useEffect, useRef, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from 'react-router-dom';
import axios from 'axios';
import {
  Badge,
  ButtonGroup,
  Card,
  Container,
  Dropdown,
  Tab,
  Tabs
} from 'react-bootstrap';
import SimpleBar from 'simplebar-react';
import PropTypes from 'prop-types';

import { BsReceipt } from 'react-icons/bs';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { useBreakpoints } from 'hooks/useBreakpoints';
import {
  CloseButton,
  EditButton,
  MoreButton
} from 'module/Common/Buttons/ViewPageButtons';
import { showToast } from 'module/Common/Toast/toast';
import { getAcodaxPermissionSlug } from 'module/Common/Validators/permissions/PermissionSlug';
import VoidTransactionTab from '../Common/VoidTransactionTab';
import CustomerDetails from 'components/customer-details/CustomerDetails';
import ViewPagePrintButton from '../Common/ViewPagePrintButton';
import AttachementsView from '../Common/AttachementsView';
import FileUpload from '../Common/FileUpload';
import SendEmail from '../Common/SendEmail';
import ContractView from './ContractView';
import TransactionTable from '../Common/Tables/TransactionTable';
import AppLayer from '../../Layer/AppLayer';
import PDFView from 'components/PDFViewerButton/PDFView';
import Badges from '../Common/ViewPageDetails/Badges';
import PersonName from '../Common/ViewPageDetails/PersonName';
import MainDetails from '../Common/ViewPageDetails/MainDetails';
import Reference from '../Common/ViewPageDetails/Reference';
import FooterDetails from '../Common/ViewPageDetails/FooterDetails';

function ContractTabView({
  contractData,
  loading,
  voidScreen,
  infoPage,
  fetchData,
  offCanvas,
  setShowVoucherDetailArea,
  voidTransactionReport,
  onHide,
  getData
}) {
  let navigateTo = useNavigate();
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();
  const dropdownRef = useRef();
  const [key, setKey] = useState(voidScreen ? 'voidForm' : 'overview');
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [person] = useState({
    value: contractData.customer_id
  });
  const [upload, setUpload] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const permission = {
    show_ct: getAcodaxPermissionSlug('CT', 'show'),
    show_cs: getAcodaxPermissionSlug('CS', 'show'),
    print_ct: getAcodaxPermissionSlug('CT', 'print'),
    create_ct: getAcodaxPermissionSlug('CT', 'create'),
    update_ct: getAcodaxPermissionSlug('CT', 'update'),
    send_mail_ct: getAcodaxPermissionSlug('CT', 'send_mail'),
    void_ct: getAcodaxPermissionSlug('VOID', 'TRANSACTION')
  };
  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const sendEmail = () => {
    setShowEmailModal(true);
  };

  const handleCancel = () => {
    setShowEmailModal(false);
  };

  const handleStatusChange = status => {
    if (contractData?.status == status) {
      return null;
    } else {
      setIsLoading(true);
      axios({
        method: 'post',
        url: `sales/contracts/update-status/${contractData.id}`,
        data: { status: status }
      })
        .then(res => {
          if (res?.data?.success) {
            showToast(res.data?.message, 'success');
            fetchData();
            setIsLoading(false);
          }
        })
        .catch(error => {
          if (error?.response?.data?.message) {
            showToast(error.response.data.message, 'error');
            setIsLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    const source = axios.CancelToken.source();
    axios
      .get('sales/contract-status', { cancelToken: source.token })
      .then(res => {
        if (res?.data?.success) {
          setStatus(res?.data?.data);
        }
      })
      .catch(error => {
        if (!axios.isCancel(error)) {
          console.log(error);
        }
      });
    return () => {
      source.cancel('Request cancelled due to component unmount');
    };
  }, []);

  return (
    <>
      {isLoading ? <AppLayer /> : ''}
      <Card className="flex-fill view-page-content-wrapper">
        <Card.Header
          className={
            breakpoints.up('sm')
              ? 'd-flex m-0 align-items-center border-bottom'
              : 'border-bottom'
          }
        >
          <>
            <div className="d-flex m-0 align-items-center">
              <BsReceipt size={28} className="me-2 text-dark" />
              <h5 className="mb-0 d-flex text-uppercase view-page-header">
                {Translate('Contract')}
              </h5>
            </div>
            {contractData?.status && (
              <Badge
                bg={
                  contractData?.status === 'active'
                    ? 'success'
                    : contractData?.status === 'hold'
                    ? 'warning'
                    : contractData?.status === 'renewed'
                    ? 'info'
                    : 'danger'
                }
                className="ms-2 p-0 px-2 fs--1 text-uppercase d-flex align-items-center justify-content-center cursor-pointer"
                onClick={() => {
                  if (dropdownRef.current) {
                    dropdownRef.current.click();
                  }
                }}
              >
                {Translate(contractData?.status)}
                <div
                  className="ms-2 cursor-pointer"
                  title={Translate('Click here to change contract status!')}
                >
                  <Dropdown>
                    <Dropdown.Toggle
                      className="p-0"
                      variant="transparent"
                      style={{ boxShadow: 'none' }}
                      ref={dropdownRef}
                    />
                    <Dropdown.Menu>
                      {status &&
                        status.length > 0 &&
                        status.map((item, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => handleStatusChange(item)}
                          >
                            {Translate(item.toUpperCase())}
                          </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Badge>
            )}
            {voidTransactionReport && (
              <Badges label={'Voided'} className="ms-2 fs--1" bg={'danger'} />
            )}
            <ButtonGroup className="ms-auto d-flex gap-2 flex-row align-items-center">
              {voidScreen || offCanvas ? (
                ''
              ) : (
                <MoreButton
                  sendEmail={sendEmail}
                  setUpload={setUpload}
                  emailPermission={permission?.send_mail_ct}
                  firstConversionValidation={true}
                  disableFirstConversion={
                    (contractData?.balanced_invoiced_amount &&
                      parseFloat(contractData.balanced_invoiced_amount) <= 0) ||
                    contractData?.status === 'expired'
                  }
                  firstConversionTo={
                    '/sales/sales-invoice/add/ct/' + contractData.id
                  }
                  afterFirstConversion={
                    contractData?.status === 'expired'
                      ? Translate('Contract Expired!')
                      : Translate('Fully Invoiced!')
                  }
                  beforeFirstConversion={
                    contractData?.status === 'expired'
                      ? Translate('Contract Expired!')
                      : Translate('Convert to Sales Invoice')
                  }
                  voidTransactionData={
                    permission?.void_ct
                      ? {
                          id: contractData?.id,
                          trans_type: 'CT',
                          callBack: () => {
                            navigateTo('/sales/contract/info');
                            typeof getData === 'function' && getData(1);
                          }
                        }
                      : undefined
                  }
                />
              )}
              {voidScreen || infoPage || offCanvas ? (
                ''
              ) : permission?.update_ct ? (
                <EditButton
                  to={`/sales/contract/edit/${
                    contractData.id
                  }?${createSearchParams({
                    ...allQueryParams
                  })}`}
                />
              ) : (
                ''
              )}
              {!voidTransactionReport && permission?.print_ct && (
                <ViewPagePrintButton
                  variant="danger"
                  className="text-white"
                  style={{ boxShadow: 'none' }}
                  url={`sales/contract/print/${contractData.id}`}
                  pageTitle="Credit note print view"
                />
              )}
              {offCanvas ? (
                <CloseButton
                  setShowVoucherDetailArea={setShowVoucherDetailArea || onHide}
                />
              ) : (
                ''
              )}
            </ButtonGroup>
          </>
        </Card.Header>
        <Card.Body className={loading && 'd-flex'}>
          <div className="d-flex flex-wrap justify-content-between">
            <div>
              {contractData?.customer_name ? (
                <PersonName
                  label={'Customer Name'}
                  value={contractData?.customer_name}
                  view
                  permission={permission?.show_cs}
                  setCustomerDetailsArea={setCustomerDetailsArea}
                />
              ) : null}
              {contractData?.contract_date_formatted ? (
                <MainDetails
                  label={'Contract Date'}
                  value={contractData?.contract_date_formatted}
                />
              ) : null}
            </div>
            <div>
              {contractData?.reference ? (
                <Reference
                  offCanvas={offCanvas}
                  voidTransactionReport={voidTransactionReport}
                  to={`/sales/contract/info/${contractData?.id}`}
                  value={contractData?.reference}
                />
              ) : null}
              {contractData?.amount_formatted ? (
                <MainDetails
                  label={'Total Contract Amount'}
                  value={contractData?.amount_formatted}
                />
              ) : null}
              {contractData?.total_invoiced_amount_formatted ? (
                <MainDetails
                  label={'Total Invoiced Amount'}
                  value={contractData?.total_invoiced_amount_formatted}
                />
              ) : null}
            </div>
          </div>
          <Container className="p-0 view-page-tab-style-wrapper">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={k => setKey(k)}
              color="red"
            >
              {voidScreen ? (
                <Tab eventKey="voidForm" title={Translate('Voiding Form')}>
                  <SimpleBar className="simplebar-view-page-style">
                    <VoidTransactionTab
                      transType={contractData.trans_type}
                      transId={contractData.id}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              <Tab eventKey="overview" title={Translate('Overview')}>
                <SimpleBar
                  className={
                    voidScreen || offCanvas
                      ? 'simplebar-view-page-style'
                      : 'simplebar-view-page-style-with-container'
                  }
                >
                  <ContractView contractData={contractData} />
                </SimpleBar>
              </Tab>
              {contractData?.sales_invoices &&
                contractData.sales_invoices.length > 0 && (
                  <Tab
                    eventKey="transactions"
                    title={Translate('Transactions')}
                  >
                    <SimpleBar
                      className={
                        voidScreen || offCanvas
                          ? 'simplebar-view-page-style'
                          : 'simplebar-view-page-style-with-container'
                      }
                    >
                      <TransactionTable data={contractData} />
                    </SimpleBar>
                  </Tab>
                )}
              {contractData.media && contractData.media.length > 0 ? (
                <Tab eventKey="attachments" title={Translate('Attachments')}>
                  <SimpleBar
                    className={
                      Math.round(contractData.balance) === 0 ||
                      voidScreen ||
                      offCanvas
                        ? 'simplebar-view-page-style'
                        : 'simplebar-view-page-style-with-container'
                    }
                  >
                    <AttachementsView
                      data={contractData}
                      fetchData={fetchData}
                      setKey={setKey}
                    />
                  </SimpleBar>
                </Tab>
              ) : (
                ''
              )}
              {contractData?.id ? (
                <Tab eventKey="pdfView" title={Translate('PDF View')}>
                  <SimpleBar className="simplebar-view-page-style pdf-tab-view-simplebar-style">
                    {key === 'pdfView' && (
                      <PDFView
                        viewType="tab"
                        url={`sales/contract/print/${contractData.id}`}
                      />
                    )}
                  </SimpleBar>
                </Tab>
              ) : null}
            </Tabs>
          </Container>
        </Card.Body>
        <FooterDetails
          created_at={contractData?.created_at}
          created_by={contractData?.created_user_full_name}
        />
      </Card>
      <CustomerDetails
        show={showCustomerDetailsArea}
        onHide={handleCustomerDetailsArea}
        value={person}
        showCustomerDetailsArea={showCustomerDetailsArea}
      />
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={'CT'}
        id={contractData.id}
        setKey={setKey}
      />
      <SendEmail
        url={'sales/contracts-email/' + contractData.id}
        showEmailModal={showEmailModal}
        handleCancel={handleCancel}
      />
    </>
  );
}

ContractTabView.propTypes = {
  contractData: PropTypes.any,
  loading: PropTypes.bool,
  voidScreen: PropTypes.bool,
  infoPage: PropTypes.bool,
  fetchData: PropTypes.func,
  offCanvas: PropTypes.bool,
  setShowVoucherDetailArea: PropTypes.func,
  voidTransactionReport: PropTypes.bool,
  onHide: PropTypes.bool,
  getData: PropTypes.func
};

export default ContractTabView;

import React, { useEffect, useState } from 'react';
import { Alert, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import axios from 'axios';
import { showToast } from 'module/Common/Toast/toast';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import PurchaseHistoryTable from 'components/purchase-history-modal/PurchaseHistoryTable';
import LoadingScreen from 'components/common/loading-screen/LoadingScreen';
import DataFetching from 'module/Report/LedgerReport/DataFetching';
import { FaListAlt } from 'react-icons/fa';

export default function PurchaseHistoryModal({ supplierId, stockId }) {
  const Translate = useAxisproTranslate();
  const [fechingResult, setFechingResult] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [purchaseHistoryData, setPurchaseHistoryData] = useState(undefined);

  const handleShowModal = event => {
    event?.preventDefault();
    setShowModal(!showModal);
  };

  const fetchData = async page => {
    try {
      !page || (page < 2 && setFechingResult(true));
      const apiResult = await axios.get(
        `inventory/supplier-wise-stock-movements`,
        {
          params: {
            page: page || 1,
            supplier_id: supplierId,
            stock_id: stockId
          }
        }
      );
      if (apiResult?.data?.data) {
        if (page > 1) {
          setPurchaseHistoryData(prev => ({
            ...apiResult.data.data,
            data: [
              ...(prev?.data || null),
              ...(apiResult.data.data?.data || null)
            ]
          }));
          return;
        }
        setPurchaseHistoryData(apiResult.data.data);
      }
    } catch (error) {
      console.error(error);
      showToast(
        Translate('Something went wrong, please refresh and try again')
      );
    } finally {
      setFechingResult(false);
    }
  };

  useEffect(() => {
    if (showModal) {
      setPurchaseHistoryData(undefined);
      fetchData();
    }

    return () => {
      setFechingResult(true);
      setPurchaseHistoryData(undefined);
    };
  }, [showModal, supplierId, stockId]);

  return (
    <>
      <a
        href="#"
        role="button"
        className="d-flex justify-content-end mt-1"
        onClick={handleShowModal}
      >
        <small>{Translate('Show Purchase History')}</small>
      </a>

      <Modal show={showModal} onHide={handleShowModal} size="lg">
        <Modal.Header
          className="d-flex flex-row gap-2"
          style={{
            fontSize: '1.1rem'
          }}
          closeButton
        >
          <FaListAlt className="text-info" />
          <Modal.Title className="text-dark" as={'p'}>
            {Translate('Purchase History')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fechingResult ? (
            <LoadingScreen message="Looking for result" />
          ) : purchaseHistoryData?.meta?.total > 0 ? (
            <>
              <PurchaseHistoryTable data={purchaseHistoryData} />
              {purchaseHistoryData?.meta?.last_page > 0 &&
              purchaseHistoryData?.meta?.current_page !==
                purchaseHistoryData.meta.last_page ? (
                <DataFetching
                  nextLink={purchaseHistoryData.meta.current_page + 1}
                  loadNextPage={() =>
                    fetchData(purchaseHistoryData.meta.current_page + 1)
                  }
                />
              ) : purchaseHistoryData?.meta?.last_page > 0 &&
                purchaseHistoryData?.meta?.last_page ===
                  purchaseHistoryData?.meta?.current_page ? (
                <p className="text-center">{Translate('End Reached')}</p>
              ) : null}
            </>
          ) : (
            <Alert>{Translate('No result found!')}</Alert>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

PurchaseHistoryModal.propTypes = {
  supplierId: PropTypes.string,
  stockId: PropTypes.string
};

import { React } from 'react';
import Select from 'react-select';

import PropTypes from 'prop-types';

function CustomDropDown({
  name,
  value,
  onChange,
  style,
  placeholder,
  isInvalid,
  options
}) {
  return (
    <Select
      isClearable
      value={value ?? ''}
      placeholder={placeholder ?? ''}
      name={name ? name : 'owner'}
      className={`app-react-select${
        isInvalid ? ' form-control ps-0 py-0 is-invalid' : ''
      }`}
      classNamePrefix="app-react-select"
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: provided => ({
          ...provided,
          ...style
        })
      }}
      onChange={onChange}
      options={options}
    />
  );
}

CustomDropDown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  style: PropTypes.any,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  options: PropTypes.any
};

export default CustomDropDown;

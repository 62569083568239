import { React, useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { Card } from 'react-bootstrap';

import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getColor, rgbaColor } from 'helpers/utils';
import * as echarts from 'echarts/core';
import PropTypes from 'prop-types';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import AppContext from 'context/Context';
import Loader from '../Common/Loader';
import { contractDetails } from '../Common/LoaderData';
import useAxisproTranslate from 'hooks/useAxisproTranslate';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
  LegendComponent
]);

const getOption = (isDark, result) => {
  const Translate = useAxisproTranslate();
  return {
    legend: {
      left: 'start',
      bottom: 5,
      itemWidth: 12,
      itemHeight: 12,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('gray-400'),
      inactiveBorderColor: 'transparent',
      textStyle: {
        color: getColor('dark'),
        fontSize: 12,
        fontFamily: `'Inter', 'Source Sans Pro', Helvetica, Arial, sans-serif`,
        fontWeight: '500'
      },
      itemGap: 10
    },
    series: [
      {
        type: 'pie',
        radius: '70%',
        label: {
          show: false
        },
        center: ['50%', '35%'],
        itemStyle: {
          borderWidth: 2,
          borderColor: isDark ? '#121E2D' : getColor('gray-100')
        },
        data: [
          {
            value: result?.total_active_contracts,
            name: Translate('Total Active Contracts'),
            itemStyle: {
              color: rgbaColor(getColor('success'), 1)
            }
          },
          {
            value: result?.total_due_to_renewal,
            name: Translate('Total Contracts Due To Renewal'),
            itemStyle: {
              color: rgbaColor(getColor('warning'), 1)
            }
          },
          {
            value: result?.total_expired,
            name: Translate('Total Contracts Expired'),
            itemStyle: {
              color: rgbaColor(getColor('danger'), 1)
            }
          }
        ]
      }
    ],
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    }
  };
};

function ContractDetails({ draggable }) {
  const { config } = useContext(AppContext);
  const { isDark } = config;
  const Translate = useAxisproTranslate();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  const fetchData = () => {
    setLoading(true);
    axios
      .get('sales/contracts-details')
      .then(res => {
        if (res.data.success === true) {
          setResult(res.data.data);
          setLoading(false);
        }
      })
      .catch(error => console.log(error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
      <Card.Header className="pb-0">
        <h6 className="mb-0 mt-0 tile-heading">
          {Translate('Contract Details')}
        </h6>
      </Card.Header>
      {!loading ? (
        <Card.Body>
          <ReactEChartsCore
            echarts={echarts}
            option={getOption(isDark, result)}
            className="h-100 w-100"
          />
        </Card.Body>
      ) : (
        <Card.Body className="p-2">
          <Loader loader={contractDetails} />
        </Card.Body>
      )}
    </Card>
  );
}
ContractDetails.propTypes = {
  draggable: PropTypes.bool
};
export default ContractDetails;

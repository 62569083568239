import { React, useEffect, useState } from 'react';
import axios from 'axios';
import { Badge, Card, Col, Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import Background from 'components/common/Background';
import Loader from '../Common/Loader';
import { receivablesAndPayablesForFinance } from '../Common/LoaderData';
import recieve from 'assets/img/money.png';
import corner1 from 'assets/img/icons/spot-illustrations/corner-4.png';
import FalconCardHeader from 'components/common/FalconCardHeader';
import CardDropdown from 'components/common/CardDropdown';
import CalendarRangePickerModal from 'components/CalendarRangePickerModal/CalendarRangePickerModal';
import { FaCalendar } from 'react-icons/fa';

function TotalReceivables({ finance, draggable, data, setData, loading }) {
  const Translate = useAxisproTranslate();
  const [showDateRangePicker, setshowDateRangePicker] = useState(false);
  const [receivablesDateRange, setReceivablesDateRange] = useState(null);
  const [isLoading, setIsLoading] = useState(loading);
  function handleSetDateRange(event) {
    setReceivablesDateRange(event?.target?.value ?? null);
  }
  function handleShowModal() {
    setshowDateRangePicker(!showDateRangePicker);
  }

  const fetchData = (reset = false) => {
    if (receivablesDateRange) {
      setIsLoading(true);
      setshowDateRangePicker(false);
      if (reset) {
        setReceivablesDateRange(null);
      }
      axios
        .get('finance/total-receivables-and-payables', {
          params:
            !reset &&
            Array.isArray(receivablesDateRange) &&
            receivablesDateRange.length > 0
              ? {
                  from_date: receivablesDateRange[0],
                  to_date: receivablesDateRange[1]
                }
              : null
        })
        .then(res => {
          if (res.data.success === true) {
            setData(prev => ({
              ...prev,
              total_receivables: res?.data?.data?.total_receivables,
              total_receivables_formatted:
                res?.data?.data?.total_receivables_formatted
            }));
            setIsLoading(false);
          }
        })
        .catch(error => {
          if (error) {
            setIsLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {showDateRangePicker ? (
        <CalendarRangePickerModal
          show={showDateRangePicker}
          value={receivablesDateRange}
          onChange={handleSetDateRange}
          onBack={() => handleShowModal(undefined)}
          onReset={() => fetchData(true)}
          onApply={() => fetchData(false)}
        />
      ) : null}

      <Col className="h-100 " md={finance ? 6 : 12}>
        <Card className={`h-100 ${draggable && 'dashboard_shake'}`}>
          <Background image={corner1} />
          <FalconCardHeader
            title={Translate('Total Receivables')}
            light={false}
            titleTag="h6"
            className="pb-0 tile-heading"
            endEl={
              <CardDropdown>
                <div className="py-2">
                  <Dropdown.Item onClick={() => handleShowModal('receivables')}>
                    {Translate('Change Date')}
                  </Dropdown.Item>
                </div>
              </CardDropdown>
            }
          />
          {!isLoading && !loading ? (
            <Card.Body className="p-0">
              <div className="h-100 d-flex flex-column gap-2 align-items-center justify-content-center">
                <h2 className="fw-bold fs-1 m-0">
                  {data?.total_receivables_formatted}
                </h2>
                <img
                  src={recieve}
                  alt=""
                  style={{ width: finance ? '3rem' : '4rem' }}
                />
              </div>
            </Card.Body>
          ) : (
            <Card.Body className="p-0">
              <Loader
                loader={receivablesAndPayablesForFinance}
                className="ps-4"
              />
            </Card.Body>
          )}
          {Array.isArray(receivablesDateRange) &&
          receivablesDateRange.length > 1 ? (
            <Card.Footer className="pt-0">
              <div className="d-flex flex-row">
                <FaCalendar />{' '}
                <Badge className="ms-1">
                  {receivablesDateRange[0]} - {receivablesDateRange[1]}
                </Badge>
              </div>
            </Card.Footer>
          ) : (
            <Card.Footer className="p-2"></Card.Footer>
          )}
        </Card>
      </Col>
    </>
  );
}

TotalReceivables.propTypes = {
  finance: PropTypes.bool,
  draggable: PropTypes.bool,
  data: PropTypes.any,
  setData: PropTypes.any,
  loading: PropTypes.bool,
  setLoading: PropTypes.any
};

export default TotalReceivables;

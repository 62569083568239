import React from 'react';
import PropTypes from 'prop-types';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';

export default function PurchaseHistoryTable({ data }) {
  const Translate = useAxisproTranslate();
  const columns = [
    {
      accessor: 'date',
      Header: Translate('Date'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-1'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">
            {rowData.row.original.trans_date_formatted}
          </h5>
        );
      }
    },
    {
      accessor: 'transaction_type',
      Header: Translate('Transaction Type'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-1'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">{rowData.row.original.trans_type_name}</h5>
        );
      }
    },
    {
      accessor: 'quantity',
      Header: Translate('Quantity'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-1'
      },
      Cell: rowData => {
        return <h5 className="mb-0 fs--1">{rowData.row.original.stock_in}</h5>;
      }
    },
    {
      accessor: 'reference',
      Header: Translate('Reference'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-1'
      },
      Cell: rowData => {
        return <h5 className="mb-0 fs--1">{rowData.row.original.reference}</h5>;
      }
    },

    {
      accessor: 'price',
      Header: Translate('Price'),
      headerProps: { className: 'pe-1' },
      cellProps: {
        className: 'py-1'
      },
      Cell: rowData => {
        return (
          <h5 className="mb-0 fs--1">{rowData.row.original.price_formatted}</h5>
        );
      }
    }
  ];

  return (
    <>
      <AdvanceTableWrapper
        columns={columns}
        data={data?.data ?? []}
        sortable
        pagination
        perPage={data?.meta?.total}
      >
        <AdvanceTable
          table
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            size: 'sm',
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
      </AdvanceTableWrapper>
    </>
  );
}

PurchaseHistoryTable.propTypes = {
  data: PropTypes.object
};

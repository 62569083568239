import { React, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FaCalendar, FaFilter, FaTimes } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import { FiRefreshCcw } from 'react-icons/fi';
import SelectAccountDescriptions from '../../../components/form/SelectAccountDescriptions';
import SelectBankAccount from '../../../components/form/SelectBankAccount';
import SelectCategory from '../../../components/form/SelectCategory';
import SelectCustomerGroup from '../../../components/form/SelectCustomerGroup';
import SelectCostCenter from '../../../components/form/SelectCostCenter';
import { DateFormatVaildation } from 'module/Common/Functions/DateFormatVaildation';
import { showToast } from 'module/Common/Toast/toast';
import { _validateEmail } from '../Validators/InputValidators';
import { SelectedFilterFormData } from './FilterData';
import { AuthWizardContext, StoreContext } from 'context/Context';
import SelectFilterItem from 'module/Common/Search/SelectFilterItem';
import SelectSubLedger from '../../../components/form/SelectSubLedger';
import SelectSupplier from '../../../components/form/SelectSupplier';
import SelectTransactionTypes from '../../../components/form/SelectTransactionTypes';
import SelectVoucherTransType from '../../../components/form/SelectVoucherTransType';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import generateFormDataHelper from 'helpers/generateFormDataHelper';
import SelectSalesman from '../../../components/form/SelectSalesman';
import SelectWarehouse from '../../../components/form/SelectWarehouse';
import SelectSalesType from '../../../components/form/SelectSalesType';
import AppDatePicker from 'components/app-date-picker/AppDatePicker';
import SelectMultiYear from 'components/form/SelectMultiYear';
import SelectMultiMonth from 'components/form/SelectMultiMonth';
import DateRangePicker from './DateRangePicker';
import SelectCurrency from '../Search/SelectCurrency';
import SelectWorkOrder from '../Search/SelectWorkOrder';
import SearchAndSelectCustomer from '../Search/SearchAndSelectCustomer';
import SelectPriority from 'components/form/SelectPriority';
import CustomDropDown from './CustomDropDown';
import SelectRole from '../Search/SelectRole';
import SelectBranch from 'components/form/SelectBranch';

const Filter = ({
  modal,
  handleModal,
  moduleName,
  showPerson,
  year,
  enableDropDown,
  url,
  dropDownData,
  report,
  fromFixedAsset
}) => {
  const Translate = useAxisproTranslate();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  const filterItems = queryParams.get('filters');
  const layout = queryParams.get('layout');
  const [formData, setFormData] = useState({});
  const [filter, setFilter] = useState(false);
  const [ApplyFilter, setApplyFilter] = useState(false);
  const [dropDownValue, setDropDownValue] = useState([]);
  const [enableButton, setEnableButton] = useState(false);
  const filterForm = SelectedFilterFormData(moduleName);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const show_multi_branch = store?.globalConfiguration
    ?.show_multi_branch_for_admins
    ? true
    : false || store?.globalConfiguration?.show_multi_branch_for_users
    ? true
    : false;

  const fetchDropDownValues = () => {
    if (enableDropDown && url) {
      axios
        .get(url)
        .then(res => {
          if (res?.data?.success && res?.data?.data) {
            const statusObjects = res?.data?.data.map(status => {
              return {
                label: status.toUpperCase(),
                value: status
              };
            });
            setDropDownValue(statusObjects);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else if (enableDropDown && dropDownData && dropDownData?.length > 0) {
      setDropDownValue(dropDownData);
    }
  };

  useEffect(() => {
    fetchDropDownValues();
  }, [enableDropDown]);

  useEffect(async () => {
    if (filterItems && Object.keys(filterItems).length > 0) {
      const jsonObject = JSON.parse(filterItems);
      setFormData({
        ...jsonObject
      });
      setFilter(true);
    } else {
      setFormData({});
    }
  }, [filterItems]);

  useEffect(() => {
    // filterForm?.show_multi_branch   multi branch enabled in every report so this condition removed in
    if (show_multi_branch && !filter) {
      setFormData(prev => ({
        ...prev,
        branch_id: user.branch?.id,
        branch_id_ref: {
          label: user?.branch?.branch_name,
          value: user.branch?.id
        }
      }));
    }
    if (filterForm?.show_opening_balance) {
      setFormData(prev => ({
        ...prev,
        show_opening_balance: filterForm?.show_opening_balance
          ? formData['show_opening_balance']
            ? formData['show_opening_balance']
            : 1
          : ''
      }));
    }
  }, [filterForm?.show_opening_balance]);

  useEffect(async () => {
    if (filter) {
      const queryParams = {
        ...allQueryParams,
        page: 1,
        ...(layout && { layout: layout }),
        ...(!layout && modal && { info_filter: true }),
        filters: {
          ...formData
        }
      };
      const queryString = Object.keys(queryParams)
        .map(
          key =>
            `${key}=${encodeURIComponent(
              typeof queryParams[key] === 'object'
                ? JSON.stringify(queryParams[key])
                : queryParams[key]
            )}`
        )
        .join('&');
      navigate({
        pathname: '',
        search: queryString ? `?${queryString}` : ''
      });
    }
  }, [filter, ApplyFilter]);

  const clearFilter = () => {
    if (formData) {
      setFormData({});
      setFilter(false);
      navigate({
        pathname: '',
        search: ''
      });
    }
  };

  const runFilter = e => {
    e.preventDefault();
    if (Object.keys(formData).length > 0) {
      let obj = formData;
      if (
        formData?.email &&
        'email' in obj &&
        !_validateEmail(formData?.email.trim())
      ) {
        showToast('Enter valid email id to search!', 'error');
      } else {
        if (
          (!formData.date_from || DateFormatVaildation(formData.date_from)) &&
          (!formData.date_to || DateFormatVaildation(formData.date_to)) &&
          (!formData.exp_date_from ||
            DateFormatVaildation(formData.exp_date_from)) &&
          (!formData.exp_date_to || DateFormatVaildation(formData.exp_date_to))
        ) {
          if (formData.date_from > formData.date_to) {
            showToast('Start date cannot be greater than end date.', 'error');
          } else {
            setFilter(true);
            setApplyFilter(!ApplyFilter);
            if (modal) {
              handleModal();
            }
          }
        } else {
          showToast(
            'You have selected an invalid date. Please select a valid date',
            'error'
          );
        }
      }
    } else {
      showToast(
        'Select some filter option to generate corresponding report',
        'error'
      );
    }
  };

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
    if (action?.name === 'account_code') {
      setFormData(prev => ({
        ...prev,
        sub_ledger_code: '',
        sub_ledger_code_ref: ''
      }));
    }
  };

  const handleTableDataSelectFieldChange = (value, action) => {
    let itemsWithNewData = {
      ...formData,
      [action.name]: value ? value.value : null,
      [action.name + '_ref']: value
    };
    setFormData(itemsWithNewData);
  };

  const handleChange = ({ target }) => {
    let name = target.name;
    let value = '';
    if (name === 'is_recurring') {
      value = target.checked;
    } else if (name === 'is_unpaid') {
      value = formData.is_unpaid ? false : true;
    } else if (name === 'is_expiry') {
      value = formData.is_expiry ? 0 : 1;
    } else if (name === 'is_active') {
      value = formData.is_active ? 0 : 1;
    } else if (name === 'show_opening_balance') {
      value = formData.show_opening_balance ? 0 : 1;
    } else {
      value = target.value;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleFilterFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));

  const handleMultiSelect = (e, action) => {
    let name = action.name;
    if (name === 'years' || name === 'months') {
      let data = '';
      e.map((newItems, key) => {
        if (key === 0) {
          data = data + newItems.label;
        } else {
          data = data + ',' + newItems.label;
        }
      });
      let itemsWithNewData = {
        ...formData,
        [name]: data,
        [name + '_ref']: e
      };
      setFormData(itemsWithNewData);
    } else {
      setFormData({ ...formData, [name]: e });
    }
  };

  const refreshPage = () => {
    setFormData(prev => ({
      ...prev,
      refresh: prev?.refresh ? prev?.refresh + 1 : 1
    }));
    setFilter(true);
    setApplyFilter(!ApplyFilter);
  };

  useEffect(() => {
    if (filterItems) {
      const parsedFilterItems = JSON.parse(filterItems);
      const keysLength = Object.keys(parsedFilterItems).length;
      const hasSearchKey =
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'search') ||
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'refresh');
      const hasSearchRefreshKey =
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'search') &&
        Object.prototype.hasOwnProperty.call(parsedFilterItems, 'refresh');
      if (
        (hasSearchKey && keysLength === 1) ||
        (hasSearchRefreshKey && keysLength === 2)
      ) {
        setEnableButton(true);
      } else {
        setEnableButton(false);
      }
    }
  }, [filterItems]);
  const defaultHandleSubmit = e => {
    e.preventDefault();
  };
  return (
    <Form
      className="border-bottom filter-bg-color"
      onSubmit={defaultHandleSubmit}
    >
      <Row className="pb-0 p-0 pt-2 ps-2 pe-2">
        {fromFixedAsset
          ? filterForm && filterForm?.customDropDown
            ? filterForm?.customDropDown.map((items, key) => (
                <Col md={modal ? 4 : 2} key={key}>
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate(items.Heading)}</Form.Label>
                    <CustomDropDown
                      name={items.name}
                      value={formData[items.name + '_ref'] ?? ''}
                      onChange={handleFieldChange}
                      options={dropDownValue}
                    />
                  </Form.Group>
                </Col>
              ))
            : ''
          : null}
        {filterForm && filterForm.ledger
          ? filterForm.ledger.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectAccountDescriptions
                    name={item.name}
                    value={formData[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleFieldChange}
                    keyName="code"
                    required
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.sub_ledger
          ? filterForm.sub_ledger.map((item, key) => {
              if (showPerson) {
                if (
                  allQueryParams?.filters &&
                  JSON.parse(allQueryParams.filters)?.account_code
                ) {
                  return (
                    <Col md={modal ? 4 : 2} key={key}>
                      <Form.Group className="mb-3">
                        <Form.Label>{Translate(item.Heading)}</Form.Label>
                        <SelectSubLedger
                          name={item.name}
                          value={formData[item.name + '_ref'] ?? ''}
                          handleFieldChange={handleFieldChange}
                          ledger_id={formData['account_code'] ?? ''}
                          required
                        />
                      </Form.Group>
                    </Col>
                  );
                } else {
                  return;
                }
              }
              return (
                <Col md={modal ? 4 : 2} key={key}>
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate(item.Heading)}</Form.Label>
                    <SelectSubLedger
                      name={item.name}
                      value={formData[item.name + '_ref'] ?? ''}
                      handleFieldChange={handleFieldChange}
                      ledger_id={formData['account_code'] ?? ''}
                      required
                    />
                  </Form.Group>
                </Col>
              );
            })
          : ''}
        {filterForm?.dateRange
          ? filterForm?.dateRange.map((items, key) => {
              if (showPerson) {
                if (
                  allQueryParams?.filters &&
                  (JSON.parse(allQueryParams.filters)?.customer_id ||
                    JSON.parse(allQueryParams.filters)?.supplier_id ||
                    JSON.parse(allQueryParams.filters)?.stock_id ||
                    JSON.parse(allQueryParams.filters)?.bank_account_id ||
                    JSON.parse(allQueryParams.filters)?.account_code)
                ) {
                  return (
                    <Col md={modal ? 4 : 2} key={key}>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          {Translate(items.Heading ?? 'Date Range')}
                        </Form.Label>
                        <DateRangePicker
                          formData={formData}
                          setFormData={setFormData}
                          dateRange_form={items}
                        />
                      </Form.Group>
                    </Col>
                  );
                } else {
                  return;
                }
              }
              return (
                <Col md={modal ? 4 : 2} key={key}>
                  <Form.Group className="mb-3">
                    <Form.Label>
                      {Translate(items.Heading ?? 'Date Range')}
                    </Form.Label>
                    <DateRangePicker
                      formData={formData}
                      setFormData={setFormData}
                      dateRange_form={items}
                    />
                  </Form.Group>
                </Col>
              );
            })
          : ''}
        {filterForm && filterForm.date
          ? filterForm.date.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <div className="d-flex flex-row">
                    <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
                      <FaCalendar size={10} className="text-white" />
                    </div>
                    <AppDatePicker
                      name={item.name}
                      value={formData[item.name]}
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      onChange={handleFieldChange}
                    />
                  </div>
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.voucher_trans
          ? filterForm.voucher_trans.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectVoucherTransType
                    name={items.name}
                    keyName="slug"
                    onChange={handleFilterFieldChange}
                    value={formData[items.name + '_ref'] ?? ''}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm?.branch
          ? filterForm?.branch.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectBranch
                    name={item.name}
                    value={formData[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {show_multi_branch && (
          <Col md={modal ? 4 : 2}>
            <Form.Group className="mb-3">
              <Form.Label>{Translate('Branch')}</Form.Label>
              <SelectBranch
                name={'branch_id'}
                value={formData?.branch_id_ref}
                handleFieldChange={handleFieldChange}
                withAllOption={true}
                navbar={true}
              />
            </Form.Group>
          </Col>
        )}
        {filterForm && filterForm.customer
          ? filterForm.customer.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SearchAndSelectCustomer
                    name={item.name}
                    value={formData[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleTableDataSelectFieldChange}
                    showCustomerDetails={showPerson}
                    hideAddForm={true}
                    advancedSearch
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {/* Condition checking for bank transaction report:
    Only render the transaction types component when showPerson is true,
    and bank_account_id exists in allQueryParams.filters */}
        {showPerson
          ? allQueryParams.filters &&
            (JSON.parse(allQueryParams.filters)?.bank_account_id ||
              JSON.parse(allQueryParams.filters)?.account_code)
            ? filterForm && filterForm.trans_type
              ? filterForm.trans_type.map((items, key) => (
                  <Col md={modal ? 4 : 2} key={key}>
                    <Form.Group className="mb-3">
                      <Form.Label>{Translate(items.Heading)}</Form.Label>
                      <SelectTransactionTypes
                        name={items.name}
                        keyName="slug"
                        handleFieldChange={handleFilterFieldChange}
                        value={formData[items.name + '_ref'] ?? ''}
                      />
                    </Form.Group>
                  </Col>
                ))
              : ''
            : ''
          : filterForm && filterForm.trans_type
          ? filterForm.trans_type.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectTransactionTypes
                    name={items.name}
                    keyName="slug"
                    handleFieldChange={handleFilterFieldChange}
                    value={formData[items.name + '_ref'] ?? ''}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}

        {filterForm && filterForm.category
          ? filterForm.category.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectCategory
                    name={item.name}
                    value={formData[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleFieldChange}
                    fixedAsset={item?.fixedAsset ? true : false}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.item
          ? filterForm.item.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectFilterItem
                    value={formData[items.name + '_ref'] ?? ''}
                    handleFieldChange={handleFieldChange}
                    name={items.name}
                    stockItemList
                    includeFixedAsset={items?.fixedAsset ?? false}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.cost_center
          ? filterForm.cost_center.map((items, key) => {
              if (showPerson) {
                if (
                  allQueryParams?.filters &&
                  JSON.parse(allQueryParams.filters)?.account_code
                ) {
                  return (
                    <Col md={modal ? 4 : 2} key={key}>
                      <Form.Group className="mb-3">
                        <Form.Label>{Translate(items.Heading)}</Form.Label>
                        <SelectCostCenter
                          value={formData[items.name + '_ref'] ?? ''}
                          name={items.name}
                          handleFieldChange={handleFieldChange}
                          style={{ borderRadius: 'none' }}
                        />
                      </Form.Group>
                    </Col>
                  );
                } else {
                  return;
                }
              }
              return (
                <Col md={modal ? 4 : 2} key={key}>
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate(items.Heading)}</Form.Label>
                    <SelectCostCenter
                      value={formData[items.name + '_ref'] ?? ''}
                      name={items.name}
                      handleFieldChange={handleFieldChange}
                      style={{ borderRadius: 'none' }}
                    />
                  </Form.Group>
                </Col>
              );
            })
          : ''}
        {filterForm && filterForm.bankAccount
          ? filterForm.bankAccount.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectBankAccount
                    name={items.name}
                    value={formData[items.name + '_ref'] ?? ''}
                    handleFieldChange={handleTableDataSelectFieldChange}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm?.salesman
          ? filterForm?.salesman.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectSalesman
                    name={items.name}
                    value={formData[items.name + '_ref'] ?? ''}
                    handleFieldChange={handleTableDataSelectFieldChange}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}

        {filterForm && filterForm?.wareHouse
          ? filterForm?.wareHouse.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectWarehouse
                    name={item.name}
                    value={formData?.[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.sales_type
          ? filterForm.sales_type.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectSalesType
                    name={items.name}
                    value={formData[items.name + '_ref'] ?? ''}
                    handleFieldChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {!fromFixedAsset
          ? filterForm && filterForm?.customDropDown
            ? filterForm?.customDropDown.map((items, key) => (
                <Col md={modal ? 4 : 2} key={key}>
                  <Form.Group className="mb-3">
                    <Form.Label>{Translate(items.Heading)}</Form.Label>
                    <CustomDropDown
                      name={items.name}
                      value={formData[items.name + '_ref'] ?? ''}
                      onChange={handleFieldChange}
                      options={dropDownValue}
                    />
                  </Form.Group>
                </Col>
              ))
            : ''
          : null}

        {filterForm && filterForm?.supplier
          ? filterForm.supplier.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectSupplier
                    name={item.name}
                    value={formData[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleTableDataSelectFieldChange}
                    showSupplierDetails={showPerson}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm?.customer_group
          ? filterForm.customer_group.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectCustomerGroup
                    name={item.name}
                    value={formData[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleTableDataSelectFieldChange}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm?.textBox
          ? filterForm?.textBox.map((items, key) => {
              if (showPerson) {
                if (
                  allQueryParams?.filters &&
                  (JSON.parse(allQueryParams.filters)?.supplier_id ||
                    JSON.parse(allQueryParams.filters)?.bank_account_id ||
                    JSON.parse(allQueryParams.filters)?.stock_id)
                ) {
                  return (
                    <Col md={modal ? 4 : 2} key={key}>
                      <Form.Group className="mb-3">
                        <Form.Label>{Translate(items?.Heading)}</Form.Label>
                        <Form.Control
                          type="text"
                          name={items?.name}
                          value={formData[items.name] ?? ''}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  );
                } else {
                  return;
                }
              } else {
                return (
                  <Col md={modal ? 4 : 2} key={key}>
                    <Form.Group className="mb-3">
                      <Form.Label>{Translate(items?.Heading)}</Form.Label>
                      <Form.Control
                        type="text"
                        name={items?.name}
                        value={formData[items.name] ?? ''}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                );
              }
            })
          : ''}
        {filterForm && filterForm.account_code
          ? filterForm.account_code.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectAccountDescriptions
                    keyName="code"
                    name={item.name}
                    value={formData[item.name + '_ref'] ?? ''}
                    handleFieldChange={handleFieldChange}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}

        {filterForm && filterForm.currency_code
          ? filterForm.currency_code.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectCurrency
                    name={items.name}
                    handleFieldChange={handleFilterFieldChange}
                    value={formData[items.name + '_ref'] ?? ''}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.priority
          ? filterForm.priority.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectPriority
                    value={formData[item.name + '_ref'] ?? ''}
                    name={item.name}
                    yearData={year}
                    handleFieldChange={handleMultiSelect}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.years
          ? filterForm.years.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectMultiYear
                    value={formData[item.name + '_ref'] ?? ''}
                    name={item.name}
                    yearData={year}
                    handleFieldChange={handleMultiSelect}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.months
          ? filterForm.months.map((item, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(item.Heading)}</Form.Label>
                  <SelectMultiMonth
                    value={formData[item.name + '_ref'] ?? ''}
                    name={item.name}
                    handleFieldChange={handleMultiSelect}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm && filterForm.checkBox
          ? filterForm.checkBox.map((items, key) => {
              if (showPerson) {
                if (
                  allQueryParams?.filters &&
                  JSON.parse(allQueryParams.filters)?.customer_id
                ) {
                  return (
                    <Col
                      md={1}
                      className="d-flex align-items-center justify-content-center p-0"
                      key={key}
                    >
                      <Form.Group className="d-flex flex-column mt-3">
                        <Form.Label className="m-0">
                          {Translate(items.Heading)}
                        </Form.Label>
                        {console.log(formData)}
                        <Form.Check
                          type="checkbox"
                          name={items.name}
                          className="ms-0"
                          checked={formData[items.name] === 1}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </Col>
                  );
                } else {
                  return;
                }
              } else {
                return (
                  <Col
                    md={1}
                    className="d-flex align-items-center justify-content-center p-0"
                    key={key}
                  >
                    <Form.Group className="d-flex flex-column mt-3">
                      <Form.Label className="m-0">
                        {Translate(items.Heading)}
                      </Form.Label>
                      <Form.Check
                        type="checkbox"
                        name={items.name}
                        className="ms-0"
                        checked={formData[items.name] == 1}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                );
              }
            })
          : ''}

        {filterForm.work_order
          ? filterForm.work_order.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectWorkOrder
                    name={items.name}
                    handleFieldChange={handleFilterFieldChange}
                    value={formData[items.name + '_ref'] ?? ''}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}
        {filterForm.role
          ? filterForm.role.map((items, key) => (
              <Col md={modal ? 4 : 2} key={key}>
                <Form.Group className="mb-3">
                  <Form.Label>{Translate(items.Heading)}</Form.Label>
                  <SelectRole
                    name={items.name}
                    handleFieldChange={handleFilterFieldChange}
                    value={formData[items.name + '_ref'] ?? ''}
                  />
                </Form.Group>
              </Col>
            ))
          : ''}

        {!modal ? (
          <Col
            className={`d-flex align-items-center ${
              filterForm?.searchEnd ? 'justify-content-end pt-1' : ''
            }   mt-0 mb-2 `}
            style={{
              paddingTop: !filterForm?.searchEnd ? '1.5rem' : undefined
            }}
          >
            <Button
              type="button"
              variant="success"
              size="sm"
              className=" ms-0 me-1 border-0"
              onClick={runFilter}
              style={{
                height: '25px',
                width: report ? '85px' : '70px',
                fontSize: '10px',
                backgroundColor: report ? '#00bf63' : '#F5803E'
              }}
            >
              <FaFilter className=" text-light " />{' '}
              {Translate(report ? 'Generate' : 'Apply')}
            </Button>
            {filterItems ? (
              enableButton ? null : (
                <Button
                  variant="danger"
                  className="me-1"
                  size="sm"
                  onClick={clearFilter}
                  style={{
                    height: '25px',
                    width: ' 70px',
                    fontSize: '10px'
                  }}
                >
                  <MdClose className="bi-dots-vertical clear-filter-button" />{' '}
                  {Translate('Clear')}
                </Button>
              )
            ) : null}

            <Button
              variant="info"
              size="sm"
              onClick={refreshPage}
              style={{
                height: '25px',
                width: ' 85px',
                fontSize: '10px'
              }}
            >
              <FiRefreshCcw className="me-1" />
              {Translate('Refresh')}
            </Button>
          </Col>
        ) : (
          <Form.Group className="d-flex flex-row  pb-2">
            <Button
              type="button"
              variant="success"
              size="sm"
              className="ms-auto "
              onClick={runFilter}
            >
              <FaFilter /> {Translate(report ? 'Generate' : 'Apply')}
            </Button>
            <Button
              variant="danger"
              className="ms-1"
              size="sm"
              onClick={handleModal}
            >
              <FaTimes /> {Translate('Cancel')}
            </Button>
          </Form.Group>
        )}
        {/* {!modal && (
          <Col className="d-flex align-items-center justify-content-end ">
            <TableSearchBox />
          </Col>
        )} */}
      </Row>
    </Form>
  );
};
Filter.propTypes = {
  filterOption: PropTypes.any,
  modal: PropTypes.any,
  data: PropTypes.array,
  setData: PropTypes.any,
  handleModal: PropTypes.func,
  moduleName: PropTypes.string,
  showPerson: PropTypes.bool,
  fromFixedAsset: PropTypes.bool,
  year: PropTypes.array,
  enableDropDown: PropTypes.any,
  url: PropTypes.any,
  dropDownData: PropTypes.any,
  report: PropTypes.bool
};
export default Filter;

import {
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
  Form,
  Table,
  Badge
} from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import SelectCustomerGroup from 'components/form/SelectCustomerGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import MinimalReportDataFetching from 'module/Common/Loading/MinimalReportDataFetching';
import useAxisproTranslate from 'hooks/useAxisproTranslate';
import { showToast } from 'module/Common/Toast/toast';
import PropTypes from 'prop-types';
import FormattedData from 'module/Common/TableFilter/FormatedData';

const CustomerAdvancedSearch = ({ handleCustomer }) => {
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [formData, setFormData] = useState({});
  const [nextLink, setNextLink] = useState(null);
  const handleOpenModal = () => {
    setShow(true);
  };
  const searchCustomerData = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get('sales/customers', {
        params: {
          ...FormattedData(formData)
        }
      });
      setCustomerData(data.data.data);
      if (data.data?.links?.next !== null) {
        setNextLink(data.data.links.next);
      } else {
        setNextLink(null);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const loadNextPage = async () => {
    if (nextLink && nextLink !== null) {
      await axios
        .get(nextLink)
        .then(res => {
          if (res.data.success) {
            setCustomerData([...customerData, ...res.data.data.data]);
            if (res?.data?.data?.links?.next !== null) {
              setNextLink(res?.data?.data.links.next);
            } else {
              setNextLink(null);
            }
          }
        })
        .catch(error => {
          if (error) {
            showToast('Something went wrong please contact admin', 'error');
          }
        });
    }
  };
  const handleCustomerSelect = items => {
    handleCustomer(
      {
        label: items.customer_id + '-' + items.name,
        value: items.id
      },
      {
        action: 'select-option',
        option: undefined,
        name: 'customer_id'
      }
    );
    setShow(false);
  };
  const handleFieldChange = (e, action) => {
    if (action) {
      setFormData(prev => ({
        ...prev,
        [action.name]: e?.value
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [e.target.name]: e.target.value
      }));
    }
  };
  useEffect(() => {
    let timeoutId;
    if (show) {
      timeoutId = setTimeout(() => {
        searchCustomerData();
      }, 400);
    }
    return () => {
      setCustomerData(undefined);
      clearTimeout(timeoutId);
    };
  }, [show, formData]);
  return (
    <>
      <OverlayTrigger
        overlay={<Tooltip>{Translate('Advance search')}</Tooltip>}
      >
        <Button
          onClick={handleOpenModal}
          variant="transparent"
          size="sm"
          className="px-2 align-self-stretch d-flex justify-content-center align-items-center customer-advance-search-button-style"
        >
          <FaFilter size={12} className="text-light" />
        </Button>
      </OverlayTrigger>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        size="lg"
        scrollable
        className="customer-advanced-filter-modal"
      >
        <Modal.Header closeButton className="m-0">
          <Modal.Title>
            {' '}
            <FaFilter size={12} className="me-2 text-warning" />
            {Translate('ADVANCE CUSTOMER FILTER')}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-end py-3 pt-0">
            <div className=" w-100">
              <div>{Translate('Search Customer Name')}</div>
              <div className="d-flex align-items-center filter-input-width w-100">
                <Form.Control
                  type="text"
                  name={'search'}
                  placeholder=""
                  className="ps-4"
                  value={formData?.search}
                  onChange={handleFieldChange}
                />
                <FontAwesomeIcon
                  icon="search"
                  className="position-absolute text-400 search-box-icon ms-2"
                />
              </div>
            </div>
            <div className="ms-3 filter-input-width w-100">
              <div>{Translate('Customer Group')}</div>
              <SelectCustomerGroup
                name="customer_group_id"
                handleFieldChange={handleFieldChange}
                value={formData.customer_group_id_ref}
              />
            </div>
          </div>
          <div className="main-table-content">
            <Table bordered hover>
              <thead className="text-dark text-uppercase bg-dark position-sticky top-0">
                <tr>
                  <th className="table-header-size text-light">
                    {Translate('Customer Id')}
                  </th>
                  <th className="table-header-size text-light customer-name ">
                    {Translate('Customer Name')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {customerData?.length > 0 ? (
                  customerData.map((item, index) => (
                    <tr
                      key={index}
                      className="cursor-pointer"
                      onClick={() => handleCustomerSelect(item)}
                    >
                      <th className="fs--1 py-1">{item.customer_id}</th>
                      <td className="fs--1 py-1">{item?.name}</td>
                    </tr>
                  ))
                ) : !isLoading && customerData?.length < 1 ? (
                  <tr>
                    <td colSpan={5}>
                      <Badge bg="danger">{Translate('No results found')}</Badge>
                    </td>
                  </tr>
                ) : null}
              </tbody>
              {nextLink && nextLink !== null && nextLink !== '' ? (
                <MinimalReportDataFetching
                  row={3}
                  column={2}
                  nextLink={nextLink}
                  loadNextPage={loadNextPage}
                />
              ) : isLoading ? (
                ''
              ) : (
                <tr>
                  <td colSpan={2}>
                    <div className="d-flex flex-column align-items-center">
                      <span>{Translate('End reached')}! &#128578;</span>
                    </div>
                  </td>
                </tr>
              )}
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
CustomerAdvancedSearch.propTypes = {
  handleCustomer: PropTypes.func
};
export default CustomerAdvancedSearch;
